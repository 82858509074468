import CommonUtils from "./common";

const validateUserName = (userName) => {
  if (!userName)
    return {
      error: true,
      field: "userName",
      message: "Username can not be empty",
    };
  if (userName.length < 4) {
    return {
      error: true,
      field: "userName",
      message: "User name length should be greater than 4",
    };
  }
  return {
    error: false,
  };
};
const validatePassword = (password) => {
  if (!password)
    return {
      error: true,
      field: "password",
      message: "Password can not be empty",
    };
  if (password.length < 6) {
    return {
      error: true,
      field: "password",
      message: "Password length should be greater than 6",
    };
  }
  return {
    error: false,
  };
};

const validate = (...validations) => {
  return validations
    .filter((item) => item?.error)
    .reduce((acc, item) => {
      if (item.field && item.message) {
        acc[item.field] = item.message;
      }
      return acc;
    }, {});
};

const validatePacking = (packing) => {
  const ALLOWED_UNITS = ['', 'mg', 'ml', 'g'];

  const keepOnlyNumbers = (str) => {
    return str.replace(/[^0-9]/g, '');
  }
  const keepOnlyChars = (str) => {
    return str.replace(/[0-9]/g, '');
  };

  const isValid =
    packing
      .split('x')
      .map((m) => m.toLowerCase().trim())
      .filter((m, i) => {
        if (i === 0) {
          const num = keepOnlyNumbers(m);
          const unit = keepOnlyChars(m);
          return !Number.isNaN(Number(num)) && !ALLOWED_UNITS.includes(unit);
        } else {
          return Number.isNaN(Number(m));
        }
      }).length === 0;

  return isValid
}

const validateObject = (object, keys) => {
  keys.forEach(key => {
    if (key === 'packing') {
      if (!validatePacking(object[key])) {
        object[key] = '';
      }
    }
  })

  const nonEmptyKeys = CommonUtils.getNonEmptyFieldsForKeys(object, keys);

  return Object.keys(nonEmptyKeys).filter(k => keys.includes(k)).length === keys.length
}

const validateNumberInput = (e) => {
  const { value } = e.target;
  if (/^\d*$/.test(value)) {
    return value
  }
  return "";
}

const validateFloatInput = (e) => {
  const { value } = e.target;
  if (/^\d*\.?\d*$/.test(value)) {
    return value
  }
  return "";
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === ''
}


const Validator = {
  validateUserName,
  validatePassword,
  validate,
  validatePacking,
  validateObject,
  validateNumberInput,
  validateFloatInput,
  isEmpty
};
export default Validator;
