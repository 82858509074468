import { InputTypes } from "utils/input_types";

export const CurrencyHelper = {
    getCurrencyConfig() {
        return [
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Country",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'country',
                validation: {
                    expression: /^.{1,80}$/,
                    message: 'Country should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Currency",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'currency',
                validation: {
                    expression: /^.{1,20}$/,
                    message: 'Country should be 1-20 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Symbol",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'symbol',
                validation: {
                    expression: /^.{1,5}$/,
                    message: 'Country should be 1-5 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Conversion Rate",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'conversion_rate',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'Conversion rate should be a valid number'
                },
                type: 'text',
                options: []
            },

        ]
    }
}
