import * as React from "react";


import { fontWeights } from "styles/fonts";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import UserActions from "actions/user";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import ErrorUtils from "utils/error";
import TextInput from "./TextInput";

export default function ChangePasswordDialog({ open, onClose, data }) {
  const snackbar = useSnackbar();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const mChangeUserPassword = useMutation(
    {
      mutationFn: UserActions.changeUserPassword,
      mutationKey: 'change-user-password',
      onSuccess: (res) => {
        snackbar.enqueueSnackbar('Password changed successfully!', { variant: "success" });
        reset();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(ErrorUtils.parseError(error), { variant: "error" });
      }
    }
  )

  const reset = () => {
    setPassword("");
    setConfirmPassword("");

    onClose();
  }

  const onSave = () => {
    // validate password and confirmPassword

    if (!password || !confirmPassword) {
      snackbar.enqueueSnackbar("Password and Confirm Password are required", { variant: "error" });
      return;
    }
    if (password !== confirmPassword) {
      snackbar.enqueueSnackbar("Passwords do not match", { variant: "error" });
      return;
    }

    mChangeUserPassword.mutate({
      username: data?.selectedUser?.username,
      newPassword: password
    })
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        Change Password
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <DialogContentText variant="button">
          Change password of <strong> {data?.selectedUser?.username || "User"}</strong>
        </DialogContentText>
        <Stack direction={"column"} spacing={2} sx={{ mt: 3 }}>
          <TextInput
            label="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          <TextInput
            label="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={reset}>Cancel</Button>
        <LoadingButton
          loading={mChangeUserPassword.isLoading}
          variant="text"
          onClick={onSave}
          spinnerSize={18}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
