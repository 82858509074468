
import excelIcon from 'assets/excel.png'
import pdfIcon from 'assets/pdf.png'

const Images = {
  Excel: excelIcon,
  PDF: pdfIcon,
  LoginBackground: "https://prahemlabs.s3.ap-south-1.amazonaws.com/public/assets/login_banner.jpg"
};

export default Images;
