import api from "network/api";
import Endpoints from "./endpoints";
import UserActions from "./user";
import authAxios from "network/authAxios";

const AuthActions = {
  async login(userName, password) {
    try {
      await authAxios.post(Endpoints.login, {
        userName, password
      });
      const userRes = await api.get(`${Endpoints.users}/current-user`)
      localStorage.setItem('username', userName)
      return userRes?.data;
    } catch (error) {
      return false
    }
  },
  async getCurrentUser() {
    const user = await UserActions.getCurrentUser();
    return user;
  },

  async logOut() {
    await api.post(Endpoints.logout);
    localStorage.clear();
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_DOMAIN
    }, 1000)
    return true;
  },

  async refreshToken(userName) {
    await authAxios.post(Endpoints.refreshToken, {
      username: userName,
    });
  }

};

export default AuthActions;
