import { Grid, Typography } from "@mui/material";

import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";

import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import OrganizationActions from "actions/organization";
import { useNavigate } from "react-router-dom";
import OrganizationTransform from "transforms/organization";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import { OrganizationHelper } from "helpers/organization";

const headers = [
  {
    key: "sr",
    label: "SR. NO",
    width: 50,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "name",
    label: "ORGANIZATION NAME",
    width: 150,
    align: "left",
  },
  {
    key: "email",
    label: "EMAIL",
    width: 100,
    align: "center",
    editable: true
  },
  {
    key: "contact_no",
    label: "CONTACT NO.",
    width: 100,
    align: "center",
    editable: true
  },
  {
    key: "website",
    label: "WEBSITE",
    width: 100,
    align: "center",
    editable: true
  },
  {
    key: "address",
    label: "ADDRESS",
    width: 150,
    align: "center",
    editable: true
  },
  {
    key: "logo",
    label: "LOGO",
    width: 100,
    align: "center",
    transform: OrganizationTransform.transformLogo,
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 50,
    align: "center",
    items: {
      View: Icons.ChevronRight,
    },
  },
];

export default function Organizations() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  const [organizations, setOrganizations] = useState([]);

  const { isLoading } = useQuery({
    queryKey: ["get-organizations"],
    queryFn: OrganizationActions.getOrganizations,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setOrganizations(data);
    },
  });

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  const onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.VIEW) {
      navigate(`/master/organizations/${row.id}`);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={5} lg={4}>
          <Typography variant="h4" color={"text.heading"} fontWeight="600">
            Organizations
          </Typography>
        </Grid>
      </Grid>

      <DataTable headers={headers} rows={organizations} onAction={onAction} rowStyle={OrganizationHelper.setCustomRowStyle} />
    </>
  );
}
