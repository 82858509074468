import { fontWeights } from "styles/fonts";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import { useEffect, useState } from "react";
import { InputTypes } from "utils/input_types";
import Validator from "utils/validator";
import Dropdown from "./Dropdown";
import NWSuggestionInput from "./NWSuggestionInput";
import TextInput from "./TextInput";


export default function DynamicFormDialog({
  title = '',
  onClose = () => { },
  onAction = () => { },
  actionType,
  open = false,
  config = [],
  values = {}
}) {

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const valuesKeys = values && typeof values === 'object' ? Object.keys(values) : []
  useEffect(() => {
    if (config.length > 0 && valuesKeys.length > 0 && open) {
      setLoading(true);
      const _data = {};
      config.forEach(field => {
        if (values && !Validator.isEmpty(values[field.name])) {
          _data[field.name] = values[field.name]
        } else {
          _data[field.name] = field.defaultValue || ''
        }
      })
      setTimeout(() => {
        setData(_data)
        setLoading(false)
      }, 500)
    }
    // eslint-disable-next-line
  }, [config.length, valuesKeys.length, open])

  const onChange = (key, value) => {
    setData({
      ...data,
      [key]: value
    })
  }

  const validate = () => {
    const _errors = {};
    config.forEach(field => {
      if (field.validation) {
        if (!field.validation.expression.test(data[field.name])) {
          _errors[field.name] = field.validation.message
        }
        if (field.required && Validator.isEmpty(data[field.name])) {
          _errors[field.name] = `${field.label} is required`
        }
      }
    })
    setErrors(_errors)
    return Object.keys(_errors).filter(key => !!key).length === 0
  }

  const _onAction = () => {
    if (!validate()) {
      return
    }

    const _data = applyTransforms();
    onAction(actionType, _data)
  }

  const reset = () => {
    setTimeout(() => {
      setData({});
      setErrors({});
    }, 300)

  }

  const _onClose = () => {
    reset();
    onClose()
  }

  const applyTransforms = () => {
    const _data = {}
    config.forEach(field => {
      if (field.transform) {
        _data[field.name] = field.transform(data[field.name])
      } else {
        _data[field.name] = data[field.name]
      }
    })
    return _data;
  }

  const clearError = (key) => {
    const _errors = { ...errors }
    delete _errors[key]
    setErrors(_errors);
  }

  return (
    <Dialog open={open} transitionDuration={300} fullWidth PaperProps={{
      sx: {
        minHeight: 400
      }
    }}>
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {loading ?
          <Box height={400} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress size={40}></CircularProgress>
          </Box>
          :
          <Stack direction={"column"} spacing={2} sx={{ mt: 3 }}>
            {config.map(field => {
              switch (field.inputType) {
                case InputTypes.TEXT_INPUT:
                  return <DynamicTextInput key={field.name} value={data[field.name]} props={field} onChange={onChange}
                    error={errors[field.name]} onFocus={() => clearError(field.name)}
                  />
                case InputTypes.AUTOCOMPLETE:
                  return <DynamicAutocompleteInput key={field.name} value={data[field.name]} props={field} onChange={onChange}
                    error={errors[field.name]} onFocus={() => clearError(field.name)}
                  />
                case InputTypes.DROPDOWN:
                  return <DynamicDropdown key={field.name} value={data[field.name]} props={field} onChange={onChange}
                    error={errors[field.name]} onFocus={() => clearError(field.name)}
                  />
                default:
                  return null;
              }
            })}
          </Stack>
        }
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={_onClose}>Cancel</Button>
        <LoadingButton disabled={loading} variant="text" spinnerSize={18} onClick={_onAction}>
          {actionType}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function DynamicTextInput({ props, value, onChange, error, onFocus }) {

  const _onChange = (e) => {
    onChange(props.name, e.target.value);

  }

  if (value === undefined) return;

  return (
    <TextInput
      error={!!error}
      helperText={error || ''}
      value={value}
      disabled={props.disabled}
      required={props.required}
      label={props.label}
      onChange={_onChange}
      type={props.type}
      placeholder={props.placeholder || ''}
      onFocus={onFocus}
    ></TextInput>
  )
}

function DynamicAutocompleteInput({ props, value, onChange, error, onFocus }) {

  const _onChange = (val) => {
    onChange(props.name, val);
  }

  if (value === undefined) return;

  return (
    <NWSuggestionInput
      error={!!error}
      helperText={error || ''}
      onAction={_onChange}
      label={props.label}
      queryFn={props.queryFn}
      required={props.required}
      value={value}
      onFocus={onFocus}
    />
  )
}

function DynamicDropdown({ props, value, onChange, error, onFocus }) {

  const _onChange = (e) => {
    onFocus();
    onChange(props.name, e.target.value);
  }

  if (value === undefined) return;

  return (
    <Dropdown
      required={props.required}
      variant="outlined"
      size="medium"
      disabled={props.disabled}
      label={props.label}
      options={props.options}
      labelKey={'label'}
      valueKey={'value'}
      value={value}
      onChange={_onChange}
      showNone={props.showNone}
      error={!!error}
      helperText={error || ''}
    ></Dropdown>
  )
}
