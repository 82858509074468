import * as React from "react";

import { useMutation } from "react-query";

import { fontSizes, fontWeights } from "styles/fonts";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import UserActions from "actions/user";
import { useSnackbar } from "notistack";
import ErrorUtils from "utils/error";
import Dropdown from "./Dropdown";
import TextInput from "./TextInput";
import Roles, { RoleNames } from "utils/roles";
import AccountType from "utils/account_type";
import UserContext from "context/UserContext";

const ROLES = [
  {
    value: Roles.ADMIN,
    label: RoleNames[Roles.ADMIN],
  },
  {
    value: Roles.MEMBER,
    label: RoleNames[Roles.MEMBER],
  },
  {
    value: Roles.PURCHASING,
    label: RoleNames[Roles.PURCHASING],
  },
  {
    value: Roles.MEMBER_PLUS,
    label: RoleNames[Roles.MEMBER_PLUS],
  },
];

const getRolesForAccount = (user) => {
  if (user.account_type === AccountType.MANUFACTURER) {
    return ROLES.filter((role) => [Roles.ADMIN, Roles.MEMBER_PLUS].includes(role.value));
  }
  return ROLES
}

export default function AddUserDialog({ open, setOpen, onSuccess }) {
  const snackbar = useSnackbar();
  const { user } = React.useContext(UserContext);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [role, setRole] = React.useState("");

  const [error, setError] = React.useState(null);

  const roles = getRolesForAccount(user);

  React.useEffect(() => {
    if (roles.length > 1) {
      setRole(roles[1].value);
    }
    // eslint-disable-next-line
  }, [roles.length]);

  const reset = () => {
    setUsername("");
    setPassword("");
    setConfirmPassword("");
    setError(null);
    mCreateUser.reset();
  };

  const mCreateUser = useMutation({
    mutationKey: "create-user",
    mutationFn: UserActions.createUser,
    onSuccess: () => {
      snackbar.enqueueSnackbar("User created", {
        variant: "success",
      });
      reset();
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onAdd = async () => {
    const data = {
      username,
      password,
      role,
    };

    const requiredFields = ["username", "password", "role"];
    const _error = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        _error[field] = true;
      }
    });

    if (confirmPassword !== password) {
      _error.confirmPassword = true;
    }
    setError(_error);

    if (Object.keys(_error).length > 0) {
      snackbar.enqueueSnackbar("Please fill all fields with valid values", {
        variant: "error",
      });
      return;
    }
    await mCreateUser.mutateAsync(data);

    handleClose();
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontWeight: fontWeights[600], fontSize: fontSizes.xl, color: 'text.heading' }}>
        Create User
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in the following details to create a new user.
        </DialogContentText>
        <Stack direction={"column"} spacing={2} sx={{ mt: 3 }}>
          <TextInput
            error={error?.username}
            required={true}
            disabled={mCreateUser.isLoading}
            label={"Username"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onFocus={() => setError(null)}
          ></TextInput>

          <TextInput
            error={error?.password}
            required={true}
            disabled={mCreateUser.isLoading}
            label={"Password"}
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setError(null)}
          ></TextInput>

          <TextInput
            error={error?.confirmPassword}
            required={true}
            disabled={mCreateUser.isLoading}
            label={"Confirm Password"}
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={() => setError(null)}
          ></TextInput>

          <Dropdown
            required={true}
            label="Role"
            variant="outlined"
            size={"medium"}
            labelKey="label"
            valueKey="value"
            options={roles}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            disabled={mCreateUser.isLoading}
            showNone={false}
          ></Dropdown>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <LoadingButton
          variant="text"
          onClick={onAdd}
          loading={mCreateUser.isLoading}
          spinnerSize={18}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
