import { InputTypes } from "utils/input_types";

export const ClientHelper = {
    setCustomRowStyle: () => {
        const style = {
            '&:hover': {
                backgroundColor: 'hover.light',
                color: 'black',
            },
            cursor: 'pointer'
        }
        return style
    },
    getClientFormConfig(props) {
        return [
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Client Name",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'name',
                validation: {
                    expression: /^.{1,80}$/,
                    message: 'Client name should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Company Name",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'company',
                validation: {
                    expression: /^.{1,150}$/,
                    message: 'Company name should be 1-150 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Email",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'email',
                validation: {
                    expression: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Email is invalid'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Phone",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'phone',
                validation: {
                    expression: /^\+?[1-9]\d{1,14}$|^(?:\+?\d{1,3})?[-. (]*\d{1,4}[-. )]*(?:\d{1,4}[-. ]*){1,3}\d{1,4}$/,
                    message: 'Phone is invalid'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Country",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'country',
                validation: {
                    expression: /^.{1,40}$/,
                    message: 'Country should be 1-40 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.DROPDOWN,
                label: "Managed By",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'associated_user',
                validation: {
                    expression: /^.{1,50}$/,
                    message: 'Please select a valid user name'
                },
                type: 'text',
                showNone: false,
                options: props?.users || []
            },

        ]
    }
}
