import { DialogContent, IconButton, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { fontWeights } from "styles/fonts";
import DownloadDocsTransform from "transforms/download_documents";
import { ActionTypes } from "utils/action_types";
import DateTimeUtils from "utils/date_time";
import DocumentTypes from "utils/document_types";
import Icons from "utils/icons";
import DataTable from "./DataTable";

const headers = [
  {
    key: "document_type",
    label: "DOCUMENT",
    width: 100,
    align: "left",
    transform: DownloadDocsTransform.transformDocumentType
  },
  {
    key: "last_updated_at",
    label: "LAST UPDATED AT",
    width: 100,
    align: "center",
    transform: (value) => {
      if (!value?.last_updated_at) return "-";
      return DateTimeUtils.dateToString(new Date(value.last_updated_at));
    }
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 100,
    align: "center",
    items: {
      Download: Icons.Download,
    },
  },
];

export function DownloadDocumentsDialog({ data, open, onClose, actions = {}, documentTypes = [] }) {

  const [rows, setRows] = useState([]);

  React.useEffect(() => {
    if (!data || documentTypes.length === 0) return;
    const _rows = []

    if (documentTypes.includes(DocumentTypes.EXCEL))
      _rows.push({
        document_type: DocumentTypes.EXCEL,
        last_updated_at: '',
      })

    if (data?.latest_doc_url && documentTypes.includes(DocumentTypes.QUOTATION)) {
      _rows.push({
        document_type: DocumentTypes.QUOTATION,
        last_updated_at: data?.latest_doc_updated_at,
      })
    }

    if (data?.prof_invoice_doc_url && documentTypes.includes(DocumentTypes.PROF_INVOICE)) {
      _rows.push({
        document_type: DocumentTypes.PROF_INVOICE,
        last_updated_at: data?.prof_invoice_doc_updated_at,
      })
    }

    if (data?.po_doc_url && documentTypes.includes(DocumentTypes.PURCHASE_ORDER)) {
      _rows.push({
        document_type: DocumentTypes.PURCHASE_ORDER,
        last_updated_at: data?.po_doc_updated_at,
      })
    }
    setRows(_rows);

  }, [data, documentTypes]);

  const onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.DOWNLOAD) {
      switch (row.document_type) {
        case DocumentTypes.QUOTATION:
          actions.downloadQuotation();
          break;
        case DocumentTypes.PROF_INVOICE:
          actions.downloadProformaInvoice();
          break;
        case DocumentTypes.PURCHASE_ORDER:
          actions.downloadPO();
          break;
        case DocumentTypes.EXCEL:
          actions.downloadExcel();
          break;
        default:
          break;
      }
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        <Stack direction={"row"} justifyContent="space-between">
          DOCUMENTS
          <IconButton onClick={onClose} size="small">
            <Icons.Cancel fontSize="small"></Icons.Cancel>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DataTable
          headers={headers}
          rows={rows}
          // loading={isLoading}
          onAction={onAction}
        />
      </DialogContent>
    </Dialog>
  );
}
