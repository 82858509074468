export const UserHelper = {
    setCustomRowStyle: () => {
        const style = {
            '&:hover': {
                backgroundColor: 'hover.light',
                color: 'black',
            },
            cursor: 'pointer'
        }
        return style
    },
}
