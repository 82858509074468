import { Chip } from "@mui/material";
import React from "react";
import { fontWeights } from "styles/fonts";
import { ClientStatusNames } from "utils/client_status";
import { QuotationStateNames } from "utils/quotation_states";

export default function FilterChip({ filter, onClick, active, color, disabled }) {
  const getFilterLabel = () => {
    if (filter.field === "client_status") {
      return ClientStatusNames[filter.value];
    }
    if (filter.field === "state") {
      return QuotationStateNames[filter.value];
    }
  };

  return (
    <Chip
      disabled={disabled}
      color={color || "primary"}
      variant={active ? "contained" : "outlined"}
      label={getFilterLabel()}
      onClick={onClick}
      sx={{
        fontWeight: fontWeights[600]
      }}
    />
  );
}
