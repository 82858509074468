import * as React from "react";


import { fontWeights } from "styles/fonts";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import OrganizationActions from "actions/organization";
import ProformaInvoiceActions from "actions/proforma_invoice";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import ErrorUtils from "utils/error";
import Icons from "utils/icons";
import { FreightTypes } from "utils/proforma_invoice";
import Dropdown from "./Dropdown";
import TextArea from "./TextArea";
import TextInput from "./TextInput";
import { FreightDetails } from "./FreightDetails";
import Validator from "utils/validator";

export default function ProfInvoiceDetailsDialog({ open, onClose, onSuccess, data }) {


  const snackbar = useSnackbar();

  const [error, setError] = React.useState(null);

  const [buyerName, setBuyerName] = React.useState("");
  const [buyerAddress, setBuyerAddress] = React.useState("");
  const [consigneeName, setConsigneeName] = React.useState("");
  const [consigneeAddress, setConsigneeAddress] = React.useState("");
  const [buyerSameAsConsignee, setBuyerSameAsConsignee] = React.useState(false);
  const [shipmentPort, setShipmentPort] = React.useState("");
  const [destination, setDestination] = React.useState("");
  const [shipment, setShipment] = React.useState("");
  const [paymentTerms, setPaymentTerms] = React.useState("");
  const [deliveryDays, setDeliveryDays] = React.useState("");
  const [freightOption, setFreightOption] = React.useState(FreightTypes.PAID);
  const [freightAmount, setFreightAmount] = React.useState("");

  // Bank Details
  const [selectedBankDetails, setSelectedBankDetails] = React.useState("");
  const [inwardRemittance, setInwardRemittance] = React.useState("");
  const [fieldNo57A, setFieldNo57A] = React.useState("");
  const [beneficiaryBank, setBeneficiaryBank] = React.useState("");
  const [beneficiaryName, setBeneficiaryName] = React.useState("");
  const [accountNo, setAccountNo] = React.useState("");
  const [branchName, setBranchName] = React.useState("");
  const [swiftMTSendTo, setSwiftMTSendTo] = React.useState("");

  // Signatory
  const [signatoryName, setSignatoryName] = React.useState("");

  React.useEffect(() => {
    if (open) {
      const _storedSignatoryName = localStorage.getItem("signatory_name")
      if (_storedSignatoryName) {
        setSignatoryName(_storedSignatoryName)
      }
    }
  }, [open])

  const [bankDetailsFormVisible, setBankDetailsFormVisible] = React.useState(false);

  const { data: savedBanks, refetch: fetchBankDetails } = useQuery({
    queryKey: ["get-bank-names", data?.organizationId],
    queryFn: OrganizationActions.getBankNames,
    enabled: !!open,
    onSuccess: (data) => {
      if (data?.length === 0) {
        setBankDetailsFormVisible(true)
      }
    }
  })

  const mSaveBankDetails = useMutation(OrganizationActions.saveBankDetails, {
    onSuccess: () => {
      snackbar.enqueueSnackbar("Bank details saved successfully", {
        variant: "success",
      });
      fetchBankDetails();
      setBankDetailsFormVisible(false)
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
  });

  React.useEffect(() => {
    if (buyerSameAsConsignee) {
      setConsigneeName(buyerName)
      setConsigneeAddress(buyerAddress)
    }
  }, [buyerSameAsConsignee, buyerName, buyerAddress])

  const reset = () => {
    setBuyerName("")
    setBuyerAddress("")
    setConsigneeName("")
    setConsigneeAddress("")
    setBuyerSameAsConsignee(false)
    setShipmentPort("")
    setDestination("")
    setShipment("")
    setPaymentTerms("")
    setDeliveryDays("")
    setInwardRemittance("")
    setFieldNo57A("")
    setBeneficiaryBank("")
    setBeneficiaryName("")
    setAccountNo("")
    setBranchName("")
    setSwiftMTSendTo("")
    setSelectedBankDetails("")
    setFreightAmount(0)
    setFreightOption(FreightTypes.PAID);

    onClose();
  }

  const mSaveProfInvoiceDetails = useMutation(ProformaInvoiceActions.saveProfInvoiceDetails, {
    onSuccess: () => {
      snackbar.enqueueSnackbar("Proforma Invoice details saved successfully", {
        variant: "success",
      });
      localStorage.setItem("signatory_name", signatoryName);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
  });

  const { data: profInvoiceDetails, refetch: fetchProfInvoiceDetails } = useQuery({
    queryKey: ["get-prof-invoice-details", data?.quotationId],
    queryFn: ProformaInvoiceActions.getProfInvoiceDetails,
    enabled: false,
  })

  const mGenerateDoc = useMutation({
    mutationKey: "generate-prof-invoice-doc",
    mutationFn: ProformaInvoiceActions.generateProfInvoiceDoc,
    onError: () => {
      snackbar.enqueueSnackbar(
        "Failed to generate document. Please try again later",
        {
          variant: "error",
        }
      );
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Document generated", {
        variant: "success",
      });
    },
  });

  const { refetch: fetchDocUrl, isLoading: fetchDocUrlLoading } = useQuery({
    queryKey: ["get-prof-invoice-doc", data?.quotationId],
    queryFn: ProformaInvoiceActions.getProfInvoiceDocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
  });

  React.useEffect(() => {
    if (open && data?.quotationId) {
      fetchProfInvoiceDetails()
    }
  }, [data?.quotationId, fetchProfInvoiceDetails, open])

  React.useEffect(() => {
    if (profInvoiceDetails && open) {
      if (profInvoiceDetails?.buyer_name) setBuyerName(profInvoiceDetails.buyer_name)
      if (profInvoiceDetails?.buyer_address) setBuyerAddress(profInvoiceDetails.buyer_address)
      if (profInvoiceDetails?.buyer_name && profInvoiceDetails?.consignee_name
        && profInvoiceDetails?.buyer_address === profInvoiceDetails?.consignee_address)
        setBuyerSameAsConsignee(true);
      if (profInvoiceDetails?.consignee_name) setConsigneeName(profInvoiceDetails.consignee_name)
      if (profInvoiceDetails?.consignee_address) setConsigneeAddress(profInvoiceDetails.consignee_address)
      if (profInvoiceDetails?.shipment_port) setShipmentPort(profInvoiceDetails.shipment_port)
      if (profInvoiceDetails?.shipment_destination) setDestination(profInvoiceDetails.shipment_destination)
      if (profInvoiceDetails?.shipment_type) setShipment(profInvoiceDetails.shipment_type)
      if (profInvoiceDetails?.payment_terms) setPaymentTerms(profInvoiceDetails.payment_terms)
      if (profInvoiceDetails?.shipment_delivery) setDeliveryDays(profInvoiceDetails.shipment_delivery)
      if (profInvoiceDetails?.bank_details_id) setSelectedBankDetails(profInvoiceDetails.bank_details_id)
      if (profInvoiceDetails?.freight_option !== undefined) setFreightOption(profInvoiceDetails.freight_option)
      if (profInvoiceDetails?.freight_amount !== undefined) setFreightAmount(profInvoiceDetails.freight_amount)
    }
  }, [profInvoiceDetails, open])

  const validateForInputErrors = () => {
    const _error = {};

    if (!buyerName) _error.buyerName = true;
    if (!buyerAddress) _error.buyerAddress = true;
    if (!consigneeName) _error.consigneeName = true;
    if (!consigneeAddress) _error.consigneeAddress = true;
    if (!shipmentPort) _error.shipmentPort = true;
    if (!destination) _error.destination = true;
    if (!shipment) _error.shipment = true;
    if (!paymentTerms) _error.paymentTerms = true;
    if (!deliveryDays) _error.deliveryDays = true;

    // Bank details
    if (bankDetailsFormVisible) {
      if (!beneficiaryBank) _error.beneficiaryBank = true;
      if (!beneficiaryName) _error.beneficiaryName = true;
      if (!accountNo) _error.accountNo = true;
      if (!branchName) _error.branchName = true;
    } else {
      if (!selectedBankDetails) _error.selectedBankDetails = true;
    }

    // Signatory
    if (!signatoryName) _error.signatoryName = true;

    return _error;
  }
  const validateParams = () => {
    const _error = validateForInputErrors();

    setError(_error);

    if (Object.keys(_error).length > 0) {
      snackbar.enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
    }

    return Object.keys(_error).length === 0;
  }

  const onNext = async () => {

    if (!validateParams()) return;

    const bankDetailsId = await getBankDetailsId();

    if (!bankDetailsId) {
      snackbar.enqueueSnackbar("Bank details are required!", {
        variant: "error",
      });
      return;
    }

    await mSaveProfInvoiceDetails.mutateAsync({
      quotationId: data?.quotationId,
      data: {
        buyer_name: buyerName,
        buyer_address: buyerAddress,
        consignee_name: consigneeName,
        consignee_address: consigneeAddress,
        shipment_port: shipmentPort,
        shipment_destination: destination,
        shipment_type: shipment,
        payment_terms: paymentTerms,
        shipment_delivery: deliveryDays,
        bank_details_id: bankDetailsId,
        signatory_name: signatoryName,
        freight_option: freightOption,
        freight_amount: freightAmount || 0
      }
    })

    setSelectedBankDetails(bankDetailsId);


    // API call to generate document
    const url = await generateNewDoc();
    window.open(url, "_blank");

    reset();

    if (onSuccess)
      onSuccess();

  }
  const generateNewDoc = async () => {

    snackbar.enqueueSnackbar("Generating proforma invoice. Please wait...", {
      variant: "info",
    });

    const { success } = await mGenerateDoc.mutateAsync({ quotationId: data.quotationId });

    if (!success) {
      snackbar.enqueueSnackbar(
        "Could not generate document. Please try again later.",
        {
          variant: "error",
        }
      );
      return;
    }

    const res = await fetchDocUrl();
    const url = res.data.data;
    return url;

  };

  const getBankDetailsId = async () => {
    const bankDetailsParams = {
      beneficiary_bank: beneficiaryBank,
      beneficiary_name: beneficiaryName,
      account_number: accountNo,
      branch_name: branchName,
    };

    if (inwardRemittance)
      bankDetailsParams.firc = inwardRemittance;
    if (fieldNo57A)
      bankDetailsParams.field57A = fieldNo57A;
    if (swiftMTSendTo)
      bankDetailsParams.swift_mt103_send_to = swiftMTSendTo;

    let bankDetailsId = selectedBankDetails;

    if (!bankDetailsId) {
      const { id } = await mSaveBankDetails.mutateAsync({
        organizationId: data?.organizationId,
        bankDetails: bankDetailsParams
      });
      bankDetailsId = id;
    }
    return bankDetailsId;
  }

  const filledRequiredFields = () => {
    const _errors = validateForInputErrors();
    return Object.keys(_errors).filter(ek => _errors[ek]).length === 0
  }

  if (!data?.quotationId || !data?.organizationId) return null;

  const buttonsDisabled = mSaveBankDetails.isLoading || mSaveProfInvoiceDetails.isLoading || mGenerateDoc.isLoading || fetchDocUrlLoading;

  return (
    <Dialog open={open} maxWidth='md' sx={{ overflowY: "scroll", maxHeight: '100%' }}>
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        Proforma Invoice
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in following required details for proforma invoice.
        </DialogContentText>
        <Grid container spacing={3} mt={1} mb={2}>
          <Grid item xs={6}>
            <Stack spacing={2} display={'block'}>
              <Typography color={'GrayText'} variant="button" fontWeight={600}>BUYER DETAILS</Typography>
              <TextInput
                disabled={buttonsDisabled}
                fullWidth
                error={error?.buyerName}
                required={true}
                label={"Buyer Name"}
                value={buyerName}
                onChange={(e) => setBuyerName(e.target.value)}
                onFocus={() => setError(null)}
                autoComplete="buyer_name"
              ></TextInput>

              <TextArea
                disabled={buttonsDisabled}
                error={error?.buyerAddress}
                required={true}
                placeholder="Buyer Address*"
                value={buyerAddress}
                onChange={(e) => setBuyerAddress(e.target.value)}
                rows={3}
                onFocus={() => setError(null)}
                autoComplete="buyer_address"
              ></TextArea>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={2} display={'block'}>
              <Typography color={'GrayText'} variant="button" fontWeight={600}>CONSIGNEE DETAILS</Typography>
              <FormControlLabel disabled={buttonsDisabled} sx={{ mt: 0, display: "block" }} control={<Checkbox size="small" checked={!!buyerSameAsConsignee} onChange={() => setBuyerSameAsConsignee(!buyerSameAsConsignee)} />} label="Same as buyer" />
              <TextInput
                fullWidth
                error={error?.consigneeName}
                required={true}
                disabled={!!buyerSameAsConsignee}
                label={"Consignee Name"}
                value={consigneeName}
                onChange={(e) => setConsigneeName(e.target.value)}
                onFocus={() => setError(null)}
                autoComplete="consignee_name"
              ></TextInput>

              <TextArea
                error={error?.consigneeAddress}
                required={true}
                disabled={buyerSameAsConsignee}
                placeholder="Consignee Address*"
                value={consigneeAddress}
                onChange={(e) => setConsigneeAddress(e.target.value)}
                rows={3}
                onFocus={() => setError(null)}
                autoComplete="consignee_address"
              ></TextArea>

            </Stack>

          </Grid>
        </Grid>

        <Divider />

        {/* Shipment Details */}
        <Grid container spacing={3} mt={0} mb={3}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>SHIPMENT DETAILS</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.shipmentPort}
              required={true}
              label={"Port of shipment"}
              value={shipmentPort}
              onChange={(e) => setShipmentPort(e.target.value)}
              onFocus={() => setError(null)}
              autoComplete="port"
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.destination}
              required={true}
              label={"Destination"}
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              onFocus={() => setError(null)}
              autoComplete="destination"
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.shipment}
              required={true}
              label={"Shipment"}
              value={shipment}
              onChange={(e) => setShipment(e.target.value)}
              onFocus={() => setError(null)}
              autoComplete="shipment"
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.paymentTerms}
              required={true}
              label={"Payment Terms"}
              value={paymentTerms}
              onChange={(e) => setPaymentTerms(e.target.value)}
              onFocus={() => setError(null)}
              autoComplete="payment"
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.deliveryDays}
              required={true}
              label={"Delivery (Days)"}
              value={deliveryDays}
              onChange={(e) => setDeliveryDays(Validator.validateNumberInput(e))}
              onFocus={() => setError(null)}
              autoComplete="delivery"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            ></TextInput>
          </Grid>

          <Grid item xs={12}>
            <FreightDetails
              disabled={buttonsDisabled}
              selectedOption={freightOption}
              setSelectedOption={setFreightOption}
              amount={freightAmount}
              setAmount={setFreightAmount}
              showAmount={true}
            />
          </Grid>
        </Grid>

        <Divider />

        {/* Bank Details */}
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>BANK DETAILS</Typography>
          </Grid>

          {savedBanks?.length > 0 && !bankDetailsFormVisible && (
            <>
              <Grid item xs={6}>
                <Dropdown
                  disabled={buttonsDisabled}
                  sx={{ mx: 0, width: '100%' }}
                  error={error?.selectedBankDetails}
                  required={true}
                  label="Select Bank Details"
                  variant="outlined"
                  size={"medium"}
                  labelKey="label"
                  valueKey="value"
                  options={savedBanks || []}
                  value={selectedBankDetails}
                  onChange={(e) => setSelectedBankDetails(e.target.value)}
                  showNone={false}
                ></Dropdown>
              </Grid>
              <Grid item xs={6}></Grid>
            </>
          )}

          {
            !bankDetailsFormVisible && (
              <Grid item xs={12}>
                <Button
                  disabled={buttonsDisabled}
                  variant="text"
                  onClick={() => {
                    setBankDetailsFormVisible(true)
                    setSelectedBankDetails("")
                  }}
                  startIcon={<Icons.Add />}
                >
                  Add New Bank Details
                </Button>
              </Grid>
            )
          }

          {
            savedBanks?.length > 0 && bankDetailsFormVisible && (
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={() => setBankDetailsFormVisible(false)}
                  startIcon={<Icons.Cancel />}
                >
                  Cancel
                </Button>
              </Grid>
            )
          }

          {bankDetailsFormVisible && (
            <>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.inwardRemittance}
                  label={"Foreign Inward Remittance In"}
                  value={inwardRemittance}
                  onChange={(e) => setInwardRemittance(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="foreign_remittance"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.fieldNo57A}
                  label={"Field No. 57(A)"}
                  value={fieldNo57A}
                  onChange={(e) => setFieldNo57A(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="field_57a"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.beneficiaryBank}
                  required={true}
                  label={"Beneficiary Bank"}
                  value={beneficiaryBank}
                  onChange={(e) => setBeneficiaryBank(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="beneficiary_bank"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.beneficiaryName}
                  required={true}
                  label={"Name of Beneficiary"}
                  value={beneficiaryName}
                  onChange={(e) => setBeneficiaryName(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="beneficiary_name"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.accountNo}
                  required={true}
                  label={"Account Number"}
                  value={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="account_number"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.branchName}
                  required={true}
                  label={"Name of Branch"}
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="branch_name"
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  error={error?.swiftMTSendTo}
                  label={"Swift MT 103 To Be Sent To"}
                  value={swiftMTSendTo}
                  onChange={(e) => setSwiftMTSendTo(e.target.value)}
                  onFocus={() => setError(null)}
                  autoComplete="swift_mt"
                ></TextInput>
              </Grid>
            </>
          )}
        </Grid>

        <Divider />

        {/* Signatory */}
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>SIGNATORY</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.signatoryName}
              required={true}
              label={"Signatory Name"}
              value={signatoryName}
              onChange={(e) => setSignatoryName(e.target.value)}
              onFocus={() => setError(null)}
              autoComplete="signatory"
            ></TextInput>
          </Grid>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Button disabled={buttonsDisabled} onClick={reset}>Cancel</Button>

        <LoadingButton
          variant="text"
          spinnerSize={18}
          onClick={onNext}
          disabled={buttonsDisabled || !filledRequiredFields()}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}




