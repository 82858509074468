import * as XLSX from "xlsx";
import Validator from "./validator";

const parseToJSON = (file, columnMapping, mappingFunction) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });
      workbook.SheetNames.forEach(function (sheetName) {
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        if (jsonData.length === 0) resolve([]);

        if (columnMapping) {
          const inverseMapping = Object.keys(columnMapping).reduce(
            (acc, key) => {
              acc[columnMapping[key]] = key;
              return acc;
            },
            {}
          );

          const mappedData = jsonData.map((item) => {
            const newItem = {};
            Object.keys(inverseMapping).forEach((key) => {
              if (inverseMapping[key] === "packing") {
                newItem[inverseMapping[key]] = Validator.validatePacking(item[key]) ? item[key] : 1;
              } else {
                newItem[inverseMapping[key]] = item[key] || "";
              }
            });
            return newItem;
          });
          resolve(mappedData);
        }

        if (mappingFunction) {
          const mappedData = jsonData.map(mappingFunction);
          resolve(mappedData);
        } else {
          resolve(jsonData);
        }
      });
    };

    reader.onerror = function (error) {
      console.log(error);
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};

const downloadExcel = (data, fileName, sheetName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet1");
  XLSX.writeFile(wb, fileName);
};

const Excel = {
  parseToJSON,
  downloadExcel,
};

export default Excel;
