import api from "network/api";
import Endpoints from "./endpoints";
import CommonUtils from "utils/common";
import axios from "axios";

const getOrganizations = async () => {
  const res = await api.get(Endpoints.organizations);
  const data = res.data.map(r => ({
    ...r,
    actions: ['View']
  }))
  return data;
};

const getOrganization = async ({ queryKey }) => {
  const organizationId = queryKey[1];
  if (!organizationId) throw new Error("Invalid organization");
  const res = await api.get(`${Endpoints.organizations}/${organizationId}`);
  return res.data;
};

const getOrganizationNames = async () => {
  const res = await api.get(`${Endpoints.organizations}/names`);
  return res.data;
};

const updateOrganization = async (organization) => {
  const updateFields = ["name", "email", 'website', 'contact_no', 'address', 'logo', 'stamp', 'signature'];
  const params = CommonUtils.getNonEmptyFieldsForKeys(organization, updateFields);
  const res = await api.put(`${Endpoints.organizations}/${organization.id}`, params);
  return res.data;
};

const getBankNames = async ({ queryKey }) => {
  const organizationId = queryKey[1];
  if (!organizationId) throw new Error("Invalid organization");
  const res = await api.get(`${Endpoints.organizations}/${organizationId}/banks-details`);
  return res.data
}

const saveBankDetails = async ({ organizationId, bankDetails }) => {
  const res = await api.post(`${Endpoints.organizations}/${organizationId}/bank-details`, bankDetails);
  return res.data;
}

const getUploadUrl = async ({ organizationId, fileName, mime }) => {
  try {

    if (!fileName) throw new Error('Invalid file name');
    if (!organizationId) throw new Error('Invalid organization')

    const res = await api.get(`${Endpoints.organizations}/${organizationId}/upload-url`, { params: { fileName, mime } });
    return res.data;
  } catch (error) {
    throw error
  }
}

const uploadFileWithSignedUrl = async ({ signedUrl, file }) => {
  try {
    const res = await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return res.status === 200;
  } catch (error) {
    throw new Error('Unable to upload file');
  }
}

const OrganizationActions = {
  getOrganization,
  getOrganizations,
  getOrganizationNames,
  updateOrganization,
  getUploadUrl,
  uploadFileWithSignedUrl,
  // Bank Details
  getBankNames,
  saveBankDetails,
};
export default OrganizationActions;
