import {
  Box,
  Button,
  Grid,
  Stack
} from "@mui/material";
import QuotationActions from "actions/quotation";
import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import "styles/quotation-details.css";

import { ActionTypes } from "utils/action_types";
import ExportTypes from "utils/export_types";
import Icons from "utils/icons";

import CurrencyActions from "actions/currency";
import LoadingButton from "components/LoadingButton";

import OrganizationActions from "actions/organization";
import ProductActions from "actions/product";
import ProformaInvoiceActions from "actions/proforma_invoice";
import AlertDialog from "components/AlertDialog";
import CardList from "components/CardList";
import { ClientDetails } from "components/ClientDetails";
import { ClientStatusButtons } from "components/ClientStatusButtons";
import { CurrencyDetails } from "components/CurrencyDetails";
import { DownloadDocumentsDialog } from "components/DownloadDocumentsDialog";
import DynamicFormDialog from "components/DynamicFormDialog";
import LoadingContainer from "components/LoadingContainer";
import { OrganizationDetails } from "components/OrganizationDetails";
import ProductCard from "components/ProductCard";
import ProfInvoiceDetailsDialog from "components/ProfInvoiceDetailsDialog";
import { QuotationHistoryDialog } from "components/QuotationHistoryDialog";
import { QuotationStateButtons } from "components/QuotationStateButtons";
import { ReminderDetails } from "components/ReminderDetails";
import SearchProductsInput from "components/SearchProductsInput";
import SubHeader from "components/SubHeader";
import TextInput from "components/TextInput";
import UserContext from "context/UserContext";
import { QuotationDetailsHelper } from "helpers/quotations_details";
import QuotationProductTransform from "transforms/quotation_product";
import AccountType from "utils/account_type";
import { ClientStatus } from "utils/client_status";
import DateTimeUtils from "utils/date_time";
import { DialogActionTypes } from "utils/dialog_action_types";
import DocumentTypes from "utils/document_types";
import ErrorUtils from "utils/error";
import Excel from "utils/excel";
import { PageTypes } from "utils/page_types";
import ProductUtils from "utils/product";
import { QuotationStates } from "utils/quotation_states";
import Roles from "utils/roles";
import SortUtils from "utils/sort";
import ErrorFallback from "./ErrorFallback";

const filterColumnsForRole = (role) => (h) => {
  if (role === Roles.MEMBER) {
    return ![
      "percentage",
      "total",
      "total_currency",
      "price_per_unit",
      "price_per_unit_currency",
      "price_per_pack",
    ].includes(h.key);
  }
  if (role === Roles.MEMBER_PLUS) {
    return ![
      "percentage",
      "total_currency",
      "price_per_unit_currency",
      "price_per_pack",
    ].includes(h.key);
  }
  return true;
};

const filterColumnsForClientStatus = (client_status) => (h) => {
  if (client_status !== ClientStatus.PENDING) {
    return !['actions'].includes(h.key);
  }
  return true
};

const headers = (data) => {
  const _headers = [
    {
      key: "SR",
      label: "SR",
      tooltipText: "SERIAL NUMBER",
      width: 60,
      align: "center",
      transform: (row, i) => {
        return `${i + 1}.`;
      },
    },
    {
      key: "name",
      label: "PRODUCT NAME",
      tooltipText: "PRODUCT NAME",
      width: 320,
      align: "left"
    },
    {
      key: "packing",
      label: "PKG",
      tooltipText: "PACKING",
      placeholder: "eg: 1x10, 1, 1mg, 1g, 1ml",
      width: 80,
      align: "center",
      transform: QuotationProductTransform.transformPacking,
    },
    {
      key: "unit",
      label: "UNIT",
      tooltipText: "UNIT",
      width: 80,
      align: "center"
    },
    {
      key: "grade",
      label: "GRADE",
      tooltipText: "GRADE",
      width: 80,
      align: "center",
      dropdown: true,
      options: ProductUtils.getProductGradeOptions(),
      defaultSelected: 0,
      showNone: false
    },
    {
      key: "packing_type",
      label: "PKG TYPE",
      tooltipText: "PACKING TYPE",
      width: 120,
      align: "center",
    },
    {
      key: "manufacturer",
      label: "MFR",
      tooltipText: "MANUFACTURER",
      width: 100,
      align: "center",
    },
    {
      key: "moq",
      label: "MOQ",
      tooltipText: "MINIMUM ORDER QUANTITY",
      width: 120,
      align: "center",
      editable: true,
      type: "number",
      transform: QuotationProductTransform.transformMOQ,
    },
    {
      key: "percentage",
      label: "%",
      tooltipText: "PERCENTAGE",
      width: 60,
      align: "center",
      editable: true,
      type: "number",
      transform: QuotationProductTransform.transformPercentage,
      tooltip: true,
      tooltipTransform: QuotationProductTransform.transformPercentageTooltip,
    },
    {
      key: "price_per_unit",
      label: "FOB PPU (₹)",
      tooltipText: "FOB PRICE PER UNIT (INR)",
      width: 100,
      align: "center",
      editable: true,
      type: "number",
      transform: QuotationProductTransform.transformPricePerUnit,
      tooltip: true,
      tooltipTransform: QuotationProductTransform.transformPricePerUnitTooltip,
    },
    {
      key: "price_per_unit_currency",
      label: "FOB PPU " + (data?.currency ? `(${data.currency})` : ""),
      tooltipText:
        "FOB PRICE PER UNIT " + (data?.currency ? `(${data.currency})` : ""),
      width: 100,
      align: "center",
      type: "number",
      transform: (v) =>
        QuotationProductTransform.transformPricePerUnitCurrency(v, data),
    },
    {
      key: "price_per_pack",
      label: "FOB PPP (₹)",
      tooltipText: "FOB PRICE PER PACK (INR)",
      width: 100,
      align: "center",
      transform: QuotationProductTransform.transformPricePerPack,
    },
    {
      key: 'freight',
      label: 'FREIGHT',
      width: 100,
      align: 'center',
      dropdown: true,
      transform: QuotationProductTransform.transformFreight,
      options: ProductUtils.getProductFreightOptions(),
      defaultSelected: 0,
      showNone: false
    },
    {
      key: "total",
      label: "TOTAL (₹)",
      width: 100,
      align: "center",

      transform: QuotationProductTransform.transformTotal,
    },
    {
      key: "total_currency",
      label: "TOTAL " + (data?.currency ? `(${data.currency})` : ""),
      width: 100,
      align: "center",
      transform: (v) =>
        QuotationProductTransform.transformTotalCurrency(v, data),
    },
    {
      key: 'delivery',
      label: 'DELIVERY',
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      key: "actions",
      label: "ACTIONS",
      width: 100,
      align: "center",
      items: {
        Edit: Icons.Edit,
        Delete: Icons.Delete,
      },
    },
  ];

  let filteredHeaders = ProductUtils.getHeaders(_headers, data.user, data?.state)
  filteredHeaders = filteredHeaders.filter(filterColumnsForRole(data.user.role));
  filteredHeaders = filteredHeaders.filter(filterColumnsForClientStatus(data.client_status));
  return filteredHeaders;
};

export default function QuotationDetails({ mobile }) {
  const { user } = useContext(UserContext);

  const { quotationId } = useParams();
  const snackbar = useSnackbar();

  const { setLoading } = useContext(LoadingContext);
  const [resetTable, setResetTable] = useState(false);
  const [quotationHistoryOpen, setQuotationHistoryOpen] = useState(false);
  const [emailAlertDialogOpen, setEmailAlertDialogOpen] = useState(false);
  const [profInvoiceDialogOpen, setProfInvoiceDialogOpen] = useState(false);
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [currency, setCurrency] = useState("");
  const [conversionRate, setConversionRate] = useState("");
  const [organization, setOrganization] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);
  const [paymentTerms, setPaymentTerms] = useState("");
  const [remark, setRemark] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const mDeleteQuotationProducts = useMutation({
    mutationKey: "delete-quotation-products",
    mutationFn: QuotationActions.deleteQuotationProducts,
    onSuccess: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Deleted", {
        variant: "success",
      });
      fetchQuotationProducts();
    },
    onError: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Failed to delete", {
        variant: "error",
      });
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const mCreateQuotationProducts = useMutation({
    mutationKey: "create-quotation-products",
    mutationFn: QuotationActions.createQuotationProducts,
    onSuccess: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Added", {
        variant: "success",
      });
    },
    onError: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Failed to create", {
        variant: "error",
      });
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const mUpdateQuotationProducts = useMutation({
    mutationKey: "update-quotation-products",
    mutationFn: QuotationActions.updateQuotationProduct,
    onSuccess: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Updated", {
        variant: "success",
      });
      fetchQuotationProducts();
    },
    onError: (error) => {
      setResetTable(!resetTable);

      setLoading(false);
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const [changeDetected, setChangeDetected] = useState(false);
  const listenForChange = () => {
    if (!changeDetected) {
      setChangeDetected(true);
    }
  };

  const onAction = async (actionType, row, index, column, sorting, checked) => {
    try {
      if (actionType === ActionTypes.EDIT) {
        setSelectedProduct(row);
        setSelectedProductIndex(index);
      }

      if (actionType === ActionTypes.SAVE) {
        const _rows = [...products];
        _rows[index] = row;
        await mUpdateQuotationProducts.mutateAsync({
          quotationId: quotationId,
          productId: row.id,
          data: {
            moq: row.moq || 0,
            price_per_unit: row.price_per_unit || 0,
            percentage: row.percentage || 0,
            grade: row.grade,
            delivery: row.delivery,
            unit: row.unit,
            manufacturer: row.manufacturer,
            freight: row.freight || 0,
            freight_amount: row.freight_amount || 0,
          },
        });
        setProducts(_rows);
        listenForChange();
        return true;
      }
      if (actionType === ActionTypes.DELETE) {
        const _rows = [...products];
        _rows.splice(index, 1);
        await mDeleteQuotationProducts.mutateAsync({
          quotationId: quotationId,
          ids: [row.id],
        });
        setProducts(_rows);
        listenForChange();
        return true
      }
      if (actionType === ActionTypes.CHECK) {
        const _rows = [...products];
        const _idx = _rows.findIndex((item) => item.id === row.id);
        _rows[_idx].checked = checked;
        _rows[_idx].newlyChecked = checked;
        setProducts(_rows);

        if (searchResults.length > 0) {
          const _searchResults = [...searchResults];
          _searchResults[index].checked = true;
          _searchResults[index].newlyChecked = checked;
          setSearchResults(_searchResults);
        }
        listenForChange();
      }

      if (
        actionType === ActionTypes.SORT_ASC ||
        actionType === ActionTypes.SORT_DESC
      ) {
        setProducts(SortUtils.sortByString(products, column.key, sorting));
      }
    }
    catch (error) {

    }
  };

  const { data: currencies } = useQuery({
    enabled: user.role === Roles.ADMIN,
    queryKey: ["get-currencies"],
    queryFn: CurrencyActions.getCurrencies,
  });

  const { data: organizations } = useQuery({
    queryKey: ["get-organization-names"],
    queryFn: OrganizationActions.getOrganizationNames,
  });

  const {
    data: quotationDetails,
    isLoading: quotationDetailsLoading,
    refetch: fetchQuotationDetails,
    error: quotationDetailsError,
  } = useQuery({
    queryKey: ["get-quotation", quotationId, PageTypes.QUOTATIONS],
    queryFn: QuotationActions.getQuotation,
    enabled: !!quotationId,
    onError: (error) => {
      if (error.response.status === 403) {
        snackbar.enqueueSnackbar("You are not authorized to view this page", {
          variant: "error",
        });
        window.location.href = "/";
      } else {
        snackbar.enqueueSnackbar(error.response?.data?.message || "Not found", {
          variant: "error",
        });
      }
    },
  });

  useEffect(() => {
    if (quotationDetails?.currency && currencies) {
      const _currency = quotationDetails?.currency;
      setCurrency(_currency);

      const selectedCurrency = currencies.find((c) => c.currency === _currency);

      const _conversionRate = quotationDetails?.conversion_rate;
      if (_conversionRate) {
        setConversionRate(_conversionRate);
      } else {
        if (selectedCurrency) {
          setConversionRate(selectedCurrency?.conversion_rate || 1);
        }
      }
    }
  }, [
    quotationDetails?.currency,
    quotationDetails?.conversion_rate,
    currencies,
  ]);

  // select organization if quotationDetails?.organization_id is present
  useEffect(() => {
    if (quotationDetails?.organization_id && organizations) {
      const _organization = organizations.find(
        (o) => o.id === quotationDetails?.organization_id
      );
      setOrganization(_organization.id);
    }
  }, [quotationDetails?.organization_id, organizations]);

  // If no currency is selected, set USD selected by default
  useEffect(() => {
    if (
      !quotationDetails?.currency &&
      !quotationDetails?.conversion_rate &&
      currencies &&
      !currency
    ) {
      const _currency = currencies.find((c) => c.currency === "USD");
      setCurrency(_currency?.currency);
      setConversionRate(_currency?.conversion_rate);
    }
  }, [quotationDetails, currencies, currency]);

  useEffect(() => {
    if (quotationDetails?.payment_terms) {
      setPaymentTerms(quotationDetails.payment_terms);
    }
    if (quotationDetails?.remark) {
      setRemark(quotationDetails.remark);
    }
  }, [quotationDetails]);

  const mUpdateQuotationDetails = useMutation({
    mutationKey: "update-quotation-details",
    mutationFn: QuotationActions.updateQuotation,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      snackbar.enqueueSnackbar("Quotation updated successfully", {
        variant: "success",
      });
      fetchQuotationDetails();
    }
  });

  const {
    data: quotationProducts,
    isLoading: quotationProductsLoading,
    refetch: fetchQuotationProducts,
  } = useQuery({
    queryKey: ["get-quotation-products", quotationId],
    queryFn: QuotationActions.getQuotationProducts,
    enabled: !!quotationId,
    onError: (error) => {
      setLoading(false);
      snackbar.enqueueSnackbar(
        error.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    },
    onSuccess: (data) => {
      setProducts(
        data?.data?.map((item) => {
          delete item.newlyChecked;
          return { ...item, checked: false, newlyChecked: false };
        })
      );
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(
      quotationProductsLoading || quotationDetailsLoading
    );
    // eslint-disable-next-line
  }, [quotationProductsLoading, quotationDetailsLoading]);

  const onProductSelect = async (product) => {
    setSelectedProduct(product)
    setSelectedProductIndex(-1);
  }

  const onAddNewProduct = () => {
    const product = QuotationDetailsHelper.getProductEmptyObject(user);
    setSelectedProduct(product);
    setSelectedProductIndex(-1)
  }

  const addQuotationProduct = async (product) => {
    const newProduct = {
      quotation_id: quotationId,
      product_id: product.id,
      moq: product.moq || 0,
      price_per_unit: product.price_per_unit || 0,
      unit: product.unit,
      grade: product.grade,
      manufacturer: product.manufacturer,
      delivery: product.delivery,
      freight: product?.freight || 0,
      freight_amount: product.freight_amount || 0,
      percentage: product.percentage || 0,
    }

    await mCreateQuotationProducts.mutateAsync({
      quotationId: quotationId,
      products: [newProduct],
    });
    listenForChange();
    fetchQuotationProducts();

    return true;
  }

  const { refetch: fetchProfInvoiceDocUrl, isLoading: profInvoiceDocUrlLoading } = useQuery({
    queryKey: ["get-prof-invoice-doc", quotationId],
    queryFn: ProformaInvoiceActions.getProfInvoiceDocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
  });

  const { refetch: fetchDocUrl } = useQuery({
    queryKey: ["get-quotation-doc", quotationId],
    queryFn: QuotationActions.getQuotationDocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        error.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    },
  });

  const { refetch: fetchDocxUrl } = useQuery({
    queryKey: ["get-quotation-docx", quotationId],
    queryFn: QuotationActions.getQuotationDocxUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        error.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    },
  });

  const mGenerateDoc = useMutation({
    mutationKey: "generate-doc",
    mutationFn: QuotationActions.generateQuotationDoc,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
    onSuccess: () => {
      fetchQuotationDetails();
      snackbar.enqueueSnackbar("Document generated", {
        variant: "success",
      });
    },
  });

  const mGenerateDocx = useMutation({
    mutationKey: "generate-docx",
    mutationFn: QuotationActions.generateQuotationDocx,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
    onSuccess: () => {
      fetchQuotationDetails();
      snackbar.enqueueSnackbar("Word document generated", {
        variant: "success",
      });
    },
  });

  const shouldGenerateNewDoc =
    changeDetected ||
    (quotationDetails?.latest_doc_updated_at &&
      quotationProducts?.last_updated_at
      ? new Date(quotationDetails.latest_doc_updated_at).getTime() <
      new Date(quotationProducts.last_updated_at).getTime()
      : true);

  const generateNewDoc = async () => {
    const res = await mGenerateDoc.mutateAsync({ quotationId });
    return res?.success;
  };

  const generateNewDocx = async () => {
    const res = await mGenerateDocx.mutateAsync({ quotationId });
    return res?.success;
  };

  const onDownload = async (exportType) => {
    if (exportType === ExportTypes.PDF) {
      onPDFDownload()
    }
    else if (exportType === ExportTypes.EXCEL) {
      onExcelDownload()
    }
    else if (exportType === ExportTypes.WORD) {
      onWordDownload()
    }
  };

  const { refetch: fetchExcelData } = useQuery({
    queryKey: ["get-excel-data", quotationId],
    queryFn: QuotationActions.getExcelData,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    }
  })
  const onExcelDownload = async () => {

    const { data } = await fetchExcelData();
    const _currency = data.currency.currency || { currency: "" };
    const excelData = data.data || [];
    let rows = [];
    if (user.account_type === AccountType.DISTRIBUTOR) {
      rows = excelData.map((item, index) => {
        return {
          "SR NO": index + 1,
          "PRODUCT NAME": item.name,
          "PACKING": item.packing,
          MOQ: item.moq,
          [`PRICE PER UNIT (${_currency})`]: item.price_per_unit,
          [`PRICE PER PACK (${_currency})`]: item.price_per_pack,
          [`TOTAL (${_currency})`]: item.total_currency,
        };
      }
      );
    }
    if (user.account_type === AccountType.MANUFACTURER) {
      rows = excelData.map((item, index) => {
        return {
          "SR NO": index + 1,
          "PRODUCT NAME": item.name,
          "UNIT": item.unit,
          "GRADE": item.grade,
          "MANUFACTURER": item.manufacturer,
          QUANTITY: item.moq,
          [`PRICE PER UNIT (${_currency})`]: item.price_per_unit,
          "DELIVERY": item.delivery,
          "FREIGHT": item.freight,
          [`TOTAL (${_currency})`]: item.total_currency,
        };
      }
      );
    }

    const fileName = `Quotation_${DateTimeUtils.getCurrentDateForFileName()}.xlsx`
    Excel.downloadExcel(rows, fileName);
  }
  const onPDFDownload = async () => {
    if (shouldGenerateNewDoc) {
      snackbar.enqueueSnackbar("Generating quotation. Please wait...", {
        variant: "info",
      });

      const success = await generateNewDoc();
      if (!success) {
        snackbar.enqueueSnackbar(
          "Could not generate document. Please try again later.",
          {
            variant: "error",
          }
        );
        return;
      }

      setChangeDetected(false);
    }

    const res = await fetchDocUrl();
    const url = res.data.data;
    window.open(url, "_blank");
  }
  const onWordDownload = async () => {
    if (shouldGenerateNewDoc) {
      snackbar.enqueueSnackbar("Generating quotation. Please wait...", {
        variant: "info",
      });

      const success = await generateNewDocx();
      if (!success) {
        snackbar.enqueueSnackbar(
          "Could not generate document. Please try again later.",
          {
            variant: "error",
          }
        );
        return;
      }

      listenForChange();
    }

    const res = await fetchDocxUrl();
    const url = res.data.data;
    window.open(url, "_blank");
  }

  const onProfInvoiceDownload = async () => {
    const res = await fetchProfInvoiceDocUrl();
    const url = res.data.data;
    window.open(url, "_blank");
  }

  const onCurrencyChange = async (e) => {
    const selectedCurrency =
      currencies.find((c) => c.currency === e.target.value) || null;

    if (selectedCurrency) {
      mUpdateQuotationDetails.mutate({
        quotationId,
        data: {
          currency: selectedCurrency.currency,
          conversion_rate: selectedCurrency.conversion_rate,
        },
      });

      setConversionRate(selectedCurrency.conversion_rate);
    } else {
      setConversionRate(1);
    }
    setCurrency(e.target.value);

    listenForChange();
  };

  const onConversionRateChange = (value) => {
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        conversion_rate: Number(value),
      },
    });

    setConversionRate(value);
  };

  const onOrganizationChange = (organizationId) => {
    setOrganization(organizationId);
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        organization_id: organizationId,
      },
    });
  };

  const onQuotationStateChange = (state) => {
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        state,
      },
    });
  }

  const onQuotationClientStatusChange = (clientStatus) => {
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        client_status: clientStatus,
      },
    });
  }

  const editingProduct = selectedProductIndex > -1;
  const actionType = selectedProduct?.id ? ActionTypes.EDIT : ActionTypes.ADD_NEW;

  const resetProductDialog = () => {
    setSelectedProduct(null)
    setSelectedProductIndex(-1);

  }

  const onSave = () => {
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        payment_terms: paymentTerms,
        remark: remark,
      },
    });
    listenForChange()
  }

  const onEditProductRow = (row, index) => {
    setSelectedProduct(row);
    setSelectedProductIndex(index);
  };

  const onProductEditingFinished = async (data) => {
    await onAction(ActionTypes.SAVE, data, selectedProductIndex);
    resetProductDialog();
  }

  const onFormSubmit = async (action, data) => {
    let quotationProduct = {
      id: selectedProduct?.id,
      ...data
    }
    if (actionType === ActionTypes.ADD_NEW) {
      const res = await ProductActions.createProduct(data)
      quotationProduct.id = res.id;
    }
    if (editingProduct) {
      onProductEditingFinished(quotationProduct)
    } else {
      if (await addQuotationProduct(quotationProduct)) {
        resetProductDialog();
      }
    }
  }


  const headerData = React.useMemo(() => ({
    currency: currency,
    conversion_rate: conversionRate,
    user: user,
    client_status: quotationDetails?.client_status,
    state: quotationDetails?.state,
  }), [currency, conversionRate, user, quotationDetails?.client_status, quotationDetails?.state]);

  const tableHeaders = React.useMemo(() => headers(headerData), [headerData]);

  if (quotationDetailsError) {
    return <ErrorFallback error={quotationDetailsError} />;
  }

  if (quotationDetailsLoading || !quotationDetails) {
    return <LoadingContainer height={"70vh"} width="100%" />;
  }

  const headerText = quotationDetails?.quotation_number ? `Quotation Details : ${quotationDetails?.quotation_number || ""}` : "Quotation Details"

  return (
    <Box>
      <SubHeader title={headerText} />

      {!!quotationDetails && (
        <>
          <ClientDetails data={quotationDetails} />

          {user.role === Roles.ADMIN && user.account_type === AccountType.DISTRIBUTOR && (
            <CurrencyDetails
              disabled={!!quotationDetails?.client_status}
              currencies={currencies || []}
              currency={currency}
              rate={conversionRate}
              onCurrencyChange={onCurrencyChange}
              onConversionRateChange={onConversionRateChange}
              listenForChange={listenForChange}
            />
          )}

          <OrganizationDetails
            disabled={!!quotationDetails?.client_status}
            organizations={organizations || []}
            organization={organization}
            onOrganizationChange={onOrganizationChange}
            listenForChange={listenForChange}
          />

          {!quotationDetails?.client_status &&
            <ReminderDetails
              disabled={!!quotationDetails?.client_status}
              data={{
                quotationId,
                client_name: quotationDetails.client_name
              }}
            />
          }
          {
            user.account_type === AccountType.MANUFACTURER &&
            <>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    size="small"
                    label="Payment Terms"
                    value={paymentTerms}
                    fullWidth
                    onChange={(e) => setPaymentTerms(e.target.value)}
                  ></TextInput>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    size="small"
                    label="Remark"
                    value={remark}
                    fullWidth
                    onChange={(e) => setRemark(e.target.value)}
                  ></TextInput>
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
              <Box mt={2}>
                <LoadingButton
                  loading={mUpdateQuotationDetails.isLoading}
                  size="small"
                  variant="outlined"
                  onClick={onSave}
                  startIcon={<Icons.Save />}
                >Save</LoadingButton>
              </Box>
            </>
          }
        </>
      )}

      <Box mt={4}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          {!quotationDetails?.prof_invoice_doc_url ?
            <Stack direction={"row"} width={600}>
              {
                quotationDetails?.client_status === ClientStatus.PENDING &&
                <SearchProductsInput
                  onSelect={onProductSelect}
                  exclusions={products.map(p => p.product_id)}
                  onAddNew={onAddNewProduct}
                />
              }
            </Stack>
            :
            <Button
              variant="text"
              onClick={() => setProfInvoiceDialogOpen(true)}
              startIcon={<Icons.EditProfInvoice />}
            >
              Update Proforma Invoice
            </Button>
          }

          {products.length > 0 &&
            <Stack direction={"row"} spacing={mobile ? 0 : 2} flexWrap="wrap" sx={{ zIndex: 1, mt: mobile ? 2 : 0 }}>
              <ClientStatusButtons
                onClientStatusChange={onQuotationClientStatusChange}
                quotationDetails={quotationDetails}
              />

              {user.role === Roles.ADMIN && (
                <QuotationStateButtons
                  onQuotationStateChange={onQuotationStateChange}
                  quotationDetails={quotationDetails}
                />
              )}

              {quotationDetails?.client_status === ClientStatus.ACCEPTED && !quotationDetails?.prof_invoice_doc_url && (
                <LoadingButton
                  spinnerSize={20}
                  loading={false}
                  variant="text"
                  onClick={() => setProfInvoiceDialogOpen(true)}
                  startIcon={<Icons.GeneratePdf />}
                >
                  Generate Proforma Invoice
                </LoadingButton>
              )}

              {
                quotationDetails?.prof_invoice_doc_url && quotationDetails?.latest_doc_url && (
                  <Button onClick={() => setDocumentsDialogOpen(true)} variant="text" startIcon={<Icons.Download />}>Download Documents</Button>
                )
              }

              {
                (!quotationDetails?.prof_invoice_doc_url || !quotationDetails?.latest_doc_url) && (
                  <>
                    {quotationDetails?.prof_invoice_doc_url && (
                      <LoadingButton
                        spinnerSize={20}
                        loading={profInvoiceDocUrlLoading}
                        variant="text"
                        onClick={onProfInvoiceDownload}
                        startIcon={<Icons.GeneratePdf />}
                      >
                        Download Proforma Invoice PDF
                      </LoadingButton>
                    )}

                    <LoadingButton
                      disabled={
                        products.length === 0 ||
                        !organization ||
                        quotationDetails?.state !== QuotationStates.APPROVED
                      }
                      spinnerSize={20}
                      loading={mGenerateDoc.isLoading}
                      variant="text"
                      onClick={() => onDownload(ExportTypes.PDF)}
                      startIcon={<Icons.GeneratePdf />}
                    >
                      Download Quotation PDF
                    </LoadingButton>
                    <LoadingButton
                      disabled={
                        products.length === 0 ||
                        !organization ||
                        quotationDetails?.state !== QuotationStates.APPROVED
                      }
                      variant="text"
                      onClick={() => onDownload(ExportTypes.EXCEL)}
                      startIcon={<Icons.Excel />}
                    >
                      Download Excel
                    </LoadingButton>
                    <LoadingButton
                      disabled={
                        products.length === 0 ||
                        !organization ||
                        quotationDetails?.state !== QuotationStates.APPROVED
                      }
                      variant="text"
                      onClick={() => onDownload(ExportTypes.WORD)}
                      startIcon={<Icons.Excel />}
                    >
                      Download Word
                    </LoadingButton>
                  </>
                )}
            </Stack>
          }
        </Stack>
      </Box>


      {mobile ? (
        <CardList
          headers={tableHeaders}
          rows={products}
          onAction={onAction}
          showActions={!quotationDetails?.client_status}
          Component={ProductCard}
          reset={resetTable}
          currentRowsPerPage={products.length}
          onEdit={onEditProductRow}
        />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={products}
          onAction={onAction}
          reset={resetTable}
        />
      )}

      <QuotationHistoryDialog
        quotationId={quotationId}
        open={quotationHistoryOpen}
        onClose={() => setQuotationHistoryOpen(false)}
      />

      <AlertDialog
        open={emailAlertDialogOpen}
        title={"Preview required"}
        message={"Please preview the quotation before sending it"}
        onClose={() => setEmailAlertDialogOpen(false)}
      ></AlertDialog>

      <ProfInvoiceDetailsDialog
        open={profInvoiceDialogOpen}
        onClose={() => setProfInvoiceDialogOpen(false)}
        data={
          {
            quotationId: quotationId,
            organizationId: quotationDetails?.organization_id,
          }
        }
        edit={false}
        onSuccess={fetchQuotationDetails}
      />

      <DownloadDocumentsDialog
        data={quotationDetails}
        open={documentsDialogOpen}
        onClose={() => setDocumentsDialogOpen(false)}
        actions={{
          downloadProformaInvoice: onProfInvoiceDownload,
          downloadQuotation: () => onDownload(ExportTypes.PDF),
          downloadExcel: () => onDownload(ExportTypes.EXCEL),
        }}
        documentTypes={[
          DocumentTypes.QUOTATION,
          DocumentTypes.PROF_INVOICE,
          DocumentTypes.PURCHASE_ORDER,
          DocumentTypes.EXCEL,
        ]}
      />

      <DynamicFormDialog
        values={selectedProduct}
        open={!!selectedProduct}
        title="Product Details"
        config={QuotationDetailsHelper.getProductFormConfig(user, actionType)}
        actionType={DialogActionTypes.SAVE}
        onAction={onFormSubmit}
        onClose={resetProductDialog}
      >
      </DynamicFormDialog>
    </Box >
  );
}


