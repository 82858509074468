import { ButtonBase, List, ListItem, Paper, Stack, styled } from '@mui/material'
import ProductActions from 'actions/product'
import LoadingContext from 'context/LoadingContext'
import { debounce } from 'lodash'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { fonts } from 'styles/fonts'
import SearchInput from './SearchInput'
import UserContext from 'context/UserContext'
import { UserUtils } from 'utils/user'

const StyledListItem = styled(ListItem)(({ theme }) => ({
    ...fonts.regular,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.primary.lighter,
        // color: theme.palette.common.white,
    },
}));

export default function SearchProductsInput({
    onSelect,
    exclusions = [],
    onAddNew
}) {
    const { user } = useContext(UserContext);

    const listRef = useRef(null);

    const { setLoading } = useContext(LoadingContext);

    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [showNoResultsMsg, setShowNoResultsMsg] = useState(false);

    const { isLoading, refetch: fetchProducts } = useQuery({
        enabled: false,
        queryKey: ["get-products", searchText],
        queryFn: ProductActions.getProducts,
        onSuccess: (data) => {
            setProducts(data.data);
            setShowNoResultsMsg(data.data.length === 0);
            focusList();
        },
    });

    const focusList = () => {
        if (listRef.current) {
            listRef.current.focus();
        }
    }

    useEffect(() => {
        setLoading(!!isLoading)
    }, [isLoading, setLoading]);

    const onSearch = useMemo(() => debounce((searchText) => {
        if (searchText?.length > 2) {
            fetchProducts();
        }
    }, 400), [fetchProducts]);

    useEffect(() => {
        onSearch(searchText);
        // eslint-disable-next-line
    }, [searchText]);

    const resetSearch = () => {
        setSearchText("");
        setProducts([]);
        setShowNoResultsMsg(false);
    }

    const _onSelect = (product) => {

        if (onSelect) {
            onSelect(product);
        }
        resetSearch();
    }

    const getProductLabel = (product) => {
        let label = product.name;
        if (product.manufacturer) {
            label += ` (${product.manufacturer})`;
        }
        if (product.packing_type) {
            label += ` (${product.packing_type})`;
        }
        if (product.packing) {
            label += ` (${product.packing})`;
        }
        return label;
    }

    const rows = products.filter(p => !exclusions.includes(p.id))

    return (
        <Stack position={'relative'} width={'100%'} direction={'row'} alignItems={'center'}
            style={{ zIndex: 101 }}
            onBlur={() => {
                setTimeout(resetSearch, 500);
            }}
        >
            <SearchInput
                placeholder="Search Products by Name, Manufacturer, Packing Type..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={onSearch}
            />
            {
                (rows.length > 0 || showNoResultsMsg) &&
                <Paper elevation={5} sx={{
                    position: 'absolute',
                    top: '100%',
                    width: '100%',
                    backgroundColor: '#FFF',
                }}>
                    {
                        showNoResultsMsg &&
                        <>
                            <StyledListItem sx={{ padding: 2 }}>
                                No Products Found
                            </StyledListItem>
                        </>
                    }
                    {rows.length > 0 &&
                        <List
                            ref={listRef}
                            sx={{
                                maxHeight: 300,
                                overflowY: 'auto',
                            }}>
                            {rows.map((product) => {
                                return (
                                    <StyledListItem
                                        sx={{
                                            cursor: 'pointer',
                                        }} key={product.id}
                                        onClick={() => _onSelect(product)}
                                    >
                                        {
                                            getProductLabel(product)
                                        }
                                    </StyledListItem>
                                )
                            })}
                        </List>
                    }

                    {UserUtils.hasProductsAccess(user) &&
                        < AddNewButton onClick={onAddNew} />
                    }
                </Paper>
            }
        </Stack>
    )
}

function AddNewButton({ onClick }) {
    return (
        <ButtonBase style={{ width: '100%' }} onClick={onClick}>
            <StyledListItem sx={{
                p: 2,
                color: 'primary.main',
                bgcolor: 'primary.lighter',
            }}>
                + ADD NEW
            </StyledListItem>
        </ButtonBase>
    )
}
