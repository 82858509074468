import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { fontSizes } from 'styles/fonts'

export default function RadioButtonsGroup({ label, value, onChange, items, disabled }) {
    return (
        <FormControl
            disabled={disabled}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <FormLabel
                sx={{
                    mr: 2,
                    fontWeight: 'bold',
                    fontSize: fontSizes.sm
                }}
            >{label}</FormLabel>
            <RadioGroup
                row
                value={value}
                onChange={onChange}
            >
                {items.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio />}
                        label={
                            <span style={{
                                fontSize: fontSizes.sm
                            }}>
                                {item.label}
                            </span>
                        }
                    />
                ))
                }
            </RadioGroup>
        </FormControl>
    )
}
