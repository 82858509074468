import MathUtils from "utils/math";

const precision = 3;

const precisionFloat = (value, decimals) =>
  Number(parseFloat(value).toFixed(decimals ? decimals : precision));

const PurchaseOrderTransform = {
  transformPricePerPack: (v) => {
    const ppp = precisionFloat(parseFloat(
      MathUtils.evaluateMul(v["packing"]) * v["po_price_per_unit"]
    ));
    v.po_price_per_pack = ppp
    return ppp;
  },
  transformQuantityPerPack: (v) => {
    const qpp = precisionFloat(parseFloat(
      (v["po_quantity_per_unit"] || 1) /
      MathUtils.evaluateMul(v["packing"])
    ));
    v.po_quantity_per_pack = qpp
    return qpp;
  },
  transformTotal: (v) => {
    return precisionFloat(v.po_price_per_pack * v.po_quantity_per_pack);
  },
};

export default PurchaseOrderTransform;
