import * as React from "react";

import { useMutation, useQuery } from "react-query";

import { fontSizes, fontWeights } from "styles/fonts";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";

import AutoCompleteCreateInput from "./AutoCompleteCreateInput";
import LoadingButton from "./LoadingButton";

import ClientActions from "actions/client";
import OrganizationActions from "actions/organization";
import QuotationActions from "actions/quotation";
import { useSnackbar } from "notistack";
import ErrorUtils from "utils/error";
import Dropdown from "./Dropdown";
import useLayout from "hooks/useLayout";

export default function AddQuotationDialog({ open, setOpen, next }) {
  const { isMobile } = useLayout();
  const snackbar = useSnackbar();

  const [company, setCompany] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [organizationId, setOrganizationId] = React.useState("");

  const [error, setError] = React.useState(null);

  const reset = () => {
    setCompany(null);
    setName(null);
    setEmail(null);
    setPhone(null);
    setCountry(null);
    setOrganizationId("");
    setError(null);
    mCreateQuotation.reset();
  };

  const mCreateQuotation = useMutation({
    mutationKey: "create-quotation",
    mutationFn: QuotationActions.createQuotation,
    onSuccess: (data) => {
      snackbar.enqueueSnackbar("Quotation created", {
        variant: "success",
      });
      reset();
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
  });

  const { data: clientNames, isLoading: clientNamesLoading } = useQuery({
    queryKey: ["client-names"],
    queryFn: ClientActions.getClientNames,
  });

  const { data: companyNames, isLoading: companyNamesLoading } = useQuery({
    queryKey: ["company-names"],
    queryFn: ClientActions.getClientCompanies,
  });

  const { data: clientDetails } = useQuery({
    queryKey: ["client-details", name?.id || company?.id],
    queryFn: ClientActions.getClientDetails,
    enabled: !!name?.id || !!company?.id,
  });

  const { data: organizationNames } = useQuery({
    queryKey: ["get-organization-names"],
    queryFn: OrganizationActions.getOrganizationNames,
  });

  React.useEffect(() => {
    if (clientDetails) {
      setName({ title: clientDetails?.name || "" });
      setCompany({ title: clientDetails?.company || "" });
      setEmail({ title: clientDetails?.email || "" });
      setPhone({ title: clientDetails?.phone || "" });
      setCountry({ title: clientDetails?.country || "" });
    }
  }, [clientDetails]);

  React.useEffect(() => {
    (function () {
      if (!organizationNames || !Array.isArray(organizationNames || !open))
        return;

      const _organizationId = localStorage.getItem("organization");
      if (!_organizationId) {
        setOrganizationId(organizationNames[0]?.id);
        return;
      };

      const organization = organizationNames.find(
        (org) => org.id === parseInt(_organizationId, 10)
      );
      if (!organization) return;

      setOrganizationId(organization?.id);
    })();
  }, [organizationNames, open]);

  const onOrganizationChange = (e) => {
    setError({
      ...error,
      organization: false,
    });

    setOrganizationId(e.target.value);
    localStorage.setItem("organization", e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onNext = async () => {
    const data = {
      name: name?.title,
      company: company?.title,
      email: email?.title,
      phone: phone?.title,
      country: country?.title,
      organization: organizationId,
    };

    const requiredFields = [
      "name",
      "company",
      "email",
      "phone",
      "country",
      "organization",
    ];
    const _error = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        _error[field] = true;
      }
    });
    setError(_error);

    if (Object.keys(_error).length > 0) {
      snackbar.enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return;
    }

    const createRes = await mCreateQuotation.mutateAsync(data);

    if (next) {
      next({
        id: createRes.id || `temp_${Math.round(Math.random() * 100)}`,
        client_name: data.name,
        client_company: data.company,
        client_email: data.email,
        client_phone: data.phone,
        client_country: data.country,
      });
    }
  };

  return (
    <Dialog open={open} PaperProps={{
      sx: {
        width: isMobile ? '100%' : '50%',
      }
    }}>
      {(clientNamesLoading || companyNamesLoading) && <LinearProgress />}
      <DialogTitle sx={{ fontWeight: fontWeights[600], fontSize: fontSizes.xl, color: 'text.heading' }}>
        Create Quotation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select from available clients or create a new client
        </DialogContentText>
        <Stack direction={"column"} spacing={2} sx={{ mt: 3 }}>
          <AutoCompleteCreateInput
            name={'name'}
            error={error?.name}
            required={true}
            disabled={mCreateQuotation.isLoading}
            label={"Client Name"}
            value={name}
            setValue={setName}
            options={clientNames}
            onFocus={() => setError(null)}
          />
          <AutoCompleteCreateInput
            name={'company'}
            error={error?.company}
            required={true}
            disabled={mCreateQuotation.isLoading}
            label={"Company Name"}
            value={company}
            setValue={setCompany}
            options={companyNames}
            onFocus={() => setError(null)}
          />
          <AutoCompleteCreateInput
            name={'email'}
            error={error?.email}
            required={true}
            disabled={mCreateQuotation.isLoading}
            label={"Email"}
            value={email}
            setValue={setEmail}
            onFocus={() => setError(null)}
          />
          <AutoCompleteCreateInput
            name={'phone'}
            error={error?.phone}
            required={true}
            disabled={mCreateQuotation.isLoading}
            label={"Phone"}
            value={phone}
            setValue={setPhone}
            onFocus={() => setError(null)}
          />
          <AutoCompleteCreateInput
            name={'country'}
            error={error?.country}
            required={true}
            disabled={mCreateQuotation.isLoading}
            label={"Country"}
            value={country}
            setValue={setCountry}
            onFocus={() => setError(null)}
          />
          <Dropdown
            disabled={organizationNames?.length === 0}
            error={error?.organization}
            size="medium"
            variant="outlined"
            label={"Your Organization"}
            labelKey="name"
            valueKey="id"
            options={organizationNames || []}
            value={organizationId}
            onChange={onOrganizationChange}
            showNone={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <LoadingButton
          variant="text"
          onClick={onNext}
          loading={mCreateQuotation.isLoading}
          spinnerSize={18}
        >
          Next
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
