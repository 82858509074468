
export const FreightTypes = {
    'TO_PAY': 0,
    'PAID': 1
}

export const FreightOptionLabels = {
    0: 'To Pay',
    1: 'Paid'
}

