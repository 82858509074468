const Endpoints = {
  login: `/auth/login`,
  logout: `/auth/logout`,
  refreshToken: '/auth/refresh-token',
  clients: `/clients`,
  quotations: "/quotations",
  products: "/products",
  currencies: "/currencies",
  quotationHistory: "/quotation-history",
  users: "/users",
  salesUsers: "/users/sales",
  organizations: "/organizations",
  proformaInvoices: "/proforma-invoices",
  reminders: "/reminders",
  purchaseOrders: "/purchase-orders",
  manufacturers: "/manufacturers",
  analytics: '/analytics'
};

export default Endpoints;
