import {
  Box,
  Fab,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";

const CardList = ({
  headers = [],
  rows = [],
  onAction = async () => { },
  loading = false,
  checkboxes = false,
  showActions = true,
  reset = false,
  Component = null,
  pagination = true,
  dialogProps = {},
  currentPage = 0,
  currentRowsPerPage = 10,
  routingPagination,
  totalRows = 0,
  onEdit,
  addButton = false
}) => {

  const [page, setPage] = React.useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(currentRowsPerPage);

  React.useEffect(() => {
    _reset();
    // eslint-disable-next-line
  }, [showActions, reset]);

  React.useEffect(() => {
    if (dialogProps?.open) {
      onAddNewRow();
    }
    // eslint-disable-next-line
  }, [dialogProps?.open])

  React.useEffect(() => {
    if (routingPagination) {
      setPage(currentPage);
      setRowsPerPage(currentRowsPerPage);
    }
  }, [currentPage, currentRowsPerPage, routingPagination])

  React.useEffect(() => {
    setPage(0)
    if (!pagination) {
      setRowsPerPage(30);
    } else {
      setRowsPerPage(currentRowsPerPage);
    }
  }, [pagination, currentRowsPerPage])

  const handleChangePage = (event, newPage) => {
    const _newPage = newPage - 1;
    if (!routingPagination) {
      setPage(_newPage);
    }
    window.scrollTo(0, 0);

    // setPage(newPage - 1);
    onAction(
      ActionTypes.CHANGE_PAGE,
      null,
      -1,
      null,
      undefined,
      _newPage,
      rowsPerPage
    );
  };

  const onCheckChange = (event, row, index) => {
    onAction(
      ActionTypes.CHECK,
      row,
      rowsPerPage * page + index,
      null,
      undefined,
      event.target.checked
    );
  };

  const _onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.EDIT) {
      if (onEdit) {
        onEdit(row, rowsPerPage * page + index);
        onAction(ActionTypes.EDIT, row, rowsPerPage * page + index);
        return;
      }
    }

    await onAction(actionType, row, rowsPerPage * page + index);
  };

  const _reset = () => {

    setPage(0);
    if (dialogProps?.onClose) {
      dialogProps.onClose();
    }
  };

  const onAddNewRow = () => {
    const keys = headers
      .map((h) => (
        {
          key: h.key,
          defaultValue: h?.defaultValue,
        }))
      .filter((h) => h.key !== "actions" && h.key !== "sr");
    const _dummyRow = keys.reduce((acc, k) => {
      if (k?.defaultValue) {
        acc[k.key] = k.defaultValue;
      } else {
        acc[k.key] = '';
      }
      return acc;
    }, {});
    onAction(ActionTypes.ADD_NEW, _dummyRow, 0);
  };

  const dataRows = totalRows ? rows : rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const rowsRange = `${page * rowsPerPage + 1}-${Math.min(page * rowsPerPage + rowsPerPage, totalRows)}`

  return (
    <>
      <Box>
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            mb: 5,
          }}
        >{
            routingPagination &&
            <Typography variant="subtitle2" mb={1} color={'text.secondary'}>{`Showing ${rowsRange} of ${totalRows}`}</Typography>
          }
          {loading && <LinearProgress />}
          {Component && (
            <Stack sx={{ pb: 2 }}>
              {dataRows
                .map((row, i) => (
                  <Component
                    index={(page * rowsPerPage) + i + 1}
                    row={row}
                    key={`${row.id}${i}`}
                    headers={headers}
                    onAction={(actionType) => _onAction(actionType, row, i)}
                    checkboxes={checkboxes}
                    onCheckChange={(event) => onCheckChange(event, row, i)}
                    showActions={showActions}
                  />
                ))}
              {dataRows.length === 0 && <NoRecordsMessage />}
            </Stack>
          )}
          {pagination && (
            <Stack direction={"row"} justifyContent="center">
              <Pagination
                page={page + 1}
                siblingCount={0}
                color="primary"
                count={Math.ceil(totalRows / (rowsPerPage || 10))}
                size="medium"
                onChange={handleChangePage}
              />
            </Stack>
          )}
        </Paper>
      </Box>

      {addButton &&
        <Box
          className="fab-container"
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            onClick={onAddNewRow}
          >
            <Icons.Plus />
          </Fab>
        </Box>
      }
    </>
  );
};

export default CardList;

const NoRecordsMessage = () => {
  return (
    <Typography
      variant="body1"
      color={"#999"}
      sx={{ textAlign: "center", p: 4 }}
    >
      No records found
    </Typography>
  );
};
