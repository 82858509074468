import { Button, Stack, Typography } from "@mui/material";

import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";

import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import UserActions from "actions/user";
import AddUserDialog from "components/AddUserDialog";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import { UserStatus, UserStatusNames } from "utils/user_status";
import { useNavigate } from "react-router-dom";
import ManageUserDialog from "components/ManageUserDialog";
import UserContext from "context/UserContext";
import ChangePasswordDialog from "components/ChangePasswordDialog";
import { UserHelper } from "helpers/user";

const headers = [
  {
    key: "sr",
    label: "SR. NO",
    width: 20,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "username",
    label: "USERNAME",
    width: 150,
    align: "left",
  },
  {
    key: "role",
    label: "ROLE",
    width: 100,
    align: "center",
  },
  {
    key: "status",
    label: "STATUS",
    width: 100,
    align: "center",
    transform: (row) => {
      return row.status !== undefined ? UserStatusNames[row.status] : "Unknown";
    }
  },
  {
    key: "created_date",
    label: "CREATED DATE",
    width: 100,
    align: "center",
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 100,
    align: "center",
    items: {
      View: Icons.ChevronRight,
    },
  },
];

export default function Users() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [manageUserDialogOpen, setManageUserDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const { isLoading, refetch: fetchUsers } = useQuery({
    queryKey: ["get-users", user?.username],
    queryFn: UserActions.getUsers,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setUsers(data);
    },
  });

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);


  const mUpdateUserStatus = useMutation(UserActions.updateUserStatus, {
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("User status updated successfully", {
        variant: "success",
      });
      fetchUsers();
    },
  });

  const onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.ACTIVE) {
      mUpdateUserStatus.mutate({
        username: row.username,
        status: UserStatus.ACTIVE,
      });
    }
    if (actionType === ActionTypes.INACTIVE) {
      mUpdateUserStatus.mutate({
        username: row.username,
        status: UserStatus.INACTIVE,
      });
    }
    if (actionType === ActionTypes.VIEW) {
      navigate(`/master/users/${row.username}/quotations?page=1&limit=10`)
    }

    if (actionType === ActionTypes.MANAGE) {
      setManageUserDialogOpen(true);
      setSelectedUser(row);
    }

    if (actionType === ActionTypes.CHANGE_PASSWORD) {
      setChangePasswordDialogOpen(true);
      setSelectedUser(row);
    }
  };

  return (
    <>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography variant="h4" color={"text.heading"} fontWeight="600">
          Users
        </Typography>
        <Button
          variant="outlined"
          startIcon={<Icons.Add />}
          component="label"
          onClick={() => setAddUserDialogOpen(true)}
        >
          ADD USER
        </Button>
      </Stack>

      <DataTable headers={headers} rows={users} onAction={onAction} rowStyle={UserHelper.setCustomRowStyle} />

      <AddUserDialog
        open={addUserDialogOpen}
        setOpen={setAddUserDialogOpen}
        onSuccess={fetchUsers}
      ></AddUserDialog>

      <ManageUserDialog
        open={manageUserDialogOpen}
        onClose={() => {
          setManageUserDialogOpen(false);
          setSelectedUser(null)
        }}
        data={{
          selectedUser: selectedUser,
        }}
      />

      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        onClose={() => {
          setChangePasswordDialogOpen(false);
          setSelectedUser(null)
        }}
        data={{
          selectedUser: selectedUser,
        }}
      />
    </>
  );
}
