
const DrawerUtils = {
  filterMasterItems: (items = [], user, mobile) => {
    const role = user.role;
    const accountType = user['account_type'];

    if (!role) return [];
    return items
      .filter((item) => {
        if (mobile === undefined && item?.mobile === undefined) return true;

        if (typeof mobile === "boolean" && typeof item?.mobile === "boolean")
          return mobile === item.mobile;
        return true;
      })
      .filter(item => item.accountTypes.includes(accountType))
      .filter((r) => r.accessRoles && r.accessRoles.includes(role))
  },
};

export default DrawerUtils;
