import MathUtils from "utils/math";
import { FreightOptionLabels } from "utils/proforma_invoice";

const precision = 3;

const precisionFloat = (value, decimals) =>
  Number(parseFloat(value).toFixed(decimals ? decimals : precision));

const percentCalculation = (value, percentage) => {
  let _value = value;
  _value = parseFloat(
    _value * (1 - (-1 * parseFloat(percentage || 0)) / 100)
  ).toFixed(precision);
  return Number(_value);
};

const calculatePricePerUnitCurrency = (v, currency) => {
  return precisionFloat(
    percentCalculation(v["price_per_unit"], v["percentage"]) /
    (currency?.conversion_rate || 1)
  );
};

const QuotationProductTransform = {
  transformPacking: (v) => {
    return String(v["packing"])
      .split("x")
      .map((x) => x.trim())
      .join(" x ");
  },
  transformMOQ: (v) => Number(v.moq).toLocaleString(),
  transformPricePerPack: (v) => {
    const ppp = parseFloat(
      MathUtils.evaluateMul(v["packing"]) * v["price_per_unit"]
    );
    return percentCalculation(ppp, v["percentage"]);
  },
  transformPricePerPackCurrency: (v, currency) => {
    const pricePerUnitCurrency = calculatePricePerUnitCurrency(v, currency);
    const pricePerPack = precisionFloat(
      MathUtils.evaluateMul(v['packing']) * pricePerUnitCurrency,
    );
    return pricePerPack + (currency?.symbol || '');
  },
  transformPercentage: (v) =>
    v["percentage"]
      ? Number.isSafeInteger(v["percentage"])
        ? parseInt(v["percentage"], 10) + "%"
        : parseFloat(v["percentage"]).toFixed(2) + "%"
      : "-",
  transformPercentageTooltip: (v) => {
    if (v["prev_percentage"] !== undefined) {
      return (
        "Previous: " +
        Number(parseFloat(v["prev_percentage"]).toFixed(precision)) +
        "%"
      );
    }
    return null;
  },
  transformPricePerUnit: (v) =>
    percentCalculation(v["price_per_unit"], v["percentage"]),
  transformPricePerUnitTooltip: (v) => {
    if (v["prev_price_per_unit"]) {
      const prevValue = percentCalculation(
        v["prev_price_per_unit"],
        v["prev_percentage"]
      );
      return `Previous: ${prevValue} @ ${v["prev_percentage"]}%`;
    }
    return null;
  },
  transformPricePerUnitCurrency: (v, currency) => {
    const pricePerUnitCurrency = calculatePricePerUnitCurrency(v, currency);
    return pricePerUnitCurrency + (currency?.symbol || "");
  },
  transformTotal: (v) => {
    let total = parseFloat(MathUtils.evaluateMul(v.moq) * v["price_per_unit"]);
    total = percentCalculation(total, v["percentage"]);
    v.total = total;
    return total;
  },
  transformTotalCurrency: (v, currency) => {
    const pricePerUnitCurrency = calculatePricePerUnitCurrency(v, currency);
    return (
      precisionFloat(pricePerUnitCurrency * v['moq']) + (currency?.symbol || '')
    );
  },
  transformFreight: (v) => {
    return MathUtils.isNonEmpty(v['freight']) ? FreightOptionLabels[v['freight']] : '-';
  }
};

export default QuotationProductTransform;
