import { Box, Grid, Stack, Typography } from "@mui/material";
import Dropdown from "components/Dropdown";

export const OrganizationDetails = ({
    disabled, organization, organizations, onOrganizationChange, listenForChange,
}) => {
    const _onOrganizationChange = (e) => {
        onOrganizationChange(e.target.value);
        listenForChange();
    };

    return (
        <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <Stack direction={"row"}>
                        <Typography variant="body2" component={"span"} fontWeight="600">
                            Organization
                            <span style={{ color: "red" }}>*</span>:
                        </Typography>

                        <Dropdown
                            sx={{
                                minWidth: 60,
                            }}
                            disabled={disabled}
                            labelKey="name"
                            valueKey="id"
                            onChange={_onOrganizationChange}
                            value={organization}
                            options={organizations || []}
                            showNone={false}
                        ></Dropdown>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};
