import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Icons from 'utils/icons';

export default function SubHeader({ title }) {
    const location = useLocation();
    const navigate = useNavigate()
    return (

        <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems={"center"}
            spacing={1}
        >
            {/* Check if history has any routes */}
            {location.key !== "default" && (
                <IconButton color="primary" size="small" onClick={() => navigate(-1)}>
                    <Icons.Back />
                </IconButton>
            )}
            <Typography variant="h5" color={"text.primary"} fontWeight="600">
                {title}
            </Typography>
        </Stack>

    )
}
