import { Stack, Typography } from "@mui/material";
import React from "react";

export default function NotFound() {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      sx={{
        mt: 10,
      }}
    >
      <Typography variant="h5" color={"grey"}>
        Page not found!
      </Typography>
    </Stack>
  );
}
