import { DialogContent, IconButton, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import QuotationActions from "actions/quotation";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fontWeights } from "styles/fonts";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import DataTable from "./DataTable";

const headers = [
  {
    key: "sr",
    label: "SR. NO",
    width: 60,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "created_at",
    label: "DATE",
    width: 100,
    align: "center",
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 100,
    align: "center",
    items: {
      View: Icons.Preview,
    },
  },
];

export function QuotationHistoryDialog({ quotationId, open = true, onClose }) {
  const snackbar = useSnackbar();
  const {
    data: history,
    isLoading,
    refetch: fetchHistory,
  } = useQuery({
    queryKey: ["get-quotation-history", quotationId],
    queryFn: QuotationActions.getQuotationHistory,
    enabled: false,
  });

  useEffect(() => {
    if (quotationId && open) {
      fetchHistory();
    }
    // eslint-disable-next-line
  }, [quotationId, open]);

  const [historyId, setHistoryId] = useState(undefined);

  const { data: documentUrl } = useQuery({
    queryKey: ["get-quotation-history-doc", historyId],
    queryFn: QuotationActions.getQuotationHistoryDocUrl,
    enabled: !!historyId,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        error.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    },
  });

  useEffect(() => {
    if (documentUrl) {
      window.open(documentUrl, "_blank");
      setHistoryId(null);
    }
  }, [documentUrl]);

  const onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.VIEW) {
      setHistoryId(row.id);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        <Stack direction={"row"} justifyContent="space-between">
          Quotation History
          <IconButton onClick={onClose} size="small">
            <Icons.Cancel fontSize="small"></Icons.Cancel>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DataTable
          headers={headers}
          rows={history}
          loading={isLoading}
          onAction={onAction}
        />
      </DialogContent>
    </Dialog>
  );
}
