const ClientStatus = {
  PENDING: 0,
  REJECTED: 1,
  ACCEPTED: 2,
};

const ClientStatusNames = {
  [ClientStatus.PENDING]: "Pending",
  [ClientStatus.ACCEPTED]: "Order Received",
  [ClientStatus.REJECTED]: "Rejected",
};

export { ClientStatusNames, ClientStatus };
