import {
  Badge,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import { fontSizes, fontWeights } from "styles/fonts";
import { ClientStatusNames } from "utils/client_status";
import Icons from "utils/icons";
import { QuotationStateNames } from "utils/quotation_states";

const StyledListItem = styled(ListItem)(({ theme }) => ({}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
  paddingRight: 20,
}));

const StyledListItemText = styled(ListItemText)(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : "inherit",
  "& span": {
    fontWeight: selected ? fontWeights[600] : fontWeights[400],
    fontSize: fontSizes.sm,
  },
}));

const FILTER_OPTIONS = ["Client Status", "Quotation State"];

const CLIENT_STATUS_OPTIONS = Object.keys(ClientStatusNames).map((key) => ({
  value: key,
  label: ClientStatusNames[key],
}));

const QUOTATION_STATE_OPTIONS = Object.keys(QuotationStateNames).map((key) => ({
  value: key,
  label: QuotationStateNames[key],
}));

export default function QuotationFiltersDrawer({
  appliedFilters = [],
  open = false,
  onClose,
  mobile,
  onApply,
}) {
  const [selectedFilter, setSelectedFilter] = useState(FILTER_OPTIONS[0]);

  const [clientStatusFilter, setClientStatusFilter] = useState("");
  const [quotationStateFilter, setQuotationStateFilter] = useState("");

  useEffect(() => {
    if (open) {
      const clientStatusFilter = appliedFilters.find(
        (filter) => filter.field === "client_status"
      );
      if (clientStatusFilter) {
        setClientStatusFilter(parseInt(clientStatusFilter.value, 10));
      } else {
        setClientStatusFilter("");
      }

      const quotationStateFilter = appliedFilters.find(
        (filter) => filter.field === "state"
      );
      if (quotationStateFilter) {
        setQuotationStateFilter(parseInt(quotationStateFilter.value, 10));
      } else {
        setQuotationStateFilter("");
      }

      if (appliedFilters.length === 0) {
        resetFilters();
      }

    }
  }, [appliedFilters, open]);

  const resetFilters = () => {
    setClientStatusFilter("");
    setQuotationStateFilter("");
  };

  const onClear = () => {
    switch (selectedFilter) {
      case "Client Status":
        setClientStatusFilter("");
        break;
      case "Quotation State":
        setQuotationStateFilter("");
        break;
      default:
    }
  };

  const onClearAll = () => {
    setClientStatusFilter("");
    setQuotationStateFilter("");
  };

  const _onApply = () => {
    const filters = [];

    if (clientStatusFilter !== "") {
      filters.push({
        field: "client_status",
        value: clientStatusFilter,
      });
    }

    if (quotationStateFilter !== "") {
      filters.push({
        field: "state",
        value: quotationStateFilter,
      });
    }

    onApply(filters);
  };

  const renderBadge = (text) => {
    let shouldRender = false;
    if (text === "Client Status" && clientStatusFilter !== "") {
      shouldRender = true;
    }
    if (text === "Quotation State" && quotationStateFilter !== "") {
      shouldRender = true;
    }

    if (shouldRender) {
      return (
        <Badge
          badgeContent={4}
          color="primary"
          variant="dot"
          sx={{
            marginRight: -1,
          }}
        ></Badge>
      );
    }
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 1001,
      }}
      PaperProps={{
        sx: {
          width: mobile ? "100%" : 500,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Stack
          direction={"row"}
          alignItems="flex-start"
          justifyContent={"space-between"}
        >
          <Typography pl={2} pb={2} fontWeight={600} fontSize={24}>
            Filters
          </Typography>
          <IconButton onClick={onClose}>
            <Icons.Close color="insignificant" />
          </IconButton>
        </Stack>

        <Divider />

        <Grid container>
          <Grid
            item
            xs={5}
            sx={{
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderStyle: "solid",
              borderWidth: 0,
              borderRightWidth: "thin",
            }}
          >
            <List>
              {FILTER_OPTIONS.map((text, index) => (
                <StyledListItem key={text} disablePadding>
                  <StyledListItemButton
                    selected={selectedFilter === text}
                    onClick={() => setSelectedFilter(text)}
                  >
                    <StyledListItemText
                      primary={text}
                      selected={selectedFilter === text}
                    />
                    {renderBadge(text)}
                  </StyledListItemButton>
                </StyledListItem>
              ))}
            </List>
            <Divider />
          </Grid>
          <Grid item xs={7}>
            {selectedFilter === "Client Status" && (
              <RadioFilters
                options={CLIENT_STATUS_OPTIONS}
                value={clientStatusFilter}
                setFilter={setClientStatusFilter}
              />
            )}
            {selectedFilter === "Quotation State" && (
              <RadioFilters
                options={QUOTATION_STATE_OPTIONS}
                value={quotationStateFilter}
                setFilter={setQuotationStateFilter}
              />
            )}
            <Divider />
          </Grid>
        </Grid>
        <div className="quotation-filters">
          <Divider />
          <Stack
            p={1}
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent={"space-evenly"}
          >
            <Button
              title="Clear"
              variant="text"
              color="insignificant"
              onClick={onClearAll}
            >
              Clear All
            </Button>
            <Button
              title="Clear"
              variant="text"
              color="insignificant"
              onClick={onClear}
            >
              Clear
            </Button>
            <Button title="Clear" variant="text" onClick={_onApply}>
              Apply
            </Button>
          </Stack>
        </div>
      </Box>
    </Drawer>
  );
}

const RadioFilters = ({ options = [], value = "", setFilter }) => {
  return (
    <Box py={2} px={3}>
      <RadioGroup
        value={value}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: "subtitle2",
                },
              }}
              key={option.value}
              value={option.value}
              control={<Radio size="small" name={option.label} />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
};
