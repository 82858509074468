import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useEffect, useState } from "react";

import { DateRangePicker } from 'react-date-range';

export default function DateRangePickerDialog({
  open,
  onSelect,
  onClose,
  defaultRange = []
}) {

  const [state, setState] = useState([]);
  const theme = useTheme()

  useEffect(() => {
    if (defaultRange.length > 0) {
      setState(defaultRange)
    }
  }, [defaultRange, defaultRange?.length])

  if (!state.length > 0) return null;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      style: {
        maxWidth: "100%"
      }
    }}>
      <DialogContent>
        <DateRangePicker
          onChange={item => setState([item?.range1])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          minDate={new Date(2023, 1, 1)}
          maxDate={new Date()}
          rangeColors={[theme.palette.primary.main, theme.palette.secondary.main]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={() => onSelect(state)} autoFocus>
          Select
        </Button>

      </DialogActions>
    </Dialog>
  );
}
