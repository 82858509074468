import TextInput from "components/TextInput";


export const TinyInput = (props) => {
    return (
        <TextInput
            inputProps={{
                style: {
                    padding: "0px 4px",
                    width: 30,
                    textAlign: "center",
                },
            }}
            {...props}
            size={"small"}
        ></TextInput>
    );
};
