import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { CommonActions } from "actions/common";
import UserContext from "context/UserContext";
import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { fontWeights } from "styles/fonts";
import { DRAWER_ITEMS, DRAWER_WIDTH } from "utils/constants";
import DrawerUtils from "utils/drawer";
import Icons from "utils/icons";
import RouterUtils from "utils/router";
import { Header } from "./Header";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "4px 6px",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 50,
  paddingLeft: 14,
  paddingRight: 14,
}));

const StyledListItemText = styled(ListItemText)(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : "inherit",
  "& span": {
    fontWeight: selected ? fontWeights[600] : fontWeights[400],
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : "inherit",
  // minWidth: 40,
}));

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function NavigationDrawer({ render }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = React.useContext(UserContext);

  const [drawerExpanded, setDrawerExpanded] = React.useState(true);
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState("");

  React.useEffect(() => {
    if (!user) return;
    const _selectedItem = RouterUtils.getRoutesForUser(user).find((r) =>
      location.pathname.indexOf(r.path) === 0
    );

    if (_selectedItem) {
      setSelectedItem(_selectedItem.name);
    } else {
      handleSelectionChange(undefined);
    }
    // eslint-disable-next-line
  }, [location.pathname, user?.role]);

  const handleSelectionChange = (item) => {
    CommonActions.resetStoredData()

    if (mobileDrawerOpen) {
      setTimeout(() => setMobileDrawerOpen(false), 200);
    }

    setSelectedItem(item);
    if (item) {
      const selectedRoute = RouterUtils.getRoutesForUser(user).find(
        (route) => route.name === item
      );
      if (selectedRoute) {
        const routePath = RouterUtils.getRoutePath(selectedRoute);
        navigate(routePath);
      } else {
        navigate("/404");
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        sx={{
          display: { xs: "none", sm: "block" },
        }}
        onDrawerOpen={() => setDrawerExpanded(true)}
        open={drawerExpanded}
        title="Dashboard"
        onTitleClick={() => navigate("/")}
      />

      <Header
        sx={{
          display: { xs: "block", sm: "none" },
        }}
        onDrawerOpen={() => setMobileDrawerOpen(true)}
        open={mobileDrawerOpen}
        title="Dashboard"
        onTitleClick={() => navigate("/")}
        mobile={true}
      />

      <Drawer
        variant="permanent"
        open={drawerExpanded}
        sx={{
          display: { xs: "none", sm: "block" },
          zIndex: 999,
        }}
      >
        <DrawerItems
          user={user}
          drawerOpen={drawerExpanded}
          selectedItem={selectedItem}
          setSelectedItem={handleSelectionChange}
          onDrawerClose={() => setDrawerExpanded(false)}
        />
      </Drawer>

      <MuiDrawer
        variant="temporary"
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
          zIndex: 1001,
        }}
      >
        <DrawerItems
          mobile={true}
          user={user}
          drawerOpen={true}
          selectedItem={selectedItem}
          setSelectedItem={handleSelectionChange}
          onDrawerClose={() => setMobileDrawerOpen(false)}
        />
      </MuiDrawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <DrawerHeader />
        {!!render && render({ mobile: false })}
      </Box>

      <Box
        component="main"
        sx={{
          px: 1,
          pt: 3,
          flexGrow: 1,
          width: "100%",
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <DrawerHeader />
        {!!render && render({ mobile: true })}
      </Box>
    </Box>
  );
}

const DrawerItems = ({
  mobile,
  user,
  drawerOpen,
  selectedItem,
  setSelectedItem,
  onDrawerClose,
}) => {

  return (
    <>
      <DrawerHeader>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          width={"100%"}
        >
          {drawerOpen && (
            <Stack direction={"row"} alignItems="center" pl={1}>
              <Avatar alt={user.username} sx={{ width: 24, height: 24 }} />
              <Typography
                variant="subtitle1"
                // color={"primary"}
                fontWeight={"600"}
                pl={1}
              >
                {user?.username || ""}
              </Typography>
            </Stack>
          )}
          <IconButton onClick={onDrawerClose}>
            <Icons.Back />
          </IconButton>
        </Stack>
      </DrawerHeader>
      <Divider />
      <List>
        {DrawerUtils.filterMasterItems(DRAWER_ITEMS, user, mobile).map(
          (item, index) => (
            <DrawerItem
              key={index}
              mobile={mobile}
              user={user}
              drawerOpen={drawerOpen}
              item={item}
              selected={selectedItem === item.label}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          )
        )}
      </List>
    </>
  );
};

const DrawerItem = ({
  drawerOpen,
  mobile,
  user,
  item,
  selected,
  selectedItem,
  setSelectedItem,
  sx = {},
}) => {
  const Icon = item.icon;

  const [expanded, setExpanded] = React.useState(false);

  // Expand if child item is selected
  React.useEffect(() => {
    if (item?.children) {
      const selectedChild = item.children.find((c) => c.label === selectedItem);
      if (selectedChild) {
        setExpanded(true);
      }
    }
  }, [item, selectedItem]);

  const onDrawerItemSelect = () => {
    if (item?.children) {
      setExpanded(!expanded);
    } else {
      setSelectedItem(item.label);
    }
  };
  return (
    <>
      <Tooltip title={!drawerOpen ? item.label : ""} placement="right">
        <StyledListItem key={item.label} disablePadding sx={sx}>
          <StyledListItemButton
            selected={selected}
            onClick={onDrawerItemSelect}
          >
            <StyledListItemIcon selected={selected}>
              <Stack direction={"column"} spacing={1}>
                <Icon />
                {item?.children &&
                  !drawerOpen &&
                  (expanded ? <Icons.Collapse /> : <Icons.Expand />)}
              </Stack>
            </StyledListItemIcon>
            <StyledListItemText primary={item.label} selected={selected} />
            {item?.children && (
              <StyledListItemIcon
                selected={selectedItem === item.label}
                sx={{ justifyContent: "flex-end" }}
              >
                {expanded ? <Icons.Collapse /> : <Icons.Expand />}
              </StyledListItemIcon>
            )}
          </StyledListItemButton>
        </StyledListItem>
      </Tooltip>

      {item?.children &&
        expanded &&
        DrawerUtils.filterMasterItems(item.children, user, mobile).map(
          (childItem) => (
            <DrawerItem
              drawerOpen={drawerOpen}
              key={childItem.label}
              item={childItem}
              user={user}
              selected={selectedItem === childItem.label}
              selectedItem={childItem.label}
              setSelectedItem={setSelectedItem}
              {...(drawerOpen && { sx: { pl: 4 } })}
            />
          )
        )}
    </>
  );
};

export default NavigationDrawer;
