import { Avatar, Chip, useTheme } from '@mui/material';

export default function UserChip({ username, onDelete }) {
    const theme = useTheme();
    if (!username) return null;

    const userInitial = username.length > 0 ? username[0].toUpperCase() : "A";
    return (
        <Chip avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }} style={{ color: "white" }}>{userInitial}</Avatar >} label={username} onDelete={onDelete} />
    )
}
