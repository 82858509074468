import {
  Card as MuiCard,
  CardActionArea,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import UserContext from "context/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AccountType from "utils/account_type";
import DrawerUtils from "utils/drawer";
import Icons from "utils/icons";
import Roles from "utils/roles";

const cards = [
  {
    label: "Quotations",
    description: "Create and manage quotations",
    Icon: Icons.Quotation,
    route: "/quotations?page=1&limit=10",
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Purchase Orders",
    description: "Create and manage purchase orders",
    Icon: Icons.PurchaseOrder,
    route: "/purchase_orders?page=1&limit=10",
    accessRoles: [Roles.ADMIN, Roles.PURCHASING, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR]
  },
  {
    label: "Products",
    description: "Create and manage products",
    Icon: Icons.Products,
    route: "/master/products",
    mobile: false,
    accessRoles: [Roles.ADMIN, Roles.PURCHASING, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Clients",
    description: "View all engaged clients",
    Icon: Icons.Clients,
    route: "/master/clients",
    mobile: false,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Users",
    description: "Create and manage users within your organization",
    Icon: Icons.Users,
    route: "/master/users",
    mobile: false,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Analytics",
    description: "Analyze product and user metrics",
    Icon: Icons.Analytics,
    route: "/analytics",
    mobile: false,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
];

export default function Home({ mobile }) {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  return (
    <Grid container spacing={3} mt={3}>
      {DrawerUtils.filterMasterItems(cards, user, mobile).map((card) => (
        <Grid key={card.label} item xs={12} sm={4} lg={3}>
          <Card
            title={card.label}
            description={card.description}
            Icon={card.Icon}
            onClick={() => navigate(card.route)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function Card({ title, description, Icon, onClick }) {
  const theme = useTheme();
  return (
    <MuiCard
      onClick={onClick}
      elevation={0}
      sx={{
        border: `3px solid ${theme.palette.primary.main}22}`,
      }}
    >
      <CardActionArea
        style={{
          height: 150,
        }}
      >
        <Stack
          direction={"row"}
          spacing={2}
          p={3}
          alignItems="center"
          justifyContent={"center"}
          sx={{ opacity: 0.8, pr: 5 }}
        >
          <Icon color="primary" sx={{ fontSize: 50 }} />
          <Stack>
            <Typography fontSize={22} fontWeight={300}>
              {title}
            </Typography>
            <Typography fontSize={12} fontWeight={300} color="#555">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </MuiCard>
  );
}
