import api from "network/api";
import Endpoints from "./endpoints";


const createReminder = async ({
  quotation_id,
  client_name,
  reminder_days
}) => {
  if (!reminder_days || !quotation_id) {
    throw new Error(`Oops! Couldn't set reminder. Try again later.`);
  }
  const res = await api.post(Endpoints.reminders, {
    quotation_id,
    client_name,
    reminder_days
  });
  return res.data;
};

const getReminder = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  if (!quotationId) {
    return
  }
  const res = await api.get(`${Endpoints.reminders}/${quotationId}`)
  return res.data
}

const getReminders = async () => {

  const res = await api.get(Endpoints.reminders)
  const data = res.data;
  return {
    data: data?.data || [],
    total: data?.total || 0
  }
}

const discardReminder = async ({ quotation_id }) => {
  if (!quotation_id) {
    throw new Error('Something went wrong!')
  }
  const res = await api.delete(`${Endpoints.reminders}/${quotation_id}`);
  return res.data;
}


const ReminderActions = {
  createReminder,
  getReminder,
  getReminders,
  discardReminder
};
export default ReminderActions;
