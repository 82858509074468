const Roles = {
  ADMIN: "admin",
  MEMBER: "member",
  PURCHASING: "purchasing",
  MEMBER_PLUS: 'member+',
};


export const RoleNames = {
  [Roles.ADMIN]: "Admin",
  [Roles.MEMBER]: "Member",
  [Roles.PURCHASING]: "Purchasing",
  [Roles.MEMBER_PLUS]: "Member+",
}

export default Roles;
