import { QuotationStates } from "utils/quotation_states";

export const QuotationsHelper = {
    getStoredFilters: () => {
        const _filters = sessionStorage.getItem("filters") || "[]";

        const jsonFilters = JSON.parse(_filters) || [];

        if (jsonFilters.length === 0) {
            jsonFilters.push({
                field: "state",
                value: QuotationStates.PENDING_APPROVAL,
            })
        }

        return jsonFilters;
    },
    addUserFiler: (username, filters) => {
        filters.push({ field: 'managed_by', value: username })
    },
    addClientFiler: (clientId, filters) => {
        filters.push({ field: 'client_id', value: clientId })
    },
    clearFilters: () => {
        sessionStorage.removeItem("filters");
    },
    userFilterExists: (filters) => {
        return filters.some((filter) => filter.field === "managed_by");
    },
    clientFilterExists: (filters) => {
        return filters.some((filter) => filter.field === "client_id");
    },
    removeFilter: (filters, filter) => {
        const index = filters.findIndex((f) => f.field === filter.field && parseInt(f.value, 10) === parseInt(filter.value, 10))
        if (index > -1) {
            filters.splice(index, 1);
        }
    },
    markFilterActive: (filters, filter) => {
        filters.length = 0;
        filters.push(filter);
    },
    setCustomRowStyle: (row) => {
        let style = {}
        if (row?.state === QuotationStates.APPROVED) {
            style = { ...style, backgroundColor: 'primary.lighter' }
        }
        style = {
            ...style,
            '&:hover': {
                backgroundColor: 'hover.light',
                color: 'black',
            },
            cursor: 'pointer'
        }

        return style
    }

}
