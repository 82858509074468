import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ErrorFallback({ error }) {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      sx={{
        mt: 10,
      }}
    >
      <Typography variant="h5" color={"grey"}>
        Oops! Something went wrong!
      </Typography>
    </Stack>
  );
}
