import { TextField } from "@mui/material";
import React from "react";
import { fonts } from "styles/fonts";

export default function TextInput(props) {
  return (
    <TextField
      InputLabelProps={{
        style: {
          ...fonts.regular,
        },
      }}
      InputProps={{
        style: {
          ...fonts.regular,
        },
      }}
      {...props}
    />
  );
}
