export const ActionTypes = {
  EDIT: "Edit",
  DELETE: "Delete",
  SAVE: "Save",
  VIEW: "View",
  ROWS_PER_PAGE: "Rows_Per_Page",
  CHANGE_PAGE: "Change_Page",
  CHECK_ALL: "Check_All",
  CHECK: "Check",
  NEW_ENTRY: "New_Entry",
  ACCEPTED: "Order Received",
  REJECTED: "Rejected",
  APPROVAL: "Send for Approval",
  APPROVE: "Mark Approved",
  SORT_ASC: "Sort Ascending",
  SORT_DESC: "Sort Descending",
  ACTIVE: "Mark Active",
  INACTIVE: "Mark Inactive",
  DOWNLOAD: "Download",
  MANAGE: "Manage User",
  DISCARD: "Discard",
  CHANGE_PASSWORD: "Change_Password",
  ADD_NEW: "Add"
};
