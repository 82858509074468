import api from "network/api";
import { RoleNames } from "utils/roles";
import { UserStatus } from "utils/user_status";
import Endpoints from "./endpoints";

const UserActions = {
  getUsers: async ({ queryKey }) => {
    const [, username] = queryKey;
    const res = await api.get(Endpoints.users);
    return res.data.map((user) => {
      const actions = ['View'];
      const menuActions = ["Manage", 'Change_Password']
      if (user?.username !== username) {
        if (user?.status === UserStatus.ACTIVE) menuActions.push("Inactive");
        if (user?.status === UserStatus.INACTIVE) menuActions.push("Active");
      }
      return {
        ...user,
        role: RoleNames[user.role] || "",
        actions: actions,
        menuActions: menuActions
      };
    });
  },
  getSalesUsers: async () => {
    const res = await api.get(Endpoints.salesUsers);
    return (res.data || []).map(user => ({
      value: user.username,
      label: user.username,
    }));
  },
  getUser: async (username) => {
    try {
      const { data: userDetails } = await api.get(`${Endpoints.users}/${username}`);
      return userDetails;
    } catch (error) {
      return null;
    }
  },
  getCurrentUser: async () => {
    try {
      const userRes = await api.get(`${Endpoints.users}/current-user`)
      return userRes.data;
    } catch (error) {
      throw error
    }
  },
  createUser: async (user) => {
    const res = await api.post(Endpoints.users, user);
    return res.data;
  },
  updateUserStatus: async ({ username, status }) => {
    if (!username) {
      throw new Error("Invalid username");
    }
    if (status === undefined) {
      throw new Error("Invalid status");
    }
    const res = await api.patch(`${Endpoints.users}/${username}`, {
      status,
    });
    return res.data;
  },
  handoverUserData: async ({ username, newUsername }) => {
    if (!username) {
      throw new Error("Invalid username");
    }
    if (!newUsername) {
      throw new Error("Invalid new username");
    }
    const res = await api.patch(`${Endpoints.users}/${username}/handover`, {
      newUsername,
    });
    return res.data;
  },
  changeUserPassword: async ({ username, newPassword }) => {
    if (!username) {
      throw new Error("Invalid username");
    }
    if (!newPassword) {
      throw new Error("Invalid new password");
    }
    const res = await api.patch(`${Endpoints.users}/${username}/password`, {
      password: newPassword,
    });
    return res.data;
  }
};

export default UserActions;
