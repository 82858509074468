import { Container } from "@mui/material";
import NavigationDrawer from "components/NavigationDrawer";
import LoadingContext from "context/LoadingContext";
import UserContext from "context/UserContext";
import React, { useContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RouterUtils from "utils/router";
import NotFound from "./NotFound";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      <Container style={{ maxWidth: "100%" }}>
        <BrowserRouter>
          <NavigationDrawer
            role={user?.role}
            render={({ mobile }) => (
              <RouterProvider user={user} mobile={mobile} />
            )}
          ></NavigationDrawer>
        </BrowserRouter>
      </Container>
    </LoadingContext.Provider>
  );
}

const RouterProvider = ({ user, mobile }) => {
  return (
    <Routes>
      {RouterUtils.getRoutesForUser(user, mobile).map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.component mobile={mobile} />}
        ></Route>
      ))}

      <Route path={"*"} element={<NotFound />}></Route>
    </Routes>
  );
};
