import { IconButton, Tooltip } from "@mui/material";
import useLayout from "hooks/useLayout";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import { QuotationStates } from "utils/quotation_states";
import LoadingButton from "./LoadingButton";

export const QuotationStateButtons = ({ quotationDetails, onQuotationStateChange }) => {
    const { isMobile } = useLayout();
    if (quotationDetails?.state !== QuotationStates.PENDING_APPROVAL) return;
    return (
        <>
            {
                isMobile ?
                    <LoadingButton
                        loading={false}
                        variant="text"
                        onClick={() => onQuotationStateChange(QuotationStates.APPROVED)}
                        startIcon={<Icons.Approve />}
                    >
                        {ActionTypes.APPROVE}
                    </LoadingButton>
                    :
                    < IconButton size="small" color="state" onClick={() => onQuotationStateChange(QuotationStates.APPROVED)}>
                        <Tooltip title={ActionTypes.APPROVE}>
                            <Icons.Approve></Icons.Approve>
                        </Tooltip>
                    </IconButton >
            }
        </>
    );
};
