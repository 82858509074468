import { InputTypes } from "utils/input_types";

export const PurchaseOrderDetailsHelper = {
    getProductFormConfig: () => {
        return PurchaseOrderDetailsHelper.getProductFormConfigForDistributor()
    },
    getProductFormConfigForDistributor() {
        let config = [
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Product Name",
                required: true,
                disabled: true,
                defaultValue: '',
                name: 'name',
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Pharmacopoeia",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'pharmacopoeia',
                validation: {
                    expression: /^.{1,100}$/,
                    message: 'Pharmacopoeia should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "GST(%)",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'gst',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'GST should be a valid number'
                },
                type: 'text'
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Brand",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'brand',
                validation: {
                    expression: /^.{1,100}$/,
                    message: 'Brand should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Remark",
                required: true,
                disabled: true,
                defaultValue: '',
                name: 'packing_type',
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Dosage",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'dosage',
                validation: {
                    expression: /^.{1,100}$/,
                    message: 'Dosage should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Packing",
                required: true,
                disabled: true,
                defaultValue: '',
                name: 'packing',
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Rate/Unit(₹)",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'po_price_per_unit',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'Rate/Unit(₹) should be a valid number'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Quantity/Unit",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'po_quantity_per_unit',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'Quantity/Unit should be a valid number'
                },
                type: 'text',
                options: []
            },
        ]
        return config;
    },
}
