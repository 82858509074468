import { IconButton, InputBase, Paper } from "@mui/material";
import { fonts } from "styles/fonts";
import Icons from "utils/icons";

export default function SearchInput({
  placeholder = "Search",
  onSearch,
  value,
  onChange,
  onClear
}) {
  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
      }}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F1F5F8",
      }}
    >
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          ...fonts.regular,
        }}
        fullWidth
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        value={value}
        onChange={onChange}
      />
      {
        value?.length > 0 ?
          <IconButton
            onClick={onClear}
            type="button"
            sx={{ p: "8px" }}
            aria-label="search"
          >
            <Icons.Cancel />
          </IconButton>
          :
          <IconButton
            onClick={onSearch}
            type="button"
            sx={{ p: "8px" }}
            aria-label="search"
          >
            <Icons.Search />
          </IconButton>
      }
    </Paper>
  );
}
