import { Grid, Typography } from "@mui/material";

import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";

import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import CurrencyActions from "actions/currency";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import DynamicFormDialog from "components/DynamicFormDialog";
import { DialogActionTypes } from "utils/dialog_action_types";
import { CurrencyHelper } from "helpers/currency";
import ErrorUtils from "utils/error";

const headers = [
  {
    key: "sr",
    label: "SR. NO",
    width: 60,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "country",
    label: "COUNTRY",
    width: 300,
    align: "left",
    editable: true,
    required: true,
  },
  {
    key: "currency",
    label: "CURRENCY",
    width: 100,
    align: "center",
    editable: true,
    required: true,
  },
  {
    key: "symbol",
    label: "SYMBOL",
    width: 100,
    align: "center",
    editable: true,
    required: true,
  },
  {
    key: "conversion_rate",
    label: "CONVERSION RATE",
    width: 100,
    align: "center",
    editable: true,
    transform: (v) => parseFloat(v["conversion_rate"] || 0).toFixed(2),
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 100,
    align: "center",
    items: {
      Edit: Icons.Edit,
    },
  },
];

export default function Currency() {
  const snackbar = useSnackbar();

  const { setLoading } = useContext(LoadingContext);

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(-1);

  const { isLoading, refetch: fetchCurrencies } = useQuery({
    queryKey: ["get-currencies"],
    queryFn: CurrencyActions.getCurrencies,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setCurrencies(data);
    },
  });

  const mCreateCurrency = useMutation({
    mutationKey: "create-currency",
    mutationFn: CurrencyActions.createCurrency,
    onSuccess: () => {
      snackbar.enqueueSnackbar("Added", {
        variant: "success",
      });
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const mUpdateCurrency = useMutation({
    mutationKey: "update-currency",
    mutationFn: CurrencyActions.updateCurrency,
    onSuccess: () => {
      snackbar.enqueueSnackbar("Updated", {
        variant: "success",
      });
      fetchCurrencies();
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  const onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.ADD_NEW) {
      setSelectedCurrency(row)
      setSelectedCurrencyIndex(index)
    }

    if (actionType === ActionTypes.EDIT) {
      setSelectedCurrency(row)
      setSelectedCurrencyIndex(index)
    }

    if (actionType === ActionTypes.NEW_ENTRY) {
      const saveRes = await mCreateCurrency.mutateAsync(row);
      if (saveRes.success) {
        const newRow = { ...row, id: saveRes.id };
        setCurrencies([newRow, ...currencies]);
      }
    }

    if (actionType === ActionTypes.SAVE) {
      mUpdateCurrency.mutate(row);
    }
  };

  const resetForm = () => {
    setSelectedCurrency(null)
    setSelectedCurrencyIndex(-1)
  }

  const onFormSubmit = async (action, data) => {
    if (selectedCurrency?.id) {
      await onAction(ActionTypes.SAVE, { ...selectedCurrency, ...data }, selectedCurrencyIndex);
    } else {
      await onAction(ActionTypes.NEW_ENTRY, data)
    }
    resetForm();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={5} lg={4}>
          <Typography variant="h4" color={"text.heading"} fontWeight="600">
            Currencies
          </Typography>
        </Grid>
      </Grid>

      <DataTable
        headers={headers}
        rows={currencies}
        onAction={onAction}
        addButton={true}
      />

      <DynamicFormDialog
        open={!!selectedCurrency}
        values={selectedCurrency}
        title="Currency Details"
        actionType={DialogActionTypes.SAVE}
        config={CurrencyHelper.getCurrencyConfig()}
        onAction={onFormSubmit}
        onClose={resetForm}
      ></DynamicFormDialog>
    </>
  );
}
