import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { fontWeights } from "styles/fonts";

export default function AlertDialog({
  open,
  title,
  message,
  onContinue,
  onClose,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontWeight={fontWeights[600]}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {onContinue !== undefined && (
          <Button onClick={onContinue} autoFocus>
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
