import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import UserContext from "context/UserContext";
import { Fragment, useContext, useState } from "react";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";

const excludeKeys = ["SR", "actions", "name"];

export default function ProductCard({
  index = 0,
  row,
  headers = [],
  onAction,
  checkboxes,
  onCheckChange,
  showActions = true,
}) {
  const { user } = useContext(UserContext);
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const actionHeader = headers.find((h) => h.key === "actions");

  const checked = (checkboxes && row?.checked) || false;

  return (
    <Card
      sx={{
        mb: 2,
        border: checked
          ? `1px solid ${theme.palette.primary.main}99`
          : "1px solid #e0e0e0",
      }}
      elevation={0}
    >
      <CardContent
        className="product-card"
        sx={{
          ...(!expanded && { pb: 0 }),
        }}
      >
        <Stack
          spacing={2}
          direction={"row"}
          flex={5}
          justifyContent="space-between"
          alignItems={"flex-start"}
        >
          <Typography
            component={"div"}
            variant="button"
            fontWeight={600}
            pb={1}
          >
            {`${index}. ${row["name"]}`}
          </Typography>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? (
              <Icons.Collapse fontSize={"small"} />
            ) : (
              <Icons.Expand fontSize={"small"} />
            )}
          </IconButton>
        </Stack>
        {expanded && (
          <Fragment>
            {headers
              .filter((h) => !excludeKeys.includes(h.key))
              .map((h) => {
                return (
                  <Fragment key={h.key}>
                    <div className="text-content" key={h.key}>
                      <Typography
                        component={"span"}
                        variant="caption"
                        fontWeight={300}
                      >
                        {h.label}
                      </Typography>
                      <Typography
                        component={"span"}
                        variant="button"
                        fontWeight={500}
                        color={"text.secondary"}
                      >
                        {h.transform ? h.transform(row) : row[h.key] || "-"}
                      </Typography>
                    </div>
                  </Fragment>
                );
              })}
          </Fragment>
        )}
      </CardContent>

      {actionHeader && (
        <CardActions sx={{ px: 1, pt: 0 }}>
          {checkboxes && (
            <Checkbox
              size="small"
              disabled={row.checked && !row.newlyChecked}
              checked={checked}
              onChange={onCheckChange}
            />
          )}
          {showActions && expanded && (
            <ActionButtons
              data={actionHeader}
              role={user.role}
              onAction={onAction}
              row={row}
            />
          )}
        </CardActions>
      )}
    </Card>
  );
}

const ActionButtons = ({ row, data, role, onAction }) => {
  return (
    <>
      {Object.keys(data.items)
        .filter((action) =>
          row?.actions ? row.actions.includes(action) : true
        )
        .map((action) => {
          const ActionIcon = data.items[action];
          const color = data?.colors ? data.colors[action] : "#0007";
          if (data?.permissions && !data.permissions[action].includes(role))
            return null;

          return (
            <IconButton
              key={action}
              onClick={() => onAction(ActionTypes[action.toUpperCase()])}
              size="small"
            >
              <ActionIcon
                style={{
                  color: color,
                }}
                fontSize={"small"}
              />
            </IconButton>
          );
        })}
    </>
  );
};
