import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Icons from 'utils/icons';

export default function ImageUploadButton({ onChange, src, placeholder }) {
    const theme = useTheme();

    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        if (src) {
            setImageSrc(src);
        }
    }, [src])

    const onFileSelect = (e) => {
        setImageSrc(URL.createObjectURL(e.target.files[0]));
        if (onChange) {
            onChange(e.target.files[0]);
        }
    }

    const uploadButtonPositionStyle = imageSrc ? { position: 'absolute', top: 1, right: 1 } : {}
    const uploadButtonSize = imageSrc ? 'medium' : 'large'

    return (
        <Stack spacing={1} alignItems={'center'} justifyContent={'flex-end'} sx={{ height: 100, maxWidth: 300, border: `1px dashed ${theme.palette.primary.dark}`, borderRadius: 1, position: 'relative' }}>
            <Box color="primary" aria-label="upload picture" component="label" sx={{ cursor: 'pointer', ...uploadButtonPositionStyle }}>
                <input hidden accept="image/*" type="file" onChange={onFileSelect} />
                <Icons.ImageUpload fontSize={uploadButtonSize} sx={{ color: 'primary.main', opacity: 1, }} />
            </Box>
            {imageSrc ?
                (<img
                    src={imageSrc}
                    alt="logo"
                    height={100}
                    style={{
                        objectFit: 'contain',
                        position: 'absolute',
                        zIndex: -1,
                        borderRadius: 5,
                        margin: 0,
                        maxWidth: 300,
                        padding: 5
                    }}
                />)
                :
                <Typography variant={'caption'} color={'text.secondary'}>{placeholder}</Typography>
            }

        </Stack>
    )
}
