import Icons from "./icons";

const QuotationViewTypes = [
  {
    value: "list_view",
    Icon: Icons.List,
    tooltipText: "List View",
    column: null,
  },
  {
    value: "view_by_users",
    Icon: Icons.Users,
    tooltipText: "View By Users",
    column: "managed_by",
  },
  {
    value: "view_by_clients",
    Icon: Icons.Clients,
    tooltipText: "View By Clients",
    column: "client_name",
  },
];

const ClientViewTypes = [
  {
    value: "list_view",
    Icon: Icons.List,
    tooltipText: "List View",
    column: null,
  },
  {
    value: "view_by_users",
    Icon: Icons.Users,
    tooltipText: "View By Users",
    column: "associated_user",
  },
];

export { QuotationViewTypes, ClientViewTypes };
