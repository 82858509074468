const ErrorUtils = {
  parseError(error) {
    let errorMessage = error.message;
    if (error.response?.data?.message) {
      errorMessage = error.response?.data?.message;
    }
    if (
      Array.isArray(error?.response?.data) &&
      error?.response?.data?.length > 0
    ) {
      errorMessage = error?.response?.data[0]?.message;
    }
    return errorMessage;
  },
};

export default ErrorUtils;
