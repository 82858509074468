import {
    Badge,
    Box, Divider, IconButton, Menu, MenuItem, Stack, Typography
} from "@mui/material";
import ReminderActions from "actions/reminder";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { fontSizes, fontWeights } from "styles/fonts";
import ErrorUtils from "utils/error";
import Icons from "utils/icons";

export const Notifications = () => {
    const snackbar = useSnackbar();
    const navigate = useNavigate()

    const [anchor, setAnchor] = React.useState(null);


    const open = Boolean(anchor);

    const onNotificationsClick = (e) => {
        setAnchor(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };


    const { data: remindersData, refetch: fetchReminders } = useQuery({
        enabled: true,
        refetchInterval: 1000 * 60 * 10,
        queryKey: ['get-reminders'],
        queryFn: ReminderActions.getReminders,
    })

    const mDiscardReminder = useMutation({
        mutationKey: 'delete-reminder',
        mutationFn: ReminderActions.discardReminder,
        onError: (error) => {
            snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
                variant: 'error',
            })
        },
        onSuccess: () => {
            fetchReminders()
        }
    })

    const onDiscardReminder = (reminder) => {
        mDiscardReminder.mutate({
            quotation_id: reminder.quotation_id
        })
    }

    const getTotalBadgeCount = () => {
        if (remindersData.total) {
            return remindersData.total > 20 ? '20+' : remindersData.total
        }
    }

    const onReminderClick = (reminder) => {
        navigate(`/quotations/${reminder.quotation_id}`);
        closeMenu()
    }

    return (
        <>
            <IconButton color="inherit"
                onClick={onNotificationsClick}
            >
                {remindersData?.total ?
                    <Badge badgeContent={getTotalBadgeCount()} color="secondary">
                        <Icons.Notifications color="inherit" />
                    </Badge> :
                    <Icons.Notifications color="inherit" />
                }
            </IconButton>
            <Menu
                anchorEl={anchor}
                open={open}
                onClose={closeMenu}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                MenuListProps={{
                    style: {
                        padding: 0,
                        maxHeight: 300
                    }
                }}
                PaperProps={{
                    style: {
                        background: "#fff",
                    }
                }}
            >
                {remindersData?.data && remindersData.data.length > 0 ?
                    <Box>
                        <Box pl={2} pt={1} pb={1} sx={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 99
                        }}

                        >
                            <Typography fontWeight={fontWeights[600]} variant="button" color="text.secondary">Reminders</Typography>
                        </Box>
                        <Divider />
                        {
                            remindersData.data.map(reminder => (

                                <MenuItem key={reminder.id} sx={{ borderBottom: '1px solid #ddd' }} disableRipple onClick={() => onReminderClick(reminder)}>
                                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} width={'100%'} spacing={1}>
                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                            <Icons.Reminder color="primary" fontSize="small"></Icons.Reminder>
                                            <Typography fontSize={fontSizes.sm} pt={1} pb={1}>
                                                Reminder for quotation sent to <strong>{reminder.client_name}</strong> on <strong>{reminder.created_at}</strong>
                                            </Typography>
                                        </Stack>
                                        <IconButton size="small" onClick={() => onDiscardReminder(reminder)}>
                                            <Icons.ReminderOff color="action" fontSize="small"></Icons.ReminderOff>
                                        </IconButton>
                                    </Stack>
                                </MenuItem>
                            ))
                        }
                    </Box> :
                    <Stack
                        sx={{
                            height: 150,
                            width: 350
                        }}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography color={'text.secondary'} variant="subtitle1">No pending notifications!</Typography>
                    </Stack>}

            </Menu>


        </>
    );
};
