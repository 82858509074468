import api from "network/api";
import Endpoints from "./endpoints";

const createClient = async (data) => {
  const res = await api.post(Endpoints.clients, data);
  return res.data;
};

const getClientNames = async () => {
  const res = await api.get(`${Endpoints.clients}/name`);
  return res.data.map((item) => ({
    id: item.id,
    title: item.name,
  }));
};

const getClientCompanies = async () => {
  const res = await api.get(`${Endpoints.clients}/company`);
  return res.data.map((item) => ({
    id: item.id,
    title: item.company,
  }));
}

const getClients = async ({ queryKey }) => {
  const [, groupBy, limit, page] = queryKey;
  const params = {
    group_by: groupBy,
    limit,
    offset: (+page - 1) * +limit
  };
  const res = await api.get(Endpoints.clients, {
    params,
  });

  const rows = res.data?.data || [];
  const actions = ['View'];
  return {
    rows: rows.map(r => ({
      ...r,
      actions: actions,
      menuActions: ['Edit', 'Delete']
    })),
    total: res.data?.total || 0
  };
};

const getClientDetails = async ({ queryKey }) => {
  const clientId = queryKey[1];
  if (!clientId) return;

  const res = await api.get(`${Endpoints.clients}/${clientId}`);
  return res.data;
};

const deleteClient = async (clientId) => {
  if (!clientId) return;
  const res = await api.delete(`${Endpoints.clients}/${clientId}`);
  return res.data;
};

const updateClient = async (data) => {
  const res = await api.put(`${Endpoints.clients}/${data.id}`, data);
  return res.data;
};

const ClientActions = {
  createClient,
  getClientNames,
  getClientDetails,
  getClients,
  deleteClient,
  updateClient,
  getClientCompanies,
};
export default ClientActions;
