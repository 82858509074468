import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingButton({
  color = "primary",
  children,
  variant = "contained",
  loading = false,
  sx,
  onClick,
  spinnerSize = 24,
  startIcon,
  disabled,
  component,
}) {
  return (
    <Button
      component={component}
      disabled={loading || disabled}
      variant={variant}
      color={color}
      sx={{ alignSelf: "center", ...sx }}
      onClick={onClick}
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={spinnerSize} />
        ) : (
          startIcon
        )
      }
    >
      {children}
    </Button>
  );
}
