const QuotationStates = {
  PENDING_APPROVAL: 1,
  APPROVED: 2,
  PI_READY: 3,
  PO_PENDING: 4,
  PO_APPROVED: 5,
};

const QuotationStateNames = {
  [QuotationStates.PENDING_APPROVAL]: "Pending Approval",
  [QuotationStates.APPROVED]: "Approved",
  [QuotationStates.PI_READY]: 'PI Ready',
  [QuotationStates.PO_PENDING]: 'PO Pending',
  [QuotationStates.PO_APPROVED]: 'PO Ready',
};

export { QuotationStateNames, QuotationStates };
