import { fonts } from "styles/fonts"

const { MenuItem } = require("@mui/material")

export const StyledMenuItem = (props) => {
    return (
        <MenuItem dense style={{
            ...fonts.regular,
        }} {...props}>
            {props.children}
        </MenuItem>
    )
}
