import moment from "moment"


const DateTimeUtils = {
    getMonthName: function (month) {
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sept', 'Oct', 'Novr', 'Dec'
        ]
        return monthNames[month]
    },
    getPaddedDate: function (date) {
        return date < 10 ? '0' + date : date
    },
    getCurrentDateForFileName: function () {
        const date = new Date()
        const dateStr = date.getFullYear() + '_' + (this.getMonthName(date.getMonth())) + '_' + this.getPaddedDate(date.getDate())
        return dateStr
    },
    dateToString: function (date) {
        return this.getPaddedDate(date.getDate()) + ', ' + this.getMonthName(date.getMonth()) + ' ' + date.getFullYear()
    },
    getCurrentDate() {
        return moment().format('YYYY-MM-DD')
    }
}

export default DateTimeUtils
