import {
    Box,
    ButtonBase, IconButton,
    LinearProgress, AppBar as MuiAppBar, Stack, Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import AuthActions from "actions/auth";
import LoadingContext from "context/LoadingContext";
import * as React from "react";
import Icons from "utils/icons";
import { AppBar } from "./NavigationDrawer";
import { Notifications } from "./Notifications";

export const Header = ({ open, onDrawerOpen, title, onTitleClick, sx = {} }) => {
    const HeaderContainer = sx?.display?.xs === "block" ? MuiAppBar : AppBar;

    const { loading } = React.useContext(LoadingContext);

    const onLogout = async () => {
        await AuthActions.logOut();
    }


    return (
        <HeaderContainer
            position="fixed"
            open={open}
            sx={{
                ...sx,
                zIndex: 1000,
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 2,
                        ...(open && { display: "none" }),
                    }}
                >
                    <Icons.Menu />
                </IconButton>
                <ButtonBase disableRipple onClick={onTitleClick}>
                    <Typography variant="h6" noWrap component="div" fontWeight={'700'}>
                        {title}
                    </Typography>
                </ButtonBase>
                <Box flexGrow={1}></Box>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>

                    <Notifications
                    />
                    <IconButton color="inherit" onClick={onLogout}>
                        <Tooltip title="Logout">
                            <Icons.Logout />
                        </Tooltip>
                    </IconButton>
                </Stack>
            </Toolbar>
            {loading && <LinearProgress color="secondary" />}



        </HeaderContainer>
    );
};


