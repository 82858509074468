import axios from "axios";
import AuthActions from "actions/auth";
import { ApiErrorCodes } from "utils/error_codes";

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/`;

let failedRequests = []
let refreshingToken = false;

const processFailedRequests = (err) => {
  failedRequests.forEach(req => {
    if (err) {
      req.reject(err)
    } else {
      req.resolve();
    }
  })

  failedRequests = [];
}

const api = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true
});

api.interceptors.request.use(
  async (config) => {
    return config
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const data = error?.response?.data;
    if (error.response && error.response.status === 401 && !originalRequest._retry && data?.code !== ApiErrorCodes.ACCESS_TOKEN_MISSING) {


      try {
        if (originalRequest.url.includes('/login')) {
          return null;
        }
        const userName = localStorage.getItem('username')
        if (!userName) {
          return null;
        }


        if (refreshingToken) {
          return new Promise((resolve, reject) => {
            failedRequests.push({ resolve, reject });
          })
            .then(() => {
              return api(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = false;
        refreshingToken = true

        await AuthActions.refreshToken(userName)

        processFailedRequests(null);

        return api(originalRequest)
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        processFailedRequests(refreshError)
        return Promise.reject(refreshError);
      } finally {
        refreshingToken = false;
      }
    }
    return Promise.reject(error);
  }
);

export default api;
