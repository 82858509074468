import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import '../styles/products.css';

import { Box, Grid, Stack, Typography } from "@mui/material";
import AnalyticsActions from 'actions/analytics';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import TextInput from 'components/TextInput';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { fontWeights } from 'styles/fonts';
import AnalyticsTransform from 'transforms/analytics';

const ChartTypes = {
  'QUOTATIONS_PER_USER': 1,
  'PRODUCT_ENQUIRIES': 2,
}


export default function Analytics() {

  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const [quotationPerUserRange, setQuotationPerUserRange] = useState([AnalyticsTransform.getDefaultRange()]);
  const [productEnquiriesRange, setProductEnquiriesRange] = useState([AnalyticsTransform.getDefaultRange()]);

  const [dateSelectionChartType, setDateSelectionChartType] = useState(-1);

  const quotationPerUser = useQuery(
    {
      queryKey: ['get-quotations-per-user', AnalyticsTransform.transformRange(quotationPerUserRange)],
      queryFn: AnalyticsActions.getQuotationsPerUser,
      enabled: true,
    }
  )

  const productEnquiries = useQuery(
    {
      queryKey: ['get-product-enquiries', AnalyticsTransform.transformRange(productEnquiriesRange)],
      queryFn: AnalyticsActions.getProductEnquiries,
      enabled: true,
    }
  )

  const onDateRangeSelect = (ranges) => {
    if (dateSelectionChartType === ChartTypes.QUOTATIONS_PER_USER) {
      setQuotationPerUserRange(ranges)
    }
    if (dateSelectionChartType === ChartTypes.PRODUCT_ENQUIRIES) {
      setProductEnquiriesRange(ranges)
    }
    setDateRangePickerOpen(false)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color={"text.heading"} fontWeight="600">
            Analytics
          </Typography>
        </Grid>
      </Grid>
      <Box mt={3} width={'100%'}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} >
            <Box
              border={'1px solid #ddd'}
              borderRadius={2}
              p={3}>
              <Stack direction={"row"} alignItems={"flex-start"} justifyContent={'space-between'} spacing={3}>
                <Typography pl={7} pr={4} variant='h6' fontWeight={fontWeights[600]} color={'primary'} mb={3}>Top 10 Users</Typography>
                <TextInput
                  sx={{
                    width: 230
                  }}
                  inputProps={{
                    sx: {
                      textAlign: 'center',
                    },
                    readOnly: true
                  }}
                  onClick={() => {
                    setDateRangePickerOpen(true);
                    setDateSelectionChartType(ChartTypes.QUOTATIONS_PER_USER)
                  }}
                  size="small"
                  value={AnalyticsTransform.transformDateRangeToText(quotationPerUserRange)}
                  placeholder="Select date range"
                ></TextInput>
              </Stack>

              <ResponsiveChartContainer>
                <BarChart data={quotationPerUser?.data || []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis dataKey={"count"} allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Bar barSize={'5%'} dataKey="count" fill="#00BFC4" name={"Quotations sent"} />
                </BarChart>
              </ResponsiveChartContainer>
            </Box>

          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              border={'1px solid #ddd'}
              borderRadius={2}
              p={3}>
              <Stack direction={"row"} alignItems={"flex-start"} justifyContent={'space-between'} spacing={3}>
                <Typography pl={7} pr={4} variant='h6' fontWeight={fontWeights[600]} color={'primary'} mb={3}>Top 10 Products</Typography>
                <TextInput
                  sx={{
                    width: 230
                  }}
                  inputProps={{
                    sx: {
                      textAlign: 'center',
                    },
                    readOnly: true
                  }}
                  onClick={() => {
                    setDateRangePickerOpen(true);
                    setDateSelectionChartType(ChartTypes.PRODUCT_ENQUIRIES)
                  }}
                  readOnly={true}
                  size="small"
                  value={AnalyticsTransform.transformDateRangeToText(productEnquiriesRange)}
                  placeholder="Select date range"
                ></TextInput>
              </Stack>

              <ResponsiveChartContainer>
                <BarChart data={productEnquiries?.data || []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={false} />
                  <YAxis dataKey={"count"} allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Bar barSize={'5%'} dataKey="count" fill="#F8766D" name={"Total Enquiries"} />
                </BarChart>
              </ResponsiveChartContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <DateRangePickerDialog
        onClose={() => setDateRangePickerOpen(false)}
        open={dateRangePickerOpen}
        onSelect={onDateRangeSelect}
        defaultRange={[AnalyticsTransform.getDefaultRange()]}
      />
    </>
  );
}

function ResponsiveChartContainer({
  width = '100%',
  height = 300,
  children = null
}) {
  return (
    <Box
      sx={{
        width: width,
        height: height
      }}
    >
      <ResponsiveContainer height={height} width={width}>
        {children}
      </ResponsiveContainer>
    </Box>
  )
}
