import { Stack } from "@mui/material";
import ProductUtils from "utils/product";
import { FreightTypes } from "utils/proforma_invoice";
import RadioButtonsGroup from "./RadioButtonsGroup";
import TextInput from "./TextInput";
import Validator from "utils/validator";

export const FreightDetails = ({
    selectedOption, setSelectedOption, amount, setAmount, disabled, showAmount = false
}) => {

    return (
        <Stack direction={'column'}>
            <RadioButtonsGroup
                disabled={disabled}
                items={ProductUtils.getProductFreightOptions()}
                label={'Freight'}
                onChange={(e) => setSelectedOption(e.target.value)}
                value={selectedOption} />
            {parseInt(selectedOption, 10) === FreightTypes.TO_PAY && showAmount && (
                <TextInput
                    disabled={disabled}
                    value={amount}
                    onChange={(e) => setAmount(Validator.validateFloatInput(e))}
                    label={`Amount (₹)`}
                    sx={{
                        mt: 2,
                        maxWidth: 250
                    }}
                ></TextInput>
            )}
        </Stack>
    );
};
