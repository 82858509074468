export const fontSizes = {
  xs: "0.7rem",
  sm: "0.9rem",
  md: "1rem",
  lg: "1.2rem",
  xl: "1.4rem",
  xxl: "1.6rem",
};

export const fontWeights = {
  200: "200",
  300: "300",
  400: "400",
  500: "500",
  600: "600",
  700: "700",
};

export const fonts = {
  regular: {
    fontSize: fontSizes.sm,
    fontWeight: fontWeights[400],
  },
};
