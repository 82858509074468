const { default: api } = require("network/api")
const { default: Endpoints } = require("./endpoints")

const PurchaseOrderActions = {
    async savePODetails({ quotationId, data }) {
        if (!quotationId) {
            throw new Error("Invalid PO");
        }
        const res = await api.put(`${Endpoints.purchaseOrders}/${quotationId}`, data);
        return res.data;
    },
    async getPODetails({
        queryKey
    }) {
        const quotationId = queryKey[1];
        if (!quotationId) {
            throw new Error("Invalid PO");
        }
        const res = await api.get(`${Endpoints.purchaseOrders}/${quotationId}`);
        return res.data;
    },
    async generatePODoc({ quotationId }) {
        const res = await api.post(`${Endpoints.purchaseOrders}/${quotationId}/generate`);
        return res.data;
    },
    async getPODocUrl({ queryKey }) {
        const quotationId = queryKey[1];
        if (!quotationId) {
            throw new Error("Invalid params");
        }
        const url = await api.get(
            `${Endpoints.purchaseOrders}/${quotationId}/document-url`
        );
        return url;
    },
    async getPOProducts({ queryKey }) {
        const quotationId = queryKey[1];
        const res = await api.get(`${Endpoints.purchaseOrders}/${quotationId}/products`);
        return res.data;
    },
    async updatePOProduct({ quotationId, productId, data }) {
        try {
            const res = await api.put(
                `${Endpoints.purchaseOrders}/${quotationId}/products/${productId}`,
                data
            );
            return res.data;
        } catch (error) {
            throw error;
        }
    }
}

export default PurchaseOrderActions
