import DocumentTypes from "utils/document_types";
import Images from "utils/images";

const styles = {
  row: {
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: 3,
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 8,
  }
}

const DownloadDocsTransform = {
  transformDocumentType: (row) => {
    if (row.document_type === DocumentTypes.EXCEL) {
      return (
        <span style={styles.row}>
          <img alt={row.document_type} style={styles.icon} src={Images.Excel}></img>
          {DocumentTypes.EXCEL}
        </span>
      );
    }
    if (row.document_type === DocumentTypes.QUOTATION) {
      return (
        <span style={styles.row}>
          <img alt={row.document_type} style={styles.icon} src={Images.PDF}></img>
          {DocumentTypes.QUOTATION}
        </span>
      );
    }
    if (row.document_type === DocumentTypes.PROF_INVOICE) {
      return (
        <span style={styles.row}>
          <img alt={row.document_type} style={styles.icon} src={Images.PDF}></img>
          {DocumentTypes.PROF_INVOICE}
        </span>
      );
    }
    if (row.document_type === DocumentTypes.PURCHASE_ORDER) {
      return (
        <span style={styles.row}>
          <img alt={row.document_type} style={styles.icon} src={Images.PDF}></img>
          {DocumentTypes.PURCHASE_ORDER}
        </span>
      );
    }
    return <span>{row.document_type}</span>;
  },
};

export default DownloadDocsTransform;
