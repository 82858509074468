import api from "network/api";
import Endpoints from "./endpoints";

const getCurrencies = async () => {
  const res = await api.get(Endpoints.currencies);
  return res.data;
};

const createCurrency = async (body) => {
  try {
    const res = await api.post(Endpoints.currencies, body);
    return res.data;
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(
        `Invalid ${error.response.data?.map((e) => e.field).join(", ")}`
      );
    }
  }
};

const updateCurrency = async (data) => {
  try {
    const currencyId = data.id;
    if (!currencyId) {
      throw new Error("Invalid currency");
    }
    const rowData = { ...data };
    delete rowData.id;

    const res = await api.put(`${Endpoints.currencies}/${currencyId}`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

const CurrencyActions = {
  getCurrencies,
  createCurrency,
  updateCurrency,
};
export default CurrencyActions;
