import {
  Box,
  Button,
  Stack
} from "@mui/material";
import QuotationActions from "actions/quotation";
import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import "styles/quotation-details.css";

import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";


import OrganizationActions from "actions/organization";
import ProformaInvoiceActions from "actions/proforma_invoice";
import PurchaseOrderActions from "actions/purchase_order";
import { DownloadDocumentsDialog } from "components/DownloadDocumentsDialog";
import LoadingContainer from "components/LoadingContainer";
import { OrganizationDetails } from "components/OrganizationDetails";
import PODetailsDialog from "components/PODetailsDialog";
import SubHeader from "components/SubHeader";
import UserContext from "context/UserContext";
import PurchaseOrderTransform from "transforms/purchase_order";
import QuotationProductTransform from "transforms/quotation_product";
import DocumentTypes from "utils/document_types";
import ErrorUtils from "utils/error";
import ProductUtils from "utils/product";
import { QuotationStates } from "utils/quotation_states";
import Roles from "utils/roles";
import ErrorFallback from "./ErrorFallback";
import { PageTypes } from "utils/page_types";
import DynamicFormDialog from "components/DynamicFormDialog";
import { PurchaseOrderDetailsHelper } from "helpers/purchase_order_details";

const headers = (data) => {
  const _headers = [
    {
      key: "SR",
      label: "SR",
      tooltipText: "SERIAL NUMBER",
      width: 60,
      align: "center",
      transform: (row, i) => {
        return `${i + 1}.`;
      },
    },
    {
      key: "name",
      label: "PRODUCT NAME",
      tooltipText: "PRODUCT NAME",
      width: 320,
      align: "left",
    },
    {
      key: "pharmacopoeia",
      label: "PHARMACOPOEIA",
      tooltipText: "PHARMACOPOEIA",
      width: 120,
      align: "center",
      editable: true,
      required: true
    },
    {
      key: "gst",
      label: "GST(%)",
      tooltipText: "GST(%)",
      width: 60,
      align: "center",
      editable: true,
      required: true,
      type: 'number'
    },
    {
      key: "brand",
      label: "BRAND",
      tooltipText: "BRAND",
      width: 100,
      align: "center",
      editable: true,
      required: true
    },
    {
      key: "packing_type",
      label: "REMARK",
      tooltipText: "REMARK",
      width: 120,
      align: "center",
    },
    {
      key: "dosage",
      label: "DOSAGE",
      tooltipText: "DOSAGE",
      width: 120,
      align: "center",
      editable: true,
      required: true
    },
    {
      key: "packing",
      label: "PKG",
      tooltipText: "PACKING",
      width: 80,
      align: "center",
      transform: QuotationProductTransform.transformPacking,
    },
    {
      key: "po_price_per_unit",
      label: "RPU (₹)",
      tooltipText: "RATE PER UNIT (INR)",
      width: 100,
      align: "center",
      editable: true,
      required: true,
      type: 'number'
    },
    {
      key: "po_quantity_per_unit",
      label: "QNT/UNIT",
      tooltipText: "QUANTITY PER UNIT",
      width: 100,
      align: "center",
      editable: true,
      required: true,
      type: 'number'
    },
    {
      key: "po_price_per_pack",
      label: "PRICE/PACK",
      tooltipText: "PRICE PER PACK",
      width: 100,
      align: "center",
      transform: PurchaseOrderTransform.transformPricePerPack,
    },
    {
      key: "po_quantity_per_pack",
      label: "QNT/PACK",
      tooltipText: "QUANTITY PER PACK",
      width: 100,
      align: "center",
      transform: PurchaseOrderTransform.transformQuantityPerPack,
    },
    {
      key: "total",
      label: "TOTAL (₹)",
      width: 200,
      align: "center",
      transform: PurchaseOrderTransform.transformTotal,
    },
    {
      key: "actions",
      label: "ACTIONS",
      width: 100,
      align: "center",
      items: {
        Edit: Icons.Edit
      },
    },
  ];

  return ProductUtils.getHeaders(_headers, data.user);
};

export default function PurchaseOrderDetails({ mobile }) {
  const { user } = useContext(UserContext);

  const { quotationId } = useParams();
  const snackbar = useSnackbar();

  const { setLoading } = useContext(LoadingContext);

  const [resetTable, setResetTable] = useState(false);
  const [poDialogOpen, setPODialogOpen] = useState(false);
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);

  const [poProducts, setPOProducts] = useState([]);
  const [organization, setOrganization] = useState("");

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);

  const mUpdatePOProduct = useMutation({
    mutationKey: "update-po-products",
    mutationFn: PurchaseOrderActions.updatePOProduct,
    onSuccess: () => {
      setLoading(false);
      snackbar.enqueueSnackbar("Updated", {
        variant: "success",
      });
      fetchPOProducts();
    },
    onError: (error) => {
      setResetTable(!resetTable);

      setLoading(false);
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const mUpdateQuotationDetails = useMutation({
    mutationKey: "update-quotation-details",
    mutationFn: QuotationActions.updateQuotation,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("PO updated successfully", {
        variant: "success",
      });
      fetchPODetails();
    }
  });

  const [changeDetected, setChangeDetected] = useState(false);
  const listenForChange = () => {
    if (!changeDetected) {
      setChangeDetected(true);
    }
  };

  const resetProductForm = () => {
    setSelectedProduct(null);
    setSelectedProductIndex(-1)
  }

  const onAction = async (actionType, row, index) => {
    try {
      if (actionType === ActionTypes.EDIT) {
        setSelectedProduct(row);
        setSelectedProductIndex(index);
      }

      if (actionType === ActionTypes.SAVE) {
        const _rows = [...poProducts];
        _rows[index] = row;
        await mUpdatePOProduct.mutateAsync({
          quotationId: quotationId,
          productId: row.id,
          data: {
            pharmacopoeia: row.pharmacopoeia,
            gst: row.gst,
            brand: row.brand,
            dosage: row.dosage,
            po_price_per_unit: row.po_price_per_unit,
            po_quantity_per_unit: row.po_quantity_per_unit,
          },
        });
        setPOProducts(_rows);
        listenForChange();
        return true
      }
    } catch (err) { }
  };

  const { data: organizations } = useQuery({
    queryKey: ["get-organization-names"],
    queryFn: OrganizationActions.getOrganizationNames,
  });

  const {
    data: poDetails,
    isLoading: poDetailsLoading,
    refetch: fetchPODetails,
    error: poDetailsError,
  } = useQuery({
    queryKey: ["get-quotation-details", quotationId, PageTypes.PURCHASE_ORDERS],
    queryFn: QuotationActions.getQuotation,
    enabled: !!quotationId,
    onError: (error) => {
      if (error.response.status === 403) {
        snackbar.enqueueSnackbar("You are not authorized to view this page", {
          variant: "error",
        });
        window.location.href = "/";
      } else {
        snackbar.enqueueSnackbar(error.response?.data?.message || "Not found", {
          variant: "error",
        });
      }
    },
  });

  // select organization if poDetails?.organization_id is present
  useEffect(() => {
    if (poDetails?.organization_id && organizations) {
      const _organization = organizations.find(
        (o) => o.id === poDetails?.organization_id
      );
      setOrganization(_organization.id);
    }
  }, [poDetails?.organization_id, organizations]);

  const {
    isLoading: poProductsLoading,
    refetch: fetchPOProducts,
  } = useQuery({
    queryKey: ["get-po-products", quotationId],
    queryFn: PurchaseOrderActions.getPOProducts,
    enabled: !!quotationId,
    onError: (error) => {
      setLoading(false);
      snackbar.enqueueSnackbar(
        error.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    },
    onSuccess: (data) => {
      setPOProducts(data?.data || []);
      setLoading(false);
    },
  });


  useEffect(() => {
    setLoading(
      poProductsLoading || poDetailsLoading
    );
    // eslint-disable-next-line
  }, [poProductsLoading, poDetailsLoading]);

  const { refetch: fetchProfInvoiceDocUrl } = useQuery({
    queryKey: ["get-prof-invoice-doc", quotationId],
    queryFn: ProformaInvoiceActions.getProfInvoiceDocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
  });

  const { refetch: fetchPODocUrl } = useQuery({
    queryKey: ["get-po-doc-url", quotationId],
    queryFn: PurchaseOrderActions.getPODocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
  });

  const onQuotationStateChange = (state) => {
    mUpdateQuotationDetails.mutate({
      quotationId,
      data: {
        state,
      },
    });
  }

  const onProfInvoiceDownload = async () => {
    const res = await fetchProfInvoiceDocUrl();
    const url = res.data.data;
    window.open(url, "_blank");
  }

  const onPODownload = async () => {
    const res = await fetchPODocUrl();
    const url = res.data.data;
    window.open(url, "_blank");
  }

  const onFormSubmit = async (actionType, data) => {
    const poProduct = {
      ...data,
      id: selectedProduct?.id
    }
    await onAction(ActionTypes.SAVE, poProduct, selectedProductIndex)

    resetProductForm();
  }

  if (poDetailsError) {
    return <ErrorFallback error={poDetailsError} />;
  }

  if (poDetailsLoading || !poDetails) {
    return <LoadingContainer height={"70vh"} width="100%" />;
  }

  const headerData = {
    user: user
  };

  const pageTitle = "Purchase Order Details"
  const headerText = poDetails?.quotation_number ? `${pageTitle} : ${poDetails?.quotation_number || ""}` : pageTitle

  return (
    <Box>
      <SubHeader title={headerText} />

      {!!poDetails && (
        <>
          <OrganizationDetails
            disabled={!!poDetails?.client_status}
            organizations={organizations || []}
            organization={organization}
          />
        </>
      )}

      <Box mt={4}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          flexWrap={"wrap"}
        >

          {
            poDetails?.state !== QuotationStates.PO_APPROVED &&
            <Button
              variant="text"
              onClick={() => setPODialogOpen(true)}
              startIcon={<Icons.GenerateDocument />}
            >
              {poDetails?.po_doc_url ? 'Update PO' : 'Generate PO'}
            </Button>
          }

          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            {
              user.role === Roles.ADMIN && poDetails?.po_doc_url && poDetails?.state === QuotationStates.PO_PENDING &&
              <Button onClick={() => onQuotationStateChange(QuotationStates.PO_APPROVED)} variant="outlined" startIcon={<Icons.Check />}>Approve PO</Button>
            }
            {
              poDetails?.po_doc_url && (
                <Button onClick={() => setDocumentsDialogOpen(true)} variant="text" startIcon={<Icons.Download />}>Download Documents</Button>
              )
            }
          </Stack>
        </Stack>
      </Box>


      <DataTable
        headers={
          headers(headerData)
        }
        rows={poProducts}
        onAction={onAction}
        reset={resetTable}
        showActions={
          poDetails?.state !== QuotationStates.PO_APPROVED
        }
      />

      <PODetailsDialog
        open={poDialogOpen}
        onClose={() => setPODialogOpen(false)}
        data={
          {
            quotationId: quotationId,
            organizationId: poDetails?.organization_id,
          }
        }
        edit={false}
        onSuccess={fetchPODetails}
      />

      <DownloadDocumentsDialog
        data={{
          prof_invoice_doc_url: poDetails?.prof_invoice_doc_url,
          po_doc_url: poDetails?.po_doc_url
        }}
        open={documentsDialogOpen}
        onClose={() => setDocumentsDialogOpen(false)}
        documentTypes={[
          DocumentTypes.PROF_INVOICE,
          DocumentTypes.PURCHASE_ORDER,
        ]}
        actions={{
          downloadProformaInvoice: onProfInvoiceDownload,
          downloadPO: onPODownload,
        }}
      />

      <DynamicFormDialog
        open={!!selectedProduct}
        values={selectedProduct}
        title="Product Details"
        actionType={ActionTypes.SAVE}
        config={PurchaseOrderDetailsHelper.getProductFormConfig()}
        onAction={onFormSubmit}
        onClose={resetProductForm}
      ></DynamicFormDialog>
    </Box>
  );
}


