import { Card, CardContent, Container, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import TextInput from "components/TextInput";
import { useState } from "react";

import AuthActions from "actions/auth";
import LoadingButton from "components/LoadingButton";
import { useSnackbar } from "notistack";
import Icons from "utils/icons";
import Images from "utils/images";
import Validator from "utils/validator";

export default function Login() {
  const snackbar = useSnackbar();
  const theme = useTheme();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState([]);

  const onLogin = async () => {
    const validationErrors = Validator.validate(
      Validator.validateUserName(userName),
      Validator.validatePassword(password)
    );

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }

    setLoading(true);

    const _user = await AuthActions.login(userName, password);
    if (!_user) {
      snackbar.enqueueSnackbar("Login failed", {
        variant: "error",
      });
      setLoading(false);
      return;
    }

    setTimeout(async () => {
      snackbar.enqueueSnackbar("Login successful", {
        variant: "success",
      });
      setLoading(false);
      window.location.reload();
    }, 1000);
  };

  return (
    <Container
      fixed
      maxWidth="lg"
      style={{ textAlign: "center", height: "100vh", display: "flex", alignItems: "center" }}
    >
      <Paper elevation={10} sx={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Card
              elevation={0}
              sx={{
                height: '100%',
                minHeight: '60vh',
              }}
            >
              <CardContent sx={{ height: '100%', p: 8, pt: '24px' }}>
                <Stack spacing={4} justifyContent={'center'} height={'100%'}>
                  <Typography variant="h3" textAlign={'left'} fontSize={40}>Login</Typography>
                  <TextInput
                    error={!!errors?.userName}
                    helperText={errors?.userName}
                    variant="outlined"
                    fullWidth
                    label="Username"
                    value={userName}
                    name="username"
                    onChange={(e) => setUserName(e.target.value)}
                    onFocus={() => setErrors({
                      ...errors,
                      userName: false
                    })}
                  />
                  <TextInput
                    error={!!errors?.password}
                    helperText={errors?.password}
                    variant="outlined"
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setErrors({
                      ...errors,
                      password: false
                    })}
                  />
                  <LoadingButton
                    color="primary"
                    sx={{ alignSelf: "center", boxShadow: 'none', width: '100%', fontWeight: 'bold', backgroundColor: theme.palette.primary.light }}
                    variant="contained"
                    onClick={onLogin}
                    loading={loading}
                    endIcon={<Icons.ChevronRight />}
                  >
                    Login
                  </LoadingButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={0} lg={6} sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}>
            <img src={Images.LoginBackground} alt="login background" style={{ width: "100%", height: '100%', objectFit: 'cover' }} />
          </Grid>
        </Grid>
      </Paper>

    </Container>
  );
}
