import { CircularProgress, Container } from "@mui/material";

export default function LoadingContainer({ height, width }) {
  return (
    <Container
      maxWidth="lg"
      style={{
        height: height ? height : "100vh",
        width: width ? width : "100vw",
        background: "#fff",
        position: "static",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={60}></CircularProgress>
    </Container>
  );
}
