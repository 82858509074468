import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

// Components
import { Box, Divider, Grid, Typography } from '@mui/material'
import SubHeader from 'components/SubHeader'
import TextArea from 'components/TextArea'
import TextInput from 'components/TextInput'

// Actions
import OrganizationActions from 'actions/organization'

// Contexts
import ImageUploadButton from 'components/ImageUploadButton'
import LoadingButton from 'components/LoadingButton'
import LoadingContext from 'context/LoadingContext'
import { useSnackbar } from 'notistack'
import ErrorUtils from 'utils/error'
import Icons from 'utils/icons'

export default function OrganizationDetails() {
    const { organizationId } = useParams();
    const { setLoading } = useContext(LoadingContext);
    const snackbar = useSnackbar();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [website, setWebsite] = useState("");
    const [address, setAddress] = useState("");

    const [errors, setErrors] = useState({});

    const { data: organizationDetails, isLoading } = useQuery({
        queryKey: ["organizationDetails", organizationId],
        queryFn: OrganizationActions.getOrganization,
        onError: (error) => {
            console.log(error);
            snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
                variant: "error",
            });
        }
    })

    const mUpdateOrganization = useMutation(
        OrganizationActions.updateOrganization,
        {
            onError: (error) => {
                snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
                    variant: "error",
                });
            },
            onSuccess: (data) => {
                snackbar.enqueueSnackbar("Organization information updated successfully", {
                    variant: "success",
                });
            },
        }
    );

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading, setLoading])

    useEffect(() => {
        if (organizationDetails) {
            setName(organizationDetails.name);
            setEmail(organizationDetails.email);
            setContactNo(organizationDetails.contact_no);
            setWebsite(organizationDetails.website);
            setAddress(organizationDetails.address);
        }
    }, [organizationDetails])


    const validateErrors = () => {
        const _errors = {};
        if (!name) _errors.name = true;
        if (!email) _errors.email = true;
        if (!contactNo) _errors.contactNo = true;
        if (!website) _errors.website = true;
        if (!address) _errors.address = true;
        return _errors;
    }

    const onSave = async () => {
        const _errors = validateErrors();
        if (Object.keys(_errors).length > 0) {
            setErrors(_errors);
            return;
        }

        const data = {
            id: organizationId,
            name,
            email,
            contact_no: contactNo,
            website,
            address
        }

        await mUpdateOrganization.mutateAsync(data);
    }

    const onLogoChange = async (file) => {
        const urlResponse = await OrganizationActions.getUploadUrl({
            organizationId,
            fileName: file.name,
            mime: file.type
        });

        const filePath = urlResponse.path;

        const uploadResponse = await OrganizationActions.uploadFileWithSignedUrl({
            signedUrl: urlResponse.url,
            file
        });

        if (!urlResponse?.url || !uploadResponse) {
            snackbar.enqueueSnackbar("Failed to upload logo", {
                variant: "error",
            });
            return;
        }

        const data = {
            id: organizationId,
            logo: filePath
        }
        mUpdateOrganization.mutateAsync(data);
    }

    const onStampChange = async (file) => {
        const urlResponse = await OrganizationActions.getUploadUrl({
            organizationId,
            fileName: file.name,
            mime: file.type
        });

        const filePath = urlResponse.path;

        const uploadResponse = await OrganizationActions.uploadFileWithSignedUrl({
            signedUrl: urlResponse.url,
            file
        });

        if (!urlResponse?.url || !uploadResponse) {
            snackbar.enqueueSnackbar("Failed to upload stamp", {
                variant: "error",
            });
            return;
        }

        const data = {
            id: organizationId,
            stamp: filePath
        }
        mUpdateOrganization.mutateAsync(data);
    }

    const onSignatureChange = async (file) => {
        const urlResponse = await OrganizationActions.getUploadUrl({
            organizationId,
            fileName: file.name,
            mime: file.type
        });

        const filePath = urlResponse.path;

        const uploadResponse = await OrganizationActions.uploadFileWithSignedUrl({
            signedUrl: urlResponse.url,
            file
        });

        if (!urlResponse?.url || !uploadResponse) {
            snackbar.enqueueSnackbar("Failed to upload signature", {
                variant: "error",
            });
            return;
        }

        const data = {
            id: organizationId,
            signature: filePath
        }
        mUpdateOrganization.mutateAsync(data);
    }

    return (
        <Box>
            <SubHeader title="Organization Details"></SubHeader>

            <Grid container spacing={2} mt={4} mb={3}>
                <Grid item xs={12}>
                    <Typography required variant='button' color={'text.secondary'} fontWeight={'bold'}>BASIC DETAILS</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput required label="Organization Name" fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onFocus={() => setErrors({ ...errors, name: false })}
                    ></TextInput>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput required label="Organization Email" fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setErrors({ ...errors, email: false })}
                    ></TextInput>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput required label="Contact Number" fullWidth
                        value={contactNo}
                        onChange={(e) => setContactNo(e.target.value)}
                        onFocus={() => setErrors({ ...errors, contactNo: false })}
                    ></TextInput>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput required label="Website" fullWidth
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        onFocus={() => setErrors({ ...errors, website: false })}
                    ></TextInput>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextArea required placeholder="Address" fullWidth
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        onFocus={() => setErrors({ ...errors, address: false })}
                    ></TextArea>
                </Grid>
                <Grid item xs={12}>
                    <ImageUploadButton
                        src={organizationDetails?.logo}
                        onChange={onLogoChange}
                        placeholder={'Upload Logo'}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ImageUploadButton
                        src={organizationDetails?.stamp}
                        onChange={onStampChange}
                        placeholder={'Upload Stamp'}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ImageUploadButton
                        src={organizationDetails?.signature}
                        onChange={onSignatureChange}
                        placeholder={'Upload Signature'}
                    />
                </Grid>

                <Grid item xs={12} mt={2}>
                    <LoadingButton variant='outlined' loading={mUpdateOrganization.isLoading} onClick={onSave} startIcon={<Icons.Save />}>
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={2} mt={1} mb={3}>
                <Grid item xs={12}>
                    <Typography variant='button' color={'text.secondary'} fontWeight={'bold'}>DOCUMENTS</Typography>
                </Grid>
            </Grid>

        </Box>
    )
}
