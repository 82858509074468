import { ClientStatus, ClientStatusNames } from "utils/client_status";
import { QuotationStateNames } from "utils/quotation_states";

const QuotationTransform = {
  transformClientStatus: (row) => {
    if (row.client_status === ClientStatus.ACCEPTED) {
      return (
        <span style={{ color: "#4caf50" }}>
          {ClientStatusNames[ClientStatus.ACCEPTED]}
        </span>
      );
    }
    if (row.client_status === ClientStatus.REJECTED) {
      return (
        <span style={{ color: "#f44336" }}>
          {ClientStatusNames[ClientStatus.REJECTED]}
        </span>
      );
    }
    if (row.client_status === ClientStatus.PENDING) {
      return (
        <span style={{ color: "#ff9800" }}>
          {ClientStatusNames[ClientStatus.PENDING]}
        </span>
      );
    }
    return "-";
  },
  transformState: (row) => {
    if (row?.state) {
      return QuotationStateNames[row.state];
    }
    return "-";
  },
};

export default QuotationTransform;
