const OrganizationTransform = {
  transformLogo: (row) => {
    if (row?.logo) {
      return (
        <img
          alt="Organization Logo"
          style={{ height: 60, padding: "10px 0px" }}
          src={row.logo}
        ></img>
      );
    }

    return "-";
  },
};

export default OrganizationTransform;
