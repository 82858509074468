const { routes } = require("./constants");

const RouterUtils = {
  getRoutesForUser: (user, mobile) => {
    if (!user) return [];
    const role = user.role;
    const accountType = user['account_type'];

    return routes
      .filter((route) => {
        const routeData = route?.data || {};
        if (mobile === undefined && routeData?.mobile === undefined)
          return true;

        if (
          typeof mobile === "boolean" &&
          typeof routeData?.mobile === "boolean"
        )
          return mobile === routeData.mobile;

        return true;
      })
      .filter(item => item.accountTypes.includes(accountType))
      .filter((r) => r.accessRoles && r.accessRoles.includes(role));
  },
  getRoutePath: (route) => {
    let routePath = route.path
    if (routePath === "/quotations") {
      routePath = `${route.path}?page=1&limit=10`
    };
    return routePath
  },
  getDomainRedirectionPath(prefix) {
    const url = process.env.REACT_APP_DOMAIN;
    const urlSplit = url.split('://');
    return `${urlSplit[0]}://${prefix}.${urlSplit[1]}`
  }
};

export default RouterUtils;
