import ManufacturerActions from "actions/manufacturer";
import AccountType from "utils/account_type"
import { InputTypes } from "utils/input_types";
import ProductUtils from "utils/product";

export const ProductsHelper = {
    getRequiredKeys: (user) => {
        if (user.account_type === AccountType.MANUFACTURER) {
            return ['name', 'unit', 'grade'];
        }
        if (user.account_type === AccountType.DISTRIBUTOR) {
            return ['name', 'packing', 'packing_type', 'manufacturer', 'moq', 'price_per_unit'];
        }
    },
    getFormConfig: (user) => {
        if (user.account_type === AccountType.MANUFACTURER) {
            return ProductsHelper.getFormConfigForManufacturer()
        }
        if (user.account_type === AccountType.DISTRIBUTOR) {
            return ProductsHelper.getFormConfigForDistributor()
        }
    },
    getFormConfigForDistributor() {
        return [
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Product Name",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'name',
                validation: {
                    expression: /^.{1,100}$/,
                    message: 'Name should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Packing",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'packing',
                validation: {
                    expression: /^\d+(\s*[xX]\s*\d+)?$/,
                    message: 'Packing should be in format number or number x number'
                },
                transform: (value) => `${value}`.toLowerCase(),
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Packing Type",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'packing_type',
                validation: {
                    expression: /^.{1,30}$/,
                    message: 'Packing type should be 1-100 characters long'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.AUTOCOMPLETE,
                label: "Manufacturer",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'manufacturer',
                validation: {
                    expression: /^.{1,50}$/,
                    message: 'Manufacturer should be 1-50 characters long'
                },
                type: 'text',
                options: [],
                queryFn: ManufacturerActions.getMFG
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "MOQ",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'moq',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'MOQ should be a valid number'
                },
                type: 'text',
                options: []
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Price Per Unit",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'price_per_unit',
                validation: {
                    expression: /^\d+(\.\d+)?$/,
                    message: 'Price per unit should be a valid number'
                },
                type: 'text',
                options: []
            },
        ]
    },
    getFormConfigForManufacturer() {
        return [
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Product Name",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'name',
                validation: {
                    expression: /^.{1,100}$/,
                    message: 'Name should be 1-100 characters long'
                },
                type: 'text'
            },
            {
                inputType: InputTypes.DROPDOWN,
                label: "Unit",
                placeholder: "",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'unit',
                validation: {
                    expression: /^.{1,50}$/,
                    message: 'Please select unit'
                },
                type: 'text',
                showNone: false,
                options: ProductUtils.getProductUnitsOptions()
            },
            {
                inputType: InputTypes.DROPDOWN,
                label: "Grade",
                placeholder: "",
                required: true,
                disabled: false,
                defaultValue: '',
                name: 'grade',
                validation: {
                    expression: /^.{1,50}$/,
                    message: 'Please select grade'
                },
                type: 'text',
                showNone: false,
                options: ProductUtils.getProductGradeOptions()
            },
            {
                inputType: InputTypes.AUTOCOMPLETE,
                label: "Manufacturer",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'manufacturer',
                validation: {
                    expression: /^.{0,50}$/,
                    message: 'Manufacturer in invalid'
                },
                type: 'text',
                queryFn: ManufacturerActions.getMFG
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Quantity",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'moq',
                validation: {
                    expression: /^(\d+(\.\d+)?|)$/,
                    message: 'QUANTITY should be a valid number'
                },
                type: 'text'
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Price",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'price_per_unit',
                validation: {
                    expression: /^(\d+(\.\d+)?|)$/,
                    message: 'Price should be a valid number'
                },
                type: 'text',
            },
        ]
    }

}
