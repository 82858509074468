import api from "network/api";
import Endpoints from "./endpoints";

const getQuotationsPerUser = async ({ queryKey }) => {
  const dateRange = queryKey[1];
  const res = await api.get(`${Endpoints.analytics}/quotations-per-user`, {
    params: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    }
  });
  return res.data?.data || [];
};

const getProductEnquiries = async ({ queryKey }) => {
  const dateRange = queryKey[1];
  const res = await api.get(`${Endpoints.analytics}/product-enquiries`, {
    params: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate
    }
  });
  return res.data?.data || [];
};

const AnalyticsActions = {
  getQuotationsPerUser,
  getProductEnquiries
};
export default AnalyticsActions;
