import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";

import { lightTheme } from "./styles/themes";

import UserContext from "context/UserContext";

import LoadingContainer from "components/LoadingContainer";
import Dashboard from "screens/Dashboard";
import Login from "screens/Login";

import AuthActions from "actions/auth";
import { SnackbarProvider } from "notistack";
import RouterUtils from "utils/router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        const _user = await AuthActions.getCurrentUser();
        if (_user?.domain_prefix && !window.location.hostname.includes(_user.domain_prefix)) {
          window.location.href = RouterUtils.getDomainRedirectionPath(_user?.domain_prefix)
        }
        setTimeout(() => {
          setUser(_user);
          setLoading(false);
        }, 1000)
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingContainer />;

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider autoHideDuration={4000} maxSnack={2}>
        <UserContext.Provider
          value={{
            user,
            setUser,
          }}
        >
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <div className="App">{user ? <Dashboard /> : <Login />}</div>
          </ThemeProvider>
        </UserContext.Provider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
