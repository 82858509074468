import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Icons from "utils/icons";
import Dropdown from "components/Dropdown";
import { TinyInput } from "./TinyInput";

export const CurrencyDetails = ({
    disabled, currencies, currency, onCurrencyChange, onConversionRateChange, rate = 1, listenForChange, total, currencyTotal,
}) => {
    const [conversionRate, setConversionRate] = useState(1);

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setConversionRate(rate);
    }, [rate]);

    const _onConversionRateChange = (e) => {
        if (isNaN(Number(e.target.value)))
            return;
        setConversionRate(e.target.value);
        listenForChange();
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
                <Stack direction={"row"}>
                    <Typography variant="body2" component={"span"} fontWeight="600">
                        {`Currency:`}
                    </Typography>

                    <Dropdown
                        disabled={disabled}
                        labelKey="currency"
                        valueKey="currency"
                        onChange={onCurrencyChange}
                        value={currency}
                        options={currencies || []}
                    ></Dropdown>
                </Stack>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Stack direction={"row"}>
                    <Typography
                        variant="body2"
                        component={"span"}
                        fontWeight="600"
                        sx={{ pr: 1 }}
                    >
                        {`Conversion Rate:`}
                    </Typography>
                    {!disabled ?
                        <Stack direction={"row"} spacing={1} sx={{ position: "relative" }}>
                            <TinyInput
                                disabled={!editing || disabled}
                                value={conversionRate}
                                onChange={_onConversionRateChange} />

                            <Box
                                sx={{
                                    position: "absolute",
                                    left: 34,
                                    ...(disabled && { pointerEvents: "none" }),
                                }}
                            >
                                {editing ? (
                                    <Icons.Save
                                        sx={{ color: "rgba(0, 0, 0, 0.467)", cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => {
                                            setEditing(false);
                                            onConversionRateChange(conversionRate);
                                        }} />
                                ) : (
                                    <Icons.Edit
                                        sx={{ color: "rgba(0, 0, 0, 0.467)", cursor: "pointer" }}
                                        fontSize="small"
                                        onClick={() => {
                                            setEditing(true);
                                        }} />
                                )}
                            </Box>
                        </Stack> :
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {conversionRate || ""}
                        </Typography>
                    }
                </Stack>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={3}>
              <Stack direction={"row"}>
                <Typography
                  variant="body2"
                  component={"span"}
                  fontWeight="600"
                  sx={{ pr: 1 }}
                >
                  {`Total (INR):`}
                </Typography>
                <Typography variant="body2" component={"span"} fontWeight="400">
                  {total || 0}
                </Typography>
              </Stack>
            </Grid>
            {currency && (
              <Grid item xs={12} md={6} lg={3}>
                <Stack direction={"row"}>
                  <Typography
                    variant="body2"
                    component={"span"}
                    fontWeight="600"
                    sx={{ pr: 1 }}
                  >
                    {`Total (${currency}):`}
                  </Typography>
                  <Typography variant="body2" component={"span"} fontWeight="400">
                    {currencyTotal || 0}
                  </Typography>
                </Stack>
              </Grid>
            )} */}
        </Grid>
    );
};
