import {
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import UserContext from "context/UserContext";
import { Fragment, useContext } from "react";
import { ActionTypes } from "utils/action_types";
import { ClientStatus, ClientStatusNames } from "utils/client_status";
import Icons from "utils/icons";
import { QuotationStateNames, QuotationStates } from "utils/quotation_states";

const excludeKeys = [
  "SR",
  "actions",
  "state",
  "client_status",
  "country",
  "client_name",
  "client_company",
  "client_country",
];

export default function QuotationCard({ row, headers = [], onAction }) {
  const { user } = useContext(UserContext);

  const actionHeader = headers.find((h) => h.key === "actions");

  return (
    <Card
      sx={{
        mb: 2,
        border: row["client_status"] ? "none" : "1px solid #e0e0e0",
      }}
      elevation={0}
      style={{
        ...(row["client_status"] === ClientStatus.ACCEPTED && {
          backgroundColor: "#e6f4ea",
        }),
        ...(row["client_status"] === ClientStatus.REJECTED && {
          backgroundColor: "#fde2e2",
        }),
      }}
    >
      <CardContent className="quotation-card">
        <Typography sx={{ fontSize: 16 }} color="text.secondary">
          {row["client_name"]}
        </Typography>
        <div>
          <Typography variant="h6" component="span">
            {row["client_company"]},&nbsp;
          </Typography>
          <Typography
            sx={{ fontSize: 16 }}
            color="text.secondary"
            component={"span"}
          >
            {row["client_country"]}
          </Typography>
        </div>
        <div style={{ marginTop: 4 }}>
          {headers
            .filter((h) => !excludeKeys.includes(h.key))
            .map((h) => {
              return (
                <Fragment key={h.key}>
                  {row[h.key] && (
                    <div className="text-content" key={h.key}>
                      <Typography
                        component={"span"}
                        variant="caption"
                        fontWeight={300}
                      >
                        {h.label}:
                      </Typography>
                      <Typography
                        component={"span"}
                        variant="subtitle2"
                        fontWeight={500}
                        color={"text.secondary"}
                      >
                        {h.transform ? h.transform(row) : row[h.key] || "-"}
                      </Typography>
                    </div>
                  )}
                </Fragment>
              );
            })}
        </div>

        <Stack direction={"row"} spacing={1} alignItems="flex-end" mt={1}>
          {!!row["client_status"] && (
            <StatusChip status={row["client_status"]} />
          )}
          {!!row["state"] && <StateChip state={row["state"]} />}
        </Stack>
      </CardContent>

      {actionHeader && (
        <CardActions sx={{ px: 2, pt: 0 }}>
          <ActionButtons
            data={actionHeader}
            role={user.role}
            onAction={onAction}
            row={row}
          />
        </CardActions>
      )}
    </Card>
  );
}

const ActionButtons = ({ row, data, role, onAction }) => {
  return (
    <>
      {Object.keys(data.items)
        .filter((action) =>
          row?.actions ? row.actions.includes(action) : true
        )
        .map((action) => {
          const ActionIcon = data.items[action];
          const color = data?.colors ? data.colors[action] : "#0007";
          if (data?.permissions && !data.permissions[action].includes(role))
            return null;

          return (
            <IconButton
              key={action}
              onClick={() => onAction(ActionTypes[action.toUpperCase()])}
              size="small"
            >
              <ActionIcon
                style={{
                  color: color,
                }}
                fontSize={"small"}
              />
            </IconButton>
          );
        })}
    </>
  );
};

const StatusChip = ({ status }) => {
  return (
    <Chip
      color={
        status === ClientStatus.ACCEPTED
          ? "success"
          : status === ClientStatus.REJECTED
            ? "error"
            : "default"
      }
      avatar={
        status === ClientStatus.ACCEPTED ? (
          <Icons.Check
            fontSize="small"
            style={{
              color: "green",
            }}
          />
        ) : status === ClientStatus.REJECTED ? (
          <Icons.Cancel
            style={{
              color: "red",
            }}
          />
        ) : null
      }
      label={ClientStatusNames[status]}
      variant="outlined"
      size="small"
      sx={{ mt: 1 }}
    />
  );
};
const StateChip = ({ state }) => {
  return (
    <Chip
      color={
        state === QuotationStates.PENDING_APPROVAL
          ? "warning"
          : state === QuotationStates.APPROVED
            ? "success"
            : "default"
      }
      label={QuotationStateNames[state]}
      variant="outlined"
      size="small"
      sx={{ mt: 1 }}
    />
  );
};
