import { Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ViewSwitcher({ items = [], selected, onChange }) {
  const _onChange = (selectedObj) => {
    const _selected = {
      value: selectedObj.value,
      column: selectedObj.column,
    };
    onChange(_selected);
  };
  return (
    <Stack spacing={2} alignItems="center" mx={4}>
      <ToggleButtonGroup size="small" color="primary" exclusive={true}>
        {items.map((ViewStyle) => (
          <ToggleButton
            value={ViewStyle.value}
            key={ViewStyle.value}
            onClick={() => _onChange(ViewStyle)}
            selected={selected.value === ViewStyle.value}
          >
            <Tooltip title={ViewStyle.tooltipText}>
              <ViewStyle.Icon fontSize="small" />
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
