import * as React from 'react';
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import { fontSizes } from 'styles/fonts';
import { useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';


const StyledTextarea = styled(TextareaAutosize)(
  ({ theme, disabled }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: ${fontSizes.sm};
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    border: 0.15rem solid ${grey[200]};
    color: ${disabled ? theme.palette.text.secondary : theme.palette.text.primary};

    &:focus {
      border-width: 0.15rem;
      border-color: ${theme.palette.primary.main};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

export default function TextArea({
  value = '',
  onChange,
  rows = 3,
  placeholder = '',
  required = false,
  onFocus,
  error,
  disabled
}) {

  const theme = useTheme();

  return (
    <StyledTextarea
      disabled={disabled}
      style={
        { ...(error && { borderColor: theme.palette.error.main, borderWidth: '0.07rem', color: theme.palette.error.main }) }
      }
      sx={{
        ...(error && {
          '&::placeholder': {
            opacity: 1
          }
        })
      }}
      onFocus={onFocus}
      required={required}
      value={value}
      onChange={onChange}
      minRows={rows}
      placeholder={placeholder}
    />
  );
}
