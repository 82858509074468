import api from "network/api";
import ErrorUtils from "utils/error";
import { getExcelMapping } from "utils/products_mapping";
import Endpoints from "./endpoints";

const getProducts = async ({ queryKey }) => {
  const [, searchText, limit, offset, orderBy, orderType] = queryKey;

  const params = {};
  if (orderBy) params.orderBy = orderBy;
  if (orderType) params.orderType = orderType;
  if (limit) params.limit = limit;
  if (offset) params.offset = offset;
  if (searchText) {
    params.search = searchText
    params.limit = 30
  };

  const res = await api.get(Endpoints.products, {
    params
  });
  const data = res?.data;
  return {
    data: data?.data || [],
    total: data?.total || 0
  };
};

const getProductsForExcel = async () => {
  const res = await api.get(Endpoints.products, {
    params: {
      limit: 1000000,
      offset: 0,
    },
  });
  const data = res?.data;
  return data?.data || [];
};

const createProduct = async (body) => {
  try {
    const res = await api.post(Endpoints.products, body);
    return res.data;
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(
        `Invalid ${error.response.data?.map((e) => e.field).join(", ")}`
      );
    }
  }
};

const updateProduct = async (data) => {
  try {
    const productId = data.id;
    if (!productId) {
      throw new Error("Invalid product");
    }
    const rowData = { ...data };
    delete rowData.id;

    const res = await api.put(`${Endpoints.products}/${productId}`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

const createProducts = async (data) => {
  try {
    const res = await api.post(`${Endpoints.products}/many`, {
      data: data,
    });
    return res.data;
  } catch (error) {
    throw new Error(ErrorUtils.parseError(error));
  }
};

const deleteProduct = async (productId) => {
  if (!productId) return;;
  const res = await api.delete(`${Endpoints.products}/${productId}`);
  return res.data;
};

const generateExcelData = (products, accountType) => {
  const excelMapping = getExcelMapping(accountType);
  const excelData = products.map((p) => {
    const excelProduct = {};
    Object.keys(excelMapping).forEach((key) => {
      if (p[key]) {
        excelProduct[excelMapping[key]] = p[key];
      } else {
        excelProduct[excelMapping[key]] = "";
      }
    });
    return excelProduct;
  });
  return excelData;
};

const generateDummyProductsForExcel = (accountType) => {
  const excelMapping = getExcelMapping(accountType);
  const excelProduct = {};
  Object.keys(excelMapping).forEach((key) => {
    excelProduct[excelMapping[key]] = "";
  });
  return [excelProduct];
};

const ProductActions = {
  getProducts,
  getProductsForExcel,
  createProduct,
  updateProduct,
  createProducts,
  deleteProduct,
  generateExcelData,
  generateDummyProductsForExcel,
};
export default ProductActions;
