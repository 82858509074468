import AccountType from "./account_type";
import { FreightOptionLabels } from "./proforma_invoice";
import { QuotationStates } from "./quotation_states";
import Roles from "./roles";

export const PRODUCT_UNITS = {
    'KG': 'KG',
    'GM': 'GM',
    'MEGA': 'M'
}

export const PRODUCT_GRADES = {
    'IP': 'IP',
    'BP': 'BP',
    'USP': 'USP',
    'EP': 'EP',
}

export const PRODUCT_DELIVERY = {
    'PROMPT': 'Prompt',
    '30_DAYS': '30 Days',
    '60_DAYS': '60 Days',
    '90_DAYS': '90 Days',
}

const setColumnLabel = (headers, key, newLabel) => {
    const index = headers.findIndex((h) => h.key === key);
    if (index !== -1) {
        headers[index].label = newLabel;
    }
};

const setColumnRequired = (headers, key, value) => {
    const index = headers.findIndex((h) => h.key === key);
    if (index !== -1) {
        headers[index].required = value;
    }
};

const ProductUtils = {
    getHeaders(headers = [], user, quotationState) {
        const accountType = user.account_type;
        const role = user['role'];
        if (accountType === AccountType.DISTRIBUTOR) {
            const excludeKeys = ['unit', 'grade', 'delivery', 'freight', 'freight_amount'];
            const _headers = headers.filter((h) => !excludeKeys.includes(h.key));
            return _headers;
        }
        if (accountType === AccountType.MANUFACTURER) {
            const excludeKeys = ["packing", 'packing_type', 'percentage', 'price_per_pack', 'price_per_unit_currency', 'total_currency'];
            if (quotationState === QuotationStates.PENDING_APPROVAL && role !== Roles.ADMIN) {
                excludeKeys.push('price_per_unit');
                excludeKeys.push('total');
            }
            const _headers = headers.filter((h) => !excludeKeys.includes(h.key));
            setColumnLabel(_headers, "moq", "QUANTITY");
            setColumnLabel(_headers, "price_per_unit", "PRICE");
            setColumnRequired(_headers, 'price_per_unit', false);
            setColumnRequired(_headers, 'moq', false);
            return _headers;
        }
    },
    getProductUnitsOptions() {
        return Object.keys(PRODUCT_UNITS).map(item => ({
            value: PRODUCT_UNITS[item],
            label: PRODUCT_UNITS[item]
        }))
    },
    getProductGradeOptions() {
        return Object.keys(PRODUCT_GRADES).map(item => ({
            value: PRODUCT_GRADES[item],
            label: PRODUCT_GRADES[item]
        }))
    },
    getProductDeliveryOptions() {
        return Object.keys(PRODUCT_DELIVERY).map(item => ({
            value: PRODUCT_DELIVERY[item],
            label: PRODUCT_DELIVERY[item]
        }))
    },
    getProductFreightOptions() {
        return Object.keys(FreightOptionLabels).map(item => ({
            value: item,
            label: FreightOptionLabels[item]
        }))
    }
}

export default ProductUtils
