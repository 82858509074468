import * as React from "react";


import { fontWeights } from "styles/fonts";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import PurchaseOrderActions from "actions/purchase_order";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import DateTimeUtils from "utils/date_time";
import ErrorUtils from "utils/error";
import TextArea from "./TextArea";
import TextInput from "./TextInput";

export default function PODetailsDialog({ open, onClose, onSuccess, data }) {

  const snackbar = useSnackbar();

  const [error, setError] = React.useState(null);

  // To
  const [toName, setToName] = React.useState("");
  const [toAddress, setToAddress] = React.useState("")
  const [toGST, setToGST] = React.useState("");
  const [toTel, setToTel] = React.useState("");

  //PO Details
  const [poNo, setPONo] = React.useState("");
  const [poDate, setPODate] = React.useState(DateTimeUtils.getCurrentDate());
  const [brokerName, setBrokerName] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const [freight, setFreight] = React.useState("");
  const [insurance, setInsurance] = React.useState("");

  // Billing Details
  const [billTo, setBillTo] = React.useState("");
  const [billingAddress, setBillingAddress] = React.useState("");
  const [billingState, setBillingState] = React.useState("");
  const [billingGSTNo, setBillingGSTNo] = React.useState("");

  // Shipping Details
  const [shipTo, setShipTo] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [shippingState, setShippingState] = React.useState("");
  const [shippingGSTNo, setShippingGSTNo] = React.useState("");

  // Other Details
  const [remark, setRemark] = React.useState("");
  const [cartonSpecs, setCartonSpecs] = React.useState("");
  const [taxType, setTaxType] = React.useState("");
  const [taxPercentage, setTaxPercentage] = React.useState("");

  // Signatory
  const [signatoryName, setSignatoryName] = React.useState("");

  React.useEffect(() => {
    if (open) {
      const _storedSignatoryName = localStorage.getItem("signatory_name")
      if (_storedSignatoryName) {
        setSignatoryName(_storedSignatoryName)
      }
    }
  }, [open])


  const reset = () => {
    setToName("")
    setToAddress("")
    setToGST("")
    setToTel("")

    setPONo("")
    setPODate(DateTimeUtils.getCurrentDate())
    setBrokerName("")
    setDelivery("")
    setFreight("")
    setInsurance("")

    setBillTo("")
    setBillingAddress("")
    setBillingState("")
    setBillingGSTNo("")

    setShipTo("")
    setShippingAddress("")
    setShippingState("")
    setShippingGSTNo("")

    setRemark("")
    setCartonSpecs("")
    setTaxType("")
    setTaxPercentage("")

    setSignatoryName("")

    onClose();
  }

  const mSavePODetails = useMutation(PurchaseOrderActions.savePODetails, {
    onSuccess: () => {
      snackbar.enqueueSnackbar("PO details saved successfully", {
        variant: "success",
      });
      localStorage.setItem("signatory_name", signatoryName);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
  });

  const { data: poDetails, refetch: fetchPODetails } = useQuery({
    queryKey: ["get-po-details", data?.quotationId],
    queryFn: PurchaseOrderActions.getPODetails,
    enabled: false,
  })

  const mGenerateDoc = useMutation({
    mutationKey: "generate-po-doc",
    mutationFn: PurchaseOrderActions.generatePODoc,
    onError: () => {
      snackbar.enqueueSnackbar(
        "Failed to generate document. Please try again later",
        {
          variant: "error",
        }
      );
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Document generated", {
        variant: "success",
      });
    },
  });

  const { refetch: fetchDocUrl, isLoading: fetchDocUrlLoading } = useQuery({
    queryKey: ["get-po-doc", data?.quotationId],
    queryFn: PurchaseOrderActions.getPODocUrl,
    enabled: false,
    onError: (error) => {
      snackbar.enqueueSnackbar(
        ErrorUtils.parseError(error),
        {
          variant: "error",
        }
      );
    },
  });

  React.useEffect(() => {
    if (open && data?.quotationId) {
      fetchPODetails()
    }
  }, [data?.quotationId, fetchPODetails, open])

  React.useEffect(() => {
    if (poDetails && open) {
      if (poDetails?.to_name) setToName(poDetails.to_name)
      if (poDetails?.to_address) setToAddress(poDetails.to_address)
      if (poDetails?.to_gst) setToGST(poDetails.to_gst)
      if (poDetails?.to_tel) setToTel(poDetails.to_tel)
      if (poDetails?.po_no) setPONo(poDetails.po_no)
      if (poDetails?.po_date) setPODate(poDetails.po_date)
      if (poDetails?.broker_name) setBrokerName(poDetails.broker_name)
      if (poDetails?.delivery) setDelivery(poDetails.delivery)
      if (poDetails?.freight) setFreight(poDetails.freight)
      if (poDetails?.insurance) setInsurance(poDetails.insurance)
      if (poDetails?.bill_to) setBillTo(poDetails.bill_to)
      if (poDetails?.billing_address) setBillingAddress(poDetails.billing_address)
      if (poDetails?.billing_state) setBillingState(poDetails.billing_state)
      if (poDetails?.billing_gst_no) setBillingGSTNo(poDetails.billing_gst_no)
      if (poDetails?.ship_to) setShipTo(poDetails.ship_to)
      if (poDetails?.shipping_address) setShippingAddress(poDetails.shipping_address)
      if (poDetails?.shipping_state) setShippingState(poDetails.shipping_state)
      if (poDetails?.shipping_gst_no) setShippingGSTNo(poDetails.shipping_gst_no)
      if (poDetails?.remark) setRemark(poDetails.remark)
      if (poDetails?.carton_specs) setCartonSpecs(poDetails.carton_specs)
      if (poDetails?.tax_type) setTaxType(poDetails.tax_type)
      if (poDetails?.tax_percentage) setTaxPercentage(poDetails.tax_percentage)
      if (poDetails?.signatory_name) setSignatoryName(poDetails.signatory_name)
    }
  }, [poDetails, open])

  const validateParams = () => {
    const _error = {};

    if (!toName) _error.toName = true
    if (!toAddress) _error.toAddress = true
    if (!poDate) _error.poDate = true
    if (!delivery) _error.delivery = true
    if (!freight) _error.freight = true
    if (!insurance) _error.insurance = true
    if (!billTo) _error.billTo = true
    if (!billingAddress) _error.billingAddress = true
    if (!billingState) _error.billingState = true
    if (!billingGSTNo) _error.billingGSTNo = true
    if (!shipTo) _error.shipTo = true
    if (!shippingAddress) _error.shippingAddress = true
    if (!shippingState) _error.shippingState = true
    if (!shippingGSTNo) _error.shippingGSTNo = true
    if (!remark) _error.remark = true
    if (!cartonSpecs) _error.cartonSpecs = true
    if (!taxType) _error.taxType = true
    if (!taxPercentage) _error.taxPercentage = true
    if (!signatoryName) _error.signatoryName = true;

    setError(_error);

    if (Object.keys(_error).length > 0) {
      snackbar.enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
    }

    return Object.keys(_error).length === 0;
  }

  const onNext = async () => {

    if (!validateParams()) return;

    await mSavePODetails.mutateAsync({
      quotationId: data?.quotationId,
      data: {
        to_name: toName,
        to_address: toAddress,
        to_gst: toGST,
        to_tel: toTel,
        po_date: poDate,
        broker_name: brokerName,
        delivery: delivery,
        freight: freight,
        insurance: insurance,
        bill_to: billTo,
        billing_address: billingAddress,
        billing_state: billingState,
        billing_gst_no: billingGSTNo,
        ship_to: shipTo,
        shipping_address: shippingAddress,
        shipping_state: shippingState,
        shipping_gst_no: shippingGSTNo,
        remark: remark,
        carton_specs: cartonSpecs,
        tax_type: taxType,
        tax_percentage: taxPercentage,
        signatory_name: signatoryName,
      }
    })

    // API call to generate document
    const url = await generateNewDoc();
    window.open(url, "_blank");

    reset();

    if (onSuccess)
      onSuccess();

  }
  const generateNewDoc = async () => {
    snackbar.enqueueSnackbar("Generating purchase order document. Please wait...", {
      variant: "info",
    });

    const { success } = await mGenerateDoc.mutateAsync({ quotationId: data.quotationId });

    if (!success) {
      snackbar.enqueueSnackbar(
        "Could not generate document. Please try again later.",
        {
          variant: "error",
        }
      );
      return;
    }

    const res = await fetchDocUrl();
    const url = res.data.data;
    return url;
  };

  if (!data?.quotationId || !data?.organizationId) return null;

  const buttonsDisabled = mSavePODetails.isLoading || mGenerateDoc.isLoading || fetchDocUrlLoading;

  const title = 'Purchase Order Details ' + (poNo ? `- ${poNo}` : '');
  return (
    <Dialog open={open} maxWidth='md' sx={{ overflowY: "scroll", maxHeight: '100%' }} keepMounted={false}>
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in following required details for purchase order.
        </DialogContentText>
        <Grid container spacing={3} mt={1} mb={2}>
          <Grid item xs={6}>
            <Stack spacing={2} display={'block'}>
              <Typography color={'GrayText'} variant="button" fontWeight={600}>TO</Typography>
              <TextInput
                disabled={buttonsDisabled}
                fullWidth
                error={error?.toName}
                required={true}
                label={"Name"}
                value={toName}
                onChange={(e) => setToName(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>

              <TextArea
                disabled={buttonsDisabled}
                error={error?.toAddress}
                required={true}
                placeholder="Address*"
                value={toAddress}
                onChange={(e) => setToAddress(e.target.value)}
                rows={3}
                onFocus={() => setError(null)}
              ></TextArea>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ pt: 5 }}></Box>
            <Stack spacing={2} display={'block'}>
              <TextInput
                disabled={buttonsDisabled}
                fullWidth
                error={error?.toGST}
                required={false}
                label={"GST"}
                value={toGST}
                onChange={(e) => setToGST(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>

              <TextInput
                disabled={buttonsDisabled}
                fullWidth
                error={error?.toTel}
                required={false}
                label={"Tel"}
                value={toTel}
                onChange={(e) => setToTel(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Stack>
          </Grid>
        </Grid>

        <Divider />

        {/* PO Details */}
        <Grid container spacing={3} mt={0} mb={3}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>PO DETAILS</Typography>
          </Grid>

          {/* <Grid item xs={6}>
            <TextInput
              disabled={true}
              fullWidth
              error={error?.poNo}
              required={true}
              label={"P.O. NO."}
              value={poNo}
              onChange={(e) => setPONo(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid> */}
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.poDate}
              required={true}
              type="date"
              label={"P.O. Date"}
              value={poDate}
              onChange={(e) => setPODate(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.brokerName}
              required={false}
              label={"Broker Name"}
              value={brokerName}
              onChange={(e) => setBrokerName(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.delivery}
              required={true}
              label={"Payment Delivery"}
              value={delivery}
              onChange={(e) => setDelivery(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.insurance}
              required={true}
              label={"Insurance"}
              value={insurance}
              onChange={(e) => setInsurance(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.freight}
              required={true}
              label={"Freight"}
              value={freight}
              onChange={(e) => setFreight(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
        </Grid>

        <Divider />

        {/* Billing Details */}
        <Grid container spacing={3} mt={0} mb={3}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>BILLING DETAILS</Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.billTo}
                required={true}
                label={"Bill To"}
                value={billTo}
                onChange={(e) => setBillTo(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>

            <Grid item xs={12} mt={3}>
              <TextArea
                disabled={buttonsDisabled}
                error={error?.billingAddress}
                required={true}
                placeholder="Address*"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
                rows={3}
                onFocus={() => setError(null)}
              ></TextArea>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.billingState}
                required={true}
                label={"State"}
                value={billingState}
                onChange={(e) => setBillingState(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>
            <Grid item xs={12} mt={3}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.billingGSTNo}
                required={true}
                label={"GST No."}
                value={billingGSTNo}
                onChange={(e) => setBillingGSTNo(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        {/* Shipping Details */}
        <Grid container spacing={3} mt={0} mb={3}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>SHIPPING DETAILS</Typography>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.shipTo}
                required={true}
                label={"Ship To"}
                value={shipTo}
                onChange={(e) => setShipTo(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>

            <Grid item xs={12} mt={3}>
              <TextArea
                disabled={buttonsDisabled}
                error={error?.shippingAddress}
                required={true}
                placeholder="Address*"
                value={shippingAddress}
                onChange={(e) => setShippingAddress(e.target.value)}
                rows={3}
                onFocus={() => setError(null)}
              ></TextArea>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.shippingState}
                required={true}
                label={"State"}
                value={shippingState}
                onChange={(e) => setShippingState(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>
            <Grid item xs={12} mt={3}>
              <TextInput
                fullWidth
                disabled={buttonsDisabled}
                error={error?.shippingGSTNo}
                required={true}
                label={"GST No."}
                value={shippingGSTNo}
                onChange={(e) => setShippingGSTNo(e.target.value)}
                onFocus={() => setError(null)}
              ></TextInput>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        {/* Other Details */}
        <Grid container spacing={3} mt={0} mb={3}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>OTHER DETAILS</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.remark}
              required={true}
              label={"Remark"}
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.cartonSpecs}
              required={true}
              label={"Carton Specification"}
              value={cartonSpecs}
              onChange={(e) => setCartonSpecs(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.taxType}
              required={true}
              label={"Tax Type (IGST/GST/CGST)"}
              value={taxType}
              onChange={(e) => setTaxType(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.taxPercentage}
              required={true}
              label={"Tax Percentage(%)"}
              value={taxPercentage}
              onChange={(e) => setTaxPercentage(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
        </Grid>

        {/* Signatory */}
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12}>
            <Typography color={'GrayText'} variant="button" fontWeight={600}>SIGNATORY</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextInput
              disabled={buttonsDisabled}
              fullWidth
              error={error?.signatoryName}
              required={true}
              label={"Signatory Name"}
              value={signatoryName}
              onChange={(e) => setSignatoryName(e.target.value)}
              onFocus={() => setError(null)}
            ></TextInput>
          </Grid>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Button disabled={buttonsDisabled} onClick={reset}>Cancel</Button>

        <LoadingButton
          variant="text"
          spinnerSize={18}
          onClick={onNext}
          disabled={buttonsDisabled}
        >
          Generate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}



