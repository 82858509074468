import _ from "lodash";

const SortUtils = {
  sortByDate: (rows, key, order) => {
    return _.orderBy(rows, [(row) => new Date(row[key])], [order]);
  },
  sortByString: (rows, key, order) => {
    return _.orderBy(rows, [key], [order]);
  },
};

export default SortUtils;
