import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ProductActions from "actions/product";
import UserContext from "context/UserContext";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import { fontWeights } from "styles/fonts";
import ErrorUtils from "utils/error";
import Excel from "utils/excel";
import Icons from "utils/icons";
import {
  getExcelMapping, getLabelMapping
} from "utils/products_mapping";
import LoadingButton from "./LoadingButton";
import TextInput from "./TextInput";

export default function ExcelUploadDialog({ open, onClose, onSuccess, data }) {
  const snackbar = useSnackbar();
  const { user } = useContext(UserContext);

  const defaultExcelMapping = getExcelMapping(user.account_type);

  const [mapping, setMapping] = React.useState(defaultExcelMapping);

  const onChange = (key, value) => {
    setMapping({
      ...mapping,
      [key]: value,
    });
  };

  const mCreateProducts = useMutation({
    mutationFn: ProductActions.createProducts,
    onSuccess: (data) => {
      snackbar.enqueueSnackbar(data?.message, {
        variant: "success",
      });
      onSuccess(data?.success);
    },
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const onFileSelect = async (e) => {
    try {
      const excelData = await Excel.parseToJSON(e.target.files[0], mapping);
      mCreateProducts.mutate(excelData);
    } catch (error) {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    }
  };

  const labelMapping = getLabelMapping(user.account_type)

  return (
    <Dialog open={open} aria-labelledby="column-mapping">
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        Add Excel Column Mapping
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2} mt={3}>
          {Object.keys(labelMapping).map((fieldKey) => (
            <MappingRow
              key={fieldKey}
              keyName={fieldKey}
              label={labelMapping[fieldKey]}
              value={mapping[fieldKey] || ""}
              onChange={onChange}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          disabled={mCreateProducts.isLoading}
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <LoadingButton
          sx={{
            minWidth: 150,
          }}
          variant="text"
          loading={mCreateProducts.isLoading}
          spinnerSize={16}
          startIcon={<Icons.Upload />}
          component="label"
        >
          {mCreateProducts.isLoading ? "Uploading..." : "Upload File"}
          <input
            onChange={onFileSelect}
            hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            multiple={false}
            type="file"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function MappingRow({ keyName, label, value, onChange }) {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      spacing={3}
      justifyContent="space-between"
    >
      <Typography variant="body1">{label}</Typography>
      <TextInput
        style={{
          width: 300,
        }}
        fullWidth
        value={value}
        onChange={(e) => onChange(keyName, e.target.value)}
      ></TextInput>
    </Stack>
  );
}
