import {
    Box, Divider,
    Grid, Typography
} from "@mui/material";
import { ClientStatus, ClientStatusNames } from "utils/client_status";
import { QuotationStateNames } from "utils/quotation_states";

export const ClientDetails = ({ data }) => {
    const getStatusColor = () => {
        let color = 'pending';

        if (data?.client_status === ClientStatus.ACCEPTED) {
            color = 'accepted';
        } else if (data?.client_status === ClientStatus.REJECTED) {
            color = 'rejected';
        }
        return color + '.main';
    };

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Divider />
            <Grid container spacing={1.5} mt={0.5}>
                <Grid item xs={12} md={6} lg={3}>
                    <Box display={"inline-block"}>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Name:`}
                        </Typography>
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {data?.client_name || ""}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Box display={"inline-block"}>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Email:`}
                        </Typography>
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {data?.client_email || ""}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Box display={"inline-block"}>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Phone:`}
                        </Typography>
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {data?.client_phone || "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Box>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Company:`}
                        </Typography>
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {data?.client_company || ""}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Box>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Country:`}
                        </Typography>
                        <Typography variant="body2" component={"span"} fontWeight="400">
                            {data?.client_country || ""}
                        </Typography>
                    </Box>
                </Grid>
                {data?.sent_date && (
                    <Grid item xs={12} md={6} lg={3}>
                        <Box>
                            <Typography
                                variant="body2"
                                component={"span"}
                                fontWeight="600"
                                sx={{ pr: 1 }}
                            >
                                {`First Sent At:`}
                            </Typography>
                            <Typography variant="body2" component={"span"} fontWeight="400">
                                {data?.sent_date || ""}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                {data?.followup_date && (
                    <Grid item xs={12} md={6} lg={3}>
                        <Box>
                            <Typography
                                variant="body2"
                                component={"span"}
                                fontWeight="600"
                                sx={{ pr: 1 }}
                            >
                                {`Follow Up At:`}
                            </Typography>
                            <Typography variant="body2" component={"span"} fontWeight="400">
                                {data?.followup_date || ""}
                            </Typography>
                        </Box>
                    </Grid>
                )}


                <Grid item xs={12} md={6} lg={3}>
                    <Box>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Status:`}
                        </Typography>

                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="400"
                            color={getStatusColor()}
                        >
                            {(ClientStatusNames[data.client_status] || "")}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Box>
                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="600"
                            sx={{ pr: 1 }}
                        >
                            {`Current State:`}
                        </Typography>

                        <Typography
                            variant="body2"
                            component={"span"}
                            fontWeight="400"
                        >
                            {(QuotationStateNames[data.state] || "")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
