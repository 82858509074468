import { FormHelperText, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { fonts } from "styles/fonts";

export default function Dropdown({
  labelKey,
  valueKey,
  value,
  onChange,
  options = [],
  disabled,
  sx = {},
  size = "small",
  variant = "standard",
  label,
  showNone = true,
  required = false,
  error = false,
  helperText = ''
}) {

  return (
    <FormControl
      error={error}
      size={size}
      sx={{
        "& .MuiInputBase-root": {
          ...fonts.regular,
        },
        mx: 1,
        ...sx,
      }}
      disabled={disabled}
      required={required}
    >
      {label && (
        <InputLabel
          sx={{
            ...fonts.regular,
            paddingTop: "1px",
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        label={label}
        variant={variant}
        value={value}
        onChange={onChange}
        {...(variant === "standard" && { disableUnderline: true })}
        MenuProps={{
          MenuListProps: {
            style: {
              overflowY: 'scroll',
              maxHeight: 300,
              scrollbarWidth: 'none'
            }
          },
          PaperProps: {
            style: {
              backgroundColor: '#FFF'
            }
          }
        }}
        SelectDisplayProps={{
          style: {
            borderRadius: 5,
            ...(variant === "standard" && {
              paddingLeft: 8,
              paddingBottom: 0,
            }),
            ...(disabled && { backgroundColor: "#eee" }),
          },
        }}
      >
        {showNone && (
          <MenuItem
            dense
            value=""
            style={{
              ...fonts.regular,
            }}
          >
            <em>None</em>
          </MenuItem>
        )}
        {options.map((o) => (
          <MenuItem
            dense
            style={{
              ...fonts.regular,
            }}
            key={o[valueKey || "value"]}
            value={o[valueKey] || "value"}
          >
            {o[labelKey || "label"]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
