import { IconButton, Tooltip } from "@mui/material";
import { ActionTypes } from "utils/action_types";
import Icons from "utils/icons";
import { QuotationStates } from "utils/quotation_states";
import { ClientStatus } from "utils/client_status";
import LoadingButton from "./LoadingButton";
import useLayout from "hooks/useLayout";

export const ClientStatusButtons = ({ quotationDetails, onClientStatusChange }) => {
    const { isMobile } = useLayout();
    return (
        <>
            {quotationDetails?.state !== QuotationStates.PENDING_APPROVAL && (!quotationDetails?.client_status || quotationDetails.client_status === ClientStatus.PENDING) &&
                (
                    <>
                        {isMobile ?
                            <MobileButtons
                                onAction={onClientStatusChange}
                            />
                            :
                            <DesktopButtons
                                onAction={onClientStatusChange}
                            />
                        }
                    </>
                )}
        </>
    );
};

function MobileButtons({
    onAction,
}) {
    return (
        <>
            <LoadingButton
                loading={false}
                color="accepted"
                variant="text"
                onClick={() => onAction(ClientStatus.ACCEPTED)}
                startIcon={<Icons.Accepted />}
            >
                {ActionTypes.ACCEPTED}
            </LoadingButton>
            <LoadingButton
                color="rejected"
                loading={false}
                variant="text"
                onClick={() => onAction(ClientStatus.REJECTED)}
                startIcon={<Icons.Rejected />}
            >
                {ActionTypes.REJECTED}
            </LoadingButton>
        </>
    )
}


function DesktopButtons({ onAction }) {
    return (
        <>
            <IconButton size="small" color="accepted" onClick={() => onAction(ClientStatus.ACCEPTED)}>
                <Tooltip title={ActionTypes.ACCEPTED}>
                    <Icons.Accepted></Icons.Accepted>
                </Tooltip>
            </IconButton>
            <IconButton size="small" color="rejected" onClick={() => onAction(ClientStatus.REJECTED)}>
                <Tooltip title={ActionTypes.REJECTED}>
                    <Icons.Rejected></Icons.Rejected>
                </Tooltip>
            </IconButton>
        </>
    )
}
