const evaluateMul = (expression) => {
  try {
    const numbers = String(expression).split("x");
    return numbers.reduce((acc, val) => acc * parseInt(val, 10), 1);
  } catch (error) {
    console.log(error?.message);
    return undefined;
  }
};

const isNonEmpty = (value) => {
  return ![undefined, null, ''].includes(value)
}

const MathUtils = {
  evaluateMul,
  isNonEmpty
};

export default MathUtils;
