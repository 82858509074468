import AccountType from "./account_type";

export const PRODUCTS_EXCEL_MAPPING_DISTRIBUTOR = {
  name: "PRODUCT NAME",
  packing: "PACKING",
  packing_type: "PACKING TYPE",
  price_per_unit: "PRICE PER UNIT",
  manufacturer: "MANUFACTURER",
  moq: "MOQ",
};

export const PRODUCTS_LABEL_MAPPING_DISTRIBUTOR = {
  name: "Product Name",
  packing: "Packing",
  packing_type: "Packing Type",
  price_per_unit: "Price Per Unit",
  manufacturer: "Manufacturer",
  moq: "MOQ",
};


export const PRODUCTS_EXCEL_MAPPING_MANUFACTURER = {
  name: "PRODUCT NAME",
  unit: "UNIT",
  grade: "GRADE",
  price_per_unit: "PRICE PER UNIT",
  manufacturer: "MANUFACTURER",
  moq: "QUANTITY",
};

export const PRODUCTS_LABEL_MAPPING_MANUFACTURER = {
  name: "Product Name",
  unit: "Unit",
  grade: "Grade",
  price_per_unit: "Price Per Unit",
  manufacturer: "Manufacturer",
  moq: "Quantity",
};

export const getExcelMapping = (accountType) => {
  if (accountType === AccountType.MANUFACTURER) {
    return PRODUCTS_EXCEL_MAPPING_MANUFACTURER;
  }
  return PRODUCTS_EXCEL_MAPPING_DISTRIBUTOR;

}

export const getLabelMapping = (accountType) => {
  if (accountType === AccountType.MANUFACTURER) {
    return PRODUCTS_LABEL_MAPPING_MANUFACTURER
  }

  return PRODUCTS_LABEL_MAPPING_DISTRIBUTOR
}
