import {
  Add,
  AddCircleOutline,
  AdminPanelSettingsOutlined,
  ArticleOutlined,
  CancelOutlined,
  CategoryOutlined,
  CheckCircleOutline,
  CheckOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  Close,
  CurrencyExchangeOutlined,
  DeleteOutline,
  EditOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  FileDownload,
  FileUpload,
  ForwardToInboxOutlined,
  GroupsOutlined,
  HistoryOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu,
  Person2Outlined,
  PictureAsPdfOutlined,
  ScheduleSendOutlined,
  SearchOutlined,
  ThumbUpAltOutlined,
  ViewCarouselOutlined,
  Logout,
  VisibilityOutlined,
  Check,
  Tune,
  VerifiedUserOutlined,
  RemoveModeratorOutlined,
  HighlightOffOutlined,
  Reorder,
  ViewStream,
  TableChartOutlined,
  PostAddOutlined,
  NoteAltOutlined,
  ManageAccounts,
  FileUploadOutlined,
  BlockOutlined,
  NotificationsNone,
  AlarmOutlined,
  AlarmOffOutlined,
  InventoryOutlined,
  LockOpen,
  AnalyticsOutlined,
  MoreVert
} from "@mui/icons-material";

const Icons = {
  Edit: EditOutlined,
  Save: CheckOutlined,
  Quotation: ArticleOutlined,
  Upload: FileUpload,
  Download: FileDownload,
  Delete: DeleteOutline,
  Cancel: Close,
  ChevronRight: ChevronRightOutlined,
  Add: AddCircleOutline,
  Expand: ExpandMoreOutlined,
  Collapse: ExpandLessOutlined,
  Users: Person2Outlined,
  Master: AdminPanelSettingsOutlined,
  Clients: GroupsOutlined,
  Products: CategoryOutlined,
  Search: SearchOutlined,
  Back: ChevronLeft,
  Preview: VisibilityOutlined,
  SendMail: ForwardToInboxOutlined,
  History: HistoryOutlined,
  GeneratePdf: PictureAsPdfOutlined,
  Currency: CurrencyExchangeOutlined,
  Menu: Menu,
  Accepted: CheckCircleOutline,
  Rejected: CancelOutlined,
  Approval: ScheduleSendOutlined,
  Approve: ThumbUpAltOutlined,
  Organizations: ViewCarouselOutlined,
  SortAsc: KeyboardArrowUp,
  SortDesc: KeyboardArrowDown,
  Logout: Logout,
  Plus: Add,
  Check: Check,
  Filters: Tune,
  Active: VerifiedUserOutlined,
  Inactive: RemoveModeratorOutlined,
  Close: HighlightOffOutlined,
  List: Reorder,
  Stream: ViewStream,
  Excel: TableChartOutlined,
  GenerateDocument: PostAddOutlined,
  EditProfInvoice: NoteAltOutlined,
  ManageUser: ManageAccounts,
  ImageUpload: FileUploadOutlined,
  Discard: BlockOutlined,
  Notifications: NotificationsNone,
  Reminder: AlarmOutlined,
  ReminderOff: AlarmOffOutlined,
  PurchaseOrder: InventoryOutlined,
  ChangePassword: LockOpen,
  Analytics: AnalyticsOutlined,
  DotsMenu: MoreVert
};

export default Icons;
