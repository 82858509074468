import Icons from "./icons";

import Clients from "screens/Clients";
import Currency from "screens/Currency";
import Home from "screens/Home";
import OrganizationDetails from "screens/OrganizationDetails";
import Organizations from "screens/Organizations";
import Products from "screens/Products";
import Quotation from "screens/Quotation";
import QuotationDetails from "screens/QuotationDetailsv2";
import Users from "screens/Users";
import Roles from "./roles";
import PurchaseOrders from "screens/PurchaseOrders";
import PurchaseOrderDetails from "screens/PurchaseOrderDetails";
import Analytics from "screens/Analytics";
import AccountType from "./account_type";

export const DRAWER_WIDTH = 240;

export const DRAWER_ITEMS = [
  {
    label: "Quotations",
    icon: Icons.Quotation,
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Purchase Orders",
    icon: Icons.Quotation,
    accessRoles: [Roles.ADMIN, Roles.MEMBER_PLUS, Roles.PURCHASING],
    mobile: false,
    accountTypes: [AccountType.DISTRIBUTOR]
  },
  {
    label: "Analytics",
    icon: Icons.Analytics,
    accessRoles: [Roles.ADMIN],
    mobile: false,
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    label: "Master",
    icon: Icons.Master,
    accessRoles: [Roles.ADMIN, Roles.PURCHASING, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    children: [
      {
        label: "Users",
        icon: Icons.Users,
        accessRoles: [Roles.ADMIN],
        mobile: false,
        accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
      },
      {
        label: "Clients",
        icon: Icons.Clients,
        accessRoles: [Roles.ADMIN],
        mobile: false,
        accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
      },
      {
        label: "Products",
        icon: Icons.Products,
        accessRoles: [Roles.ADMIN, Roles.PURCHASING, Roles.MEMBER_PLUS],
        accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
      },
      {
        label: "Currency",
        icon: Icons.Currency,
        accessRoles: [Roles.ADMIN],
        mobile: false,
        accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
      },
      {
        label: "Organizations",
        icon: Icons.Organizations,
        accessRoles: [Roles.ADMIN],
        mobile: false,
        accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
      },
    ],
  },
];

export const routes = [
  {
    path: "/quotations",
    name: "Quotations",
    component: Quotation,
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    path: "/purchase_orders",
    name: "Purchase Orders",
    component: PurchaseOrders,
    accessRoles: [Roles.ADMIN, Roles.MEMBER_PLUS, Roles.PURCHASING],
    accountTypes: [AccountType.DISTRIBUTOR],
    data: {
      mobile: false
    }
  },
  {
    path: "/quotations/:quotationId",
    name: "Quotation Details",
    component: QuotationDetails,
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    path: "/purchase_orders/:quotationId",
    name: "Purchase Order Details",
    component: PurchaseOrderDetails,
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS, Roles.PURCHASING],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false
    }
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false
    }
  },
  {
    path: "/master/users/",
    name: "Users",
    component: Users,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/users/:username/quotations",
    name: "Users",
    component: Quotation,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/clients",
    name: "Clients",
    component: Clients,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/clients/:clientId/quotations",
    name: "Clients",
    component: Quotation,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/products",
    name: "Products",
    component: Products,
    accessRoles: [Roles.ADMIN, Roles.PURCHASING, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
  {
    path: "/master/currency",
    name: "Currency",
    component: Currency,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/organizations",
    name: "Organizations",
    component: Organizations,
    accessRoles: [Roles.ADMIN],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    data: {
      mobile: false,
    },
  },
  {
    path: "/master/organizations/:organizationId",
    name: "Organization Details",
    component: OrganizationDetails,
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER],
    accessRoles: [Roles.ADMIN],
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    accessRoles: [Roles.ADMIN, Roles.MEMBER, Roles.PURCHASING, Roles.MEMBER_PLUS],
    accountTypes: [AccountType.DISTRIBUTOR, AccountType.MANUFACTURER]
  },
];
