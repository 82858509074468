import { Box, Grid, Stack, Typography } from "@mui/material";
import ReminderActions from "actions/reminder";
import Dropdown from "components/Dropdown";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import ErrorUtils from "utils/error";

const REMINDER_DAYS_OPTIONS = Array.from({ length: 20 }).fill(0).map((n, i) => i + 1).map(n => ({
    label: n,
    value: n
}))

export const ReminderDetails = ({
    disabled,
    data
}) => {
    const snackbar = useSnackbar();

    const [reminderDays, setReminderDays] = useState("");

    const {
        data: quotationReminder,
        refetch: fetchQuotationReminder
    } = useQuery({
        queryKey: ["get-reminders", data?.quotationId],
        queryFn: ReminderActions.getReminder,
        enabled: !!data?.quotationId,
    });

    const mCreateReminder = useMutation({
        mutationFn: ReminderActions.createReminder,
        onError: (error) => {
            snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
                variant: 'error',
            })
        },
        onSuccess: () => {
            fetchQuotationReminder()

        }
    })

    const _onReminderDaysChange = async (e) => {
        const value = e.target.value;

        setReminderDays(value)
        await mCreateReminder.mutateAsync({
            quotation_id: data?.quotationId,
            client_name: data?.client_name,
            reminder_days: value
        })
        snackbar.enqueueSnackbar(`Reminder for ${value} days from now has been created!`, {
            variant: 'success',
        })

    };


    return (
        <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                    <Stack direction={"row"}>
                        <Typography variant="body2" component={"span"} fontWeight="600">
                            Set Reminder For (Days):
                        </Typography>

                        <Dropdown
                            sx={{
                                width: 50
                            }}
                            valueKey={'value'}
                            labelKey={'label'}
                            disabled={disabled}
                            onChange={_onReminderDaysChange}
                            value={reminderDays}
                            options={REMINDER_DAYS_OPTIONS}
                        ></Dropdown>
                    </Stack>
                </Grid>
                {!!quotationReminder &&
                    <Grid item xs={4} md={4} lg={3}>
                        <Box display={"inline-block"}>
                            <Typography
                                variant="body2"
                                component={"span"}
                                fontWeight="600"
                                sx={{ pr: 1 }}
                            >
                                {`Remind On:`}
                            </Typography>
                            <Typography variant="body2" component={"span"} fontWeight="400">
                                {quotationReminder.scheduled_at || ''}
                            </Typography>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    );
};
