import { IconButton, Stack, Tooltip } from "@mui/material";
import FilterChip from "components/FilterChip";
import Icons from "utils/icons";

const isActive = (filter, filters) => {
    return filters.find((f) => f.field === filter.field && Number(f.value) === Number(filter.value)) !== undefined;
}

export const AccessibleFilterChips = ({ filters = [], appliedFilters, onApply, onClearAll, disabled }) => {

    return (
        <Stack direction={'row'} spacing={2} flexWrap={'wrap'} mt={3} width={700}>
            {filters.map((filter, index) => {
                const active = isActive(filter, appliedFilters);
                return (<FilterChip key={index} disabled={disabled} color={filter.color} filter={filter} active={active} onClick={() => onApply(filter, active)} />);
            }

            )}
            {!!onClearAll && !disabled &&
                <IconButton size="small" onClick={onClearAll}>
                    <Tooltip title="Clear all filters">
                        <Icons.Cancel fontSize="small" />
                    </Tooltip>
                </IconButton>}
        </Stack>
    );
};
