import api from "network/api";
import Endpoints from "./endpoints";

const getQuotation = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  const pageType = queryKey[2];

  const headers = {};
  headers['Page-Type'] = pageType;

  const res = await api.get(`${Endpoints.quotations}/${quotationId}`, {
    headers: headers
  });

  return res.data;
};

const getQuotations = async ({ queryKey }) => {
  const role = queryKey[1];
  const limit = queryKey[2];
  const page = queryKey[3];
  const filters = queryKey[4];
  const groupBy = queryKey[5];
  const searchText = queryKey[6];
  const pageType = queryKey[7];

  if (!limit || !page || !pageType) return {
    rows: [],
    total: 0
  };
  if (!role) throw new Error("Invalid request");

  const params = {
    limit,
    offset: (+page - 1) * +limit
  };
  if (filters) {
    params.filters = JSON.stringify(filters);
  }
  if (groupBy) {
    params.groupBy = groupBy;
  }
  if (searchText) {
    params.search = searchText
    params.limit = 20
  };

  const headers = {};

  headers['Page-Type'] = pageType;

  const res = await api.post(`${Endpoints.quotations}/query`, params, {
    headers: headers
  });

  const rows = (res.data?.data || []).map((row) => {
    const actions = ["View"];
    return {
      ...row,
      actions: actions,
    };
  })

  return {
    rows,
    total: res.data?.total || 0
  };
};

const createQuotation = async (data) => {
  // trim strings from response object
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "string") {
      data[key] = data[key].trim();
    }
  });
  const res = await api.post(Endpoints.quotations, data);
  return res.data;
};

const getQuotationProducts = async ({ queryKey }) => {
  const quotationId = queryKey[1];

  const res = await api.get(`${Endpoints.quotations}/${quotationId}/products`);
  return res.data;
};

const deleteQuotationProducts = async ({ quotationId, ids }) => {
  try {
    if (!ids || !Array.isArray(ids)) throw new Error("Ids can not be empty!");

    await api.delete(`${Endpoints.quotations}/${quotationId}/products`, {
      data: {
        productIds: ids,
      },
    });

    return true;
  } catch (error) {
    throw error;
  }
};

const createQuotationProducts = async ({ quotationId, products }) => {
  try {
    const res = await api.post(
      `${Endpoints.quotations}/${quotationId}/products`,
      {
        products,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const updateQuotationProduct = async ({ quotationId, productId, data }) => {
  try {
    const res = await api.put(
      `${Endpoints.quotations}/${quotationId}/products/${productId}`,
      data
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

const getQuotationDocUrl = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  const url = await api.get(
    `${Endpoints.quotations}/${quotationId}/document-url`
  );
  return url;
};

const getQuotationDocxUrl = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  const url = await api.get(
    `${Endpoints.quotations}/${quotationId}/word-document-url`
  );
  return url;
};

const generateQuotationDoc = async ({ quotationId }) => {
  const res = await api.post(`${Endpoints.quotations}/${quotationId}/generate`);
  return res.data;
};

const generateQuotationDocx = async ({ quotationId }) => {
  const res = await api.post(`${Endpoints.quotations}/${quotationId}/generate-word-doc`);
  return res.data;
};

const updateQuotation = async ({ quotationId, data }) => {
  const res = await api.put(`${Endpoints.quotations}/${quotationId}`, data);
  return res.data;
};

const getQuotationHistory = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  const res = await api.get(`${Endpoints.quotationHistory}/${quotationId}`);
  return res.data;
};

const getQuotationHistoryDocUrl = async ({ queryKey }) => {
  const id = queryKey[1];
  if (!id) return;
  const res = await api.get(`${Endpoints.quotationHistory}/${id}/document`);
  return res.data;
};

const sendQuotation = async (quotationId) => {
  if (!quotationId) throw new Error("Quotation not found");
  const res = await api.post(`${Endpoints.quotations}/${quotationId}/send`);
  return res.data;
};

const getExcelData = async ({ queryKey }) => {
  const quotationId = queryKey[1];
  const res = await api.get(`${Endpoints.quotations}/${quotationId}/excel-data`)
  return { data: res.data?.data || [], currency: res.data?.currency };
}

const QuotationActions = {
  createQuotation,
  getQuotation,
  getQuotations,
  getQuotationProducts,
  createQuotationProducts,
  deleteQuotationProducts,
  updateQuotationProduct,
  getQuotationDocUrl,
  generateQuotationDoc,
  generateQuotationDocx,
  updateQuotation,
  getQuotationHistory,
  getQuotationHistoryDocUrl,
  sendQuotation,
  getExcelData,
  getQuotationDocxUrl
};
export default QuotationActions;
