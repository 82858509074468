import AccountType from "utils/account_type";
import { ActionTypes } from "utils/action_types";
import { InputTypes } from "utils/input_types";
import ProductUtils from "utils/product";
import { ProductsHelper } from "./products";
import Roles from "utils/roles";

export const QuotationDetailsHelper = {
    // ADD
    getProductEmptyObject: (user) => {
        const keys = QuotationDetailsHelper.getProductFormConfig(user).map(o => o.name);
        return keys.reduce((acc, key) => {
            acc[key] = ''
            return acc
        }, {})

    },
    getProductFormConfig: (user, type) => {
        if (user.account_type === AccountType.MANUFACTURER) {
            return QuotationDetailsHelper.getProductFormConfigForManufacturer(type)
        }
        if (user.account_type === AccountType.DISTRIBUTOR) {
            let config = QuotationDetailsHelper.getProductFormConfigForDistributor(type)
            if (user.role === Roles.MEMBER) {
                config = config.filter(c => c.name !== 'price_per_unit')
                config = config.filter(c => c.name !== 'percentage')
            }
            return config
        }
    },
    getProductFormConfigForDistributor(type) {
        let config = [
            ...ProductsHelper.getFormConfigForDistributor(),
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Percentage",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'percentage',
                validation: {
                    expression: /^-?\d*(\.\d+)?$/,
                    message: 'Percentage should be a valid number'
                },
                type: 'text'
            },
        ]
        if (type === ActionTypes.EDIT) {
            config[0].disabled = true;
        }
        if (type === ActionTypes.ADD_NEW) {
            config = config.filter(c => c.name !== 'percentage')
        }
        return config;
    },
    getProductFormConfigForManufacturer(type) {
        const config = [
            ...ProductsHelper.getFormConfigForManufacturer(),
            {
                inputType: InputTypes.DROPDOWN,
                label: "Freight",
                placeholder: "",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'freight',
                validation: {
                    expression: /^.{0,50}$/,
                    message: 'Please select freight'
                },
                type: 'text',
                showNone: false,
                options: ProductUtils.getProductFreightOptions()
            },
            {
                inputType: InputTypes.TEXT_INPUT,
                label: "Delivery",
                required: false,
                disabled: false,
                defaultValue: '',
                name: 'delivery',
                validation: {
                    expression: /^.{0,100}$/,
                    message: 'Invalid delivery'
                },
                type: 'text'
            },
        ]
        if (type === ActionTypes.EDIT) {
            config[0].disabled = true
        }
        return config;
    },
}
