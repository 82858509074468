import { debounce } from "lodash";
import '../styles/products.css';

import { Grid, Stack, Typography } from "@mui/material";

import DataTable from "components/DataTable";
import LoadingContext from "context/LoadingContext";

import ProductActions from "actions/product";

import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import CardList from "components/CardList";
import DynamicFormDialog from "components/DynamicFormDialog";
import ExcelUploadDialog from "components/ExcelUploadDialog";
import LoadingButton from "components/LoadingButton";
import ProductCard from "components/ProductCard";
import SearchInput from "components/SearchInput";
import UserContext from "context/UserContext";
import { ProductsHelper } from "helpers/products";
import { ActionTypes } from "utils/action_types";
import { DialogActionTypes } from "utils/dialog_action_types";
import ErrorUtils from "utils/error";
import Excel from "utils/excel";
import Icons from "utils/icons";
import ProductUtils, { PRODUCT_GRADES, PRODUCT_UNITS } from "utils/product";

const headers0 = [
  {
    key: "SR",
    label: "SR. NO",
    width: 60,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "name",
    label: "PRODUCT NAME",
    width: 200,
    align: "left",
    editable: true,
    required: true,
  },
  {
    key: "packing",
    label: "PACKING",
    width: 100,
    align: "center",
    editable: true,
    required: true,
    transform: (v) =>
      String(v["packing"])
        .split("x")
        .map((x) => x.trim())
        .join(" x "),
  },
  {
    key: "unit",
    label: "UNIT",
    width: 80,
    align: "center",
    dropdown: true,
    options: ProductUtils.getProductUnitsOptions(),
    defaultSelected: 0,
    showNone: false,
    defaultValue: PRODUCT_UNITS.KG,
  },
  {
    key: "grade",
    label: "GRADE",
    width: 80,
    align: "center",
    dropdown: true,
    options: ProductUtils.getProductGradeOptions(),
    defaultSelected: 0,
    showNone: false,
    defaultValue: PRODUCT_GRADES.IP,
  },
  {
    key: "packing_type",
    label: "PACKING TYPE",
    width: 100,
    align: "center",
    editable: true,
  },
  {
    key: "manufacturer",
    label: "MANUFACTURER",
    width: 100,
    align: "center",
    editable: true,
  },
  {
    key: "moq",
    label: "MOQ",
    width: 100,
    align: "center",
    editable: true,
    required: false,
    transform: (v) => (v.moq ? Number(v.moq).toLocaleString() : "-"),
  },
  {
    key: "price_per_unit",
    label: "PRICE PER UNIT (INR)",
    width: 100,
    align: "center",
    editable: true,
    required: true,
    type: "number",
    transform: (v) => Number(v.price_per_unit).toLocaleString(),
  },
  {
    key: "created_at",
    label: "CREATED DATE",
    width: 100,
    align: "center",
    dataType: "date",
  },
  {
    key: "updated_at",
    label: "UPDATED DATE",
    width: 100,
    align: "center",
    dataType: "date",
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 100,
    align: "center",
    items: {
      Edit: Icons.Edit,
      Delete: Icons.Delete,
    },
  },
];

export default function Products({ mobile }) {
  const snackbar = useSnackbar();
  const { user } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);

  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState(undefined);
  const [orderType, setOrderType] = useState(undefined);

  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);

  const { isLoading, refetch: fetchProducts } = useQuery({
    enabled: !searchText,
    queryKey: ["get-products", searchText, limit, offset, orderBy, orderType],
    queryFn: ProductActions.getProducts,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setProducts(data.data);
      setTotalProducts(data.total);
    },
  });

  const mCreateProduct = useMutation({
    mutationKey: "create-product",
    mutationFn: ProductActions.createProduct,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Added", {
        variant: "success",
      });
      fetchProducts();
    }
  });

  const mUpdateProduct = useMutation({
    mutationKey: "update-product",
    mutationFn: ProductActions.updateProduct,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Updated", {
        variant: "success",
      });
      fetchProducts();
    },
  });

  const mDeleteProduct = useMutation({
    mutationKey: "delete-product",
    mutationFn: ProductActions.deleteProduct,
    onError: (error) => {
      snackbar.enqueueSnackbar(ErrorUtils.parseError(error), {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Deleted successfully", {
        variant: "success",
      });
      fetchProducts();
    },
  });

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  const onAction = async (
    actionType,
    row,
    index,
    column,
    sorting,
    page,
    rowsPerPage
  ) => {
    try {

      if (actionType === ActionTypes.ADD_NEW) {
        setSelectedProduct(row)
        setSelectedProductIndex(index)
      }

      if (actionType === ActionTypes.EDIT) {
        setSelectedProduct(row)
        setSelectedProductIndex(index)
      }
      // pagination actions
      if (actionType === ActionTypes.CHANGE_PAGE) {
        setLimit(rowsPerPage);
        setOffset(page * rowsPerPage);
        return;
      }

      if (actionType === ActionTypes.ROWS_PER_PAGE) {
        setLimit(rowsPerPage);
        setOffset(0);
        return;
      }

      if (actionType === ActionTypes.NEW_ENTRY) {
        const saveRes = await mCreateProduct.mutateAsync(row);
        if (saveRes.success) {
          const newRow = { ...row, id: saveRes.id };
          setProducts([newRow, ...products]);
        }
      }
      if (actionType === ActionTypes.DELETE) {
        const deleteRes = await mDeleteProduct.mutateAsync(row.id);
        if (deleteRes.success) {
          const _products = [...products];
          _products.splice(index, 1);
          setProducts(_products);
        }
      }
      if (actionType === ActionTypes.SAVE) {
        const updateRes = await mUpdateProduct.mutateAsync(row);
        if (updateRes.success) {
          const _products = [...products];
          _products[index] = row;
          setProducts(_products);
        }
      }

      // sorting actions
      if (actionType === ActionTypes.SORT_ASC) {
        setOrderBy(column.key);
        setOrderType("asc");
      }

      if (actionType === ActionTypes.SORT_DESC) {
        setOrderBy(column.key);
        setOrderType("desc");
      }
    } catch (error) {
      console.error(error)
    }
  };

  // eslint-disable-next-line
  const onSearch = useCallback(debounce(() => {
    fetchProducts();
  }, 600), []);

  useEffect(() => {
    if (searchText.length > 2) {
      onSearch();
    }
    // eslint-disable-next-line
  }, [searchText]);

  const onExcelUploadSuccess = async (success) => {
    if (success) {
      fetchProducts();
      setMappingDialogOpen(false);
    }
  };

  const onDownloadExcel = async () => {
    const _excelData = await ProductActions.getProductsForExcel();
    const excelData =
      _excelData.length > 0
        ? ProductActions.generateExcelData(_excelData, user.account_type)
        : ProductActions.generateDummyProductsForExcel(user.account_type);

    const fileName = `products_${new Date()
      .toLocaleDateString()
      .replace(/\//g, "_")}.xlsx`;

    Excel.downloadExcel(excelData, fileName, "Products");
  };

  const resetProductEditDialog = () => {
    setSelectedProduct(null);
    setSelectedProductIndex(-1);
  };

  const onFormSubmit = async (action, data) => {
    if (selectedProduct?.id) {
      await onAction(ActionTypes.SAVE, { ...selectedProduct, ...data }, selectedProductIndex);
    } else {
      await onAction(ActionTypes.NEW_ENTRY, data)
    }
    resetProductEditDialog();
  }

  const headers = ProductUtils.getHeaders(headers0, user);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color={"text.heading"} fontWeight="600">
            Products
          </Typography>
        </Grid>
        {!mobile &&
          <Grid item xs={0} sm={0} md={8} lg={6}>
            <Stack
              direction={"row"}
              alignItems="center"
              height={"100%"}
              spacing={2}
            >
              <LoadingButton
                sx={{
                  minWidth: products.length > 0 ? 180 : 260,
                }}
                variant="text"
                spinnerSize={16}
                startIcon={<Icons.Download />}
                onClick={onDownloadExcel}
              >
                {products.length > 0
                  ? `DOWNLOAD EXCEL`
                  : `DOWNLOAD EXCEL TEMPLATE`}
              </LoadingButton>
              <LoadingButton
                sx={{
                  minWidth: 150,
                }}
                variant="text"
                spinnerSize={16}
                startIcon={<Icons.Upload />}
                onClick={() => {
                  setMappingDialogOpen(true);
                }}
              >
                UPLOAD FILE
              </LoadingButton>

            </Stack>
          </Grid>
        }
        {!mobile &&
          <Grid item xs={0} sm={0} md={0} lg={2}></Grid>
        }
        <Grid item xs={12} sm={12} md={8} lg={4}>
          <SearchInput
            placeholder="Search Products..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClear={() => setSearchText("")}
            onSearch={onSearch}
          />
        </Grid>
      </Grid>


      {
        mobile ?
          <CardList
            headers={headers}
            rows={products}
            totalRows={totalProducts}
            onAction={onAction}
            pagination={searchText.length <= 2}
            addButton={searchText.length <= 2}
            Component={ProductCard}
          ></CardList>
          :
          <DataTable
            headers={headers}
            rows={products}
            totalRows={totalProducts}
            onAction={onAction}
            addButton={searchText.length <= 2}
            pagination={searchText.length <= 2}
          />
      }

      <ExcelUploadDialog
        data={products}
        onClose={() => {
          setMappingDialogOpen(false);
        }}
        open={mappingDialogOpen}
        onSuccess={onExcelUploadSuccess}
      ></ExcelUploadDialog>

      <DynamicFormDialog
        config={ProductsHelper.getFormConfig(user)}
        open={!!selectedProduct}
        values={selectedProduct}
        title="Product Details"
        actionType={DialogActionTypes.SAVE}
        onClose={resetProductEditDialog}
        onAction={onFormSubmit}
      />
    </>
  );
}
