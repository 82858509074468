import { format, subDays } from "date-fns";
const currentDate = new Date();
const AnalyticsTransform = {
  defaultRange: {
    startDate: subDays(currentDate, 30),
    endDate: currentDate
  },
  getDefaultRange() {
    return this.defaultRange
  },
  transformRange(ranges) {
    if (!ranges || ranges.length < 1) return {
      startDate: this.defaultRange.startDate,
      endDate: this.defaultRange.endDate
    };
    const range = ranges[0]
    return {
      startDate: format(range.startDate, 'dd-MMM-yyyy'),
      endDate: format(range.endDate, 'dd-MMM-yyyy')
    }
  }
  ,
  transformDateRangeToText(ranges) {
    return `${this.transformRange(ranges).startDate} to ${this.transformRange(ranges).endDate}`
  }
};

export default AnalyticsTransform;
