import api from "network/api";
import Endpoints from "./endpoints";

const getManufacturers = async ({ queryKey }) => {
    const [, searchText] = queryKey;

    const params = {};
    if (searchText) {
        params.search = searchText
    };

    const res = await api.get(Endpoints.manufacturers, {
        params
    });
    const data = res?.data;
    return {
        data: data?.data || []
    };
};

const getMFG = async ({ queryKey }) => {
    const [, searchText] = queryKey;

    const params = {};
    if (searchText) {
        params.search = searchText
    };

    const res = await api.get(Endpoints.manufacturers, {
        params
    });
    return res?.data?.data || [];
};

const ManufacturerActions = {
    getManufacturers,
    getMFG
}

export default ManufacturerActions;
