import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      lighter: "#ebf7f670",
      light: "#26a69a",
      main: "#009688",
      dark: "#00695c",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#FFE082",
      main: "#FFC107",
      dark: "#FF8F00",
      contrastText: "#424242",
    },
    text: {
      primary: "#424242",
      secondary: "#A1A1A1",
      heading: '#616161'
    },
    insignificant: {
      main: "#999",
      light: "#bbb"
    },
    accepted: {
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#ffffff",
    },
    rejected: {
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    pending: {
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "#ffffff",
    },
    state: {
      main: "#616161",
      dark: "#424242",
      contrastText: "#ffffff",
    },
    hover: {
      light: "#EEE6",
    }

  },
});
