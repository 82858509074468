const CommonUtils = {
    getNonEmptyFieldsForKeys: (obj, keys) => {
        return keys.reduce((acc, key) => {
            if (obj[key]) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
    }
}

export default CommonUtils;


