import { FileDownload } from "@mui/icons-material";
import {
  Box,
  Button,
  colors,
  IconButton,
  LinearProgress,
  Menu,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import React, { Fragment, useContext } from "react";

import { fontSizes, fontWeights } from "styles/fonts";
import Icons from "utils/icons";

import UserContext from "context/UserContext";
import { ActionTypes } from "utils/action_types";
import { StyledMenuItem } from "./StyledMenuItem";

export const LIMIT_OPTIONS = [10, 20, 30];

const DataTable = ({
  headers = [],
  rows = [],
  onAction = async () => { },
  children,
  rowTransform,
  exportExcel = false,
  loading = false,
  showActions = true,
  addButton = false,
  reset = false,
  groupBy,
  pagination = true,
  currentPage = 0,
  currentRowsPerPage = 10,
  routingPagination = false,
  rowStyle = () => { },
  totalRows = 0,
  onEdit,
}) => {
  let groupTitle = undefined;
  const groups = [];
  let newGroup = false;

  const { user } = useContext(UserContext);

  const [page, setPage] = React.useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(currentRowsPerPage);
  const [sorting, setSorting] = React.useState({});
  const [menuAnchor, setMenuAnchor] = React.useState(null)
  const [menuActions, setMenuActions] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);


  React.useEffect(() => {
    if (routingPagination) {
      setPage(currentPage);
      setRowsPerPage(currentRowsPerPage);
    }
  }, [currentPage, currentRowsPerPage, routingPagination])

  React.useEffect(() => {
    _reset();
  }, [showActions, reset]);

  React.useEffect(() => {
    setPage(0)
    if (!pagination) {
      setRowsPerPage(30);
    } else {
      setRowsPerPage(currentRowsPerPage);
    }
  }, [pagination, currentRowsPerPage])

  const handleChangePage = (event, newPage) => {
    if (!routingPagination) {
      setPage(newPage);
    }
    onAction(
      ActionTypes.CHANGE_PAGE,
      null,
      -1,
      null,
      undefined,
      newPage,
      rowsPerPage
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const _rowsPerPage = parseInt(event.target.value, currentRowsPerPage);
    if (!routingPagination) {
      setRowsPerPage(_rowsPerPage);
      setPage(0);
    }
    onAction(
      ActionTypes.ROWS_PER_PAGE,
      null,
      -1,
      null,
      undefined,
      0,
      _rowsPerPage
    );
  }

  const _onAction = async (actionType, row, index) => {
    if (actionType === ActionTypes.EDIT) {
      if (onEdit) {
        onEdit(row, rowsPerPage * page + index);
        onAction(ActionTypes.EDIT, row, rowsPerPage * page + index);
        return;
      }
    }

    await onAction(actionType, row, rowsPerPage * page + index);
  };

  const _reset = (resetPage = true) => {
    if (resetPage) {
      setPage(0);
    }
  };

  const onAddNewRow = () => {
    const keys = headers
      .map((h) => (
        {
          key: h.key,
          defaultValue: h?.defaultValue,
        }))
      .filter((h) => h.key !== "actions" && h.key !== "sr");
    const _dummyRow = keys.reduce((acc, k) => {
      if (k?.defaultValue) {
        acc[k.key] = k.defaultValue;
      } else {
        acc[k.key] = '';
      }
      return acc;
    }, {});
    onAction(ActionTypes.ADD_NEW, _dummyRow, 0);
  };

  const onSortingChange = (h) => {
    const _sorting = {
      [h.key]: "asc",
    };
    if (sorting[h.key] === "asc") {
      _sorting[h.key] = "desc";
      onAction(ActionTypes.SORT_DESC, null, -1, h, _sorting[h.key]);
    }
    if (sorting[h.key] === "desc") {
      _sorting[h.key] = "asc";
      onAction(ActionTypes.SORT_ASC, null, -1, h, _sorting[h.key]);
    }
    setSorting(_sorting);
  };

  const onRowClick = (row, index) => {
    if (row?.actions?.includes(ActionTypes.VIEW)) {
      _onAction(
        ActionTypes.VIEW,
        row,
        index
      )
    }
  }

  const onMenuItemClick = async (action) => {
    await _onAction(
      ActionTypes[action.toUpperCase()],
      selectedRow,
      selectedRowIndex
    )
    resetMenu();
  }

  const resetMenu = () => {
    setMenuAnchor(null);
    setMenuActions([])
    setSelectedRowIndex(-1)
    setSelectedRow(null)
  }

  const dataRows = totalRows ? rows : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          mt: 2,
        }}
      >
        {loading && <LinearProgress />}
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {headers
                .filter((h) => (showActions ? true : h.key !== "actions"))
                .map((item, index) => (
                  <StyledTableCell
                    key={item.key}
                    align={item.align || "center"}
                    width={item?.width || "auto"}
                  >
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={
                        item.align === "center" ? "center" : "flex-start"
                      }
                    >
                      <span>{item.label}</span>
                      {item.sorting && (
                        <IconButton
                          size="small"
                          onClick={() => onSortingChange(item)}
                        >
                          {sorting[item.key] === "asc" && (
                            <Icons.SortAsc color="primary" />
                          )}
                          {sorting[item.key] === "desc" && (
                            <Icons.SortDesc color="primary" />
                          )}
                          {sorting[item.key] === undefined && (
                            <Icons.SortAsc color="disabled" />
                          )}
                        </IconButton>
                      )}
                    </Stack>
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {addButton && (
              <StyledTableRow>
                <StyledTableCell colSpan={12}>
                  <Stack direction={"row"} justifyContent="center">
                    <Button
                      startIcon={<Icons.Add />}
                      variant="text"
                      onClick={onAddNewRow}
                    >
                      Add New Entry
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {dataRows
              .map((row, i) => {
                if (!row) return null;
                if (groupBy && groupTitle !== row[groupBy]) {
                  groupTitle = row[groupBy];
                  groups.push(groupTitle);
                  newGroup = true;
                } else {
                  newGroup = false;
                }

                return (
                  <Fragment key={i}>
                    {groupTitle && groupBy && newGroup && (
                      <GroupTitle title={groupTitle} />
                    )}

                    <StyledTableRow sx={rowStyle(row)} onClick={() => onRowClick(row, i)}>
                      {headers
                        .filter((h) =>
                          showActions ? true : h.key !== "actions"
                        )
                        .map((h, index) =>
                          h.key === "actions" ? (
                            <StyledTableCell
                              key={`${i}-${index}`}
                              align={h.align}
                              width={h?.width || "auto"}
                            >
                              {Object.keys(h?.items || {})
                                .filter(action => action !== ActionTypes.VIEW)
                                .filter((action) =>
                                  row?.actions
                                    ? row.actions.includes(action)
                                    : true
                                )
                                .map((action, idx) => {
                                  const ActionIcon = h.items[action];
                                  const color = h?.colors
                                    ? h.colors[action]
                                    : "#0007";

                                  if (
                                    h?.permissions &&
                                    !h.permissions[action].includes(
                                      user.role
                                    )
                                  )
                                    return null;
                                  return (
                                    <IconButton
                                      key={idx}
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        _onAction(
                                          ActionTypes[action.toUpperCase()],
                                          row,
                                          i
                                        )
                                      }
                                      }
                                    >
                                      <ActionIcon
                                        style={{
                                          color: color,
                                        }}
                                        fontSize={"small"}
                                      />
                                    </IconButton>
                                  );
                                })}
                              {row?.menuActions &&
                                <IconButton
                                  key={index}
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setMenuAnchor(e.currentTarget);
                                    setMenuActions(row.menuActions)
                                    setSelectedRowIndex(index)
                                    setSelectedRow(row)
                                  }
                                  }
                                >
                                  <Icons.DotsMenu
                                    fontSize={"small"}
                                  />
                                </IconButton>
                              }
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              key={`${i}-${index}`}
                              align={h.align}
                              width={h?.width || "auto"}
                            >
                              <div>
                                {h.transform
                                  ? h.transform(
                                    row,
                                    rowsPerPage * page + i
                                  )
                                  : rowTransform
                                    ? rowTransform(h, row)
                                    : row[h.key] || "-"}
                              </div>
                            </StyledTableCell>
                          )
                        )}
                    </StyledTableRow>
                  </Fragment>
                );
              })}
            {rows.length === 0 && <NoRecordsMessage />}
          </TableBody>
        </Table>

        {children}
      </TableContainer>
      {rows.length > 0 && (
        <Stack
          direction={"row"}
          sx={{ mt: 1 }}
          justifyContent={"space-between"}
          alignItems="center"
        >
          {exportExcel ? (
            <Button variant="outlined" startIcon={<FileDownload />}>
              Export Excel
            </Button>
          ) : (
            <Box></Box>
          )}
          {
            pagination &&
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalRows ? totalRows : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </Stack>
      )}

      {!!menuAnchor && menuActions.length > 0 &&
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor && menuActions.length > 0}
          onClose={resetMenu}
          anchorOrigin={
            {
              horizontal: 'center',
              vertical: 'center'
            }
          }
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
        >
          {menuActions.map((action) => (
            <StyledMenuItem key={action} value={action} onClick={() => onMenuItemClick(action)}>
              {action.replace(/[^a-zA-Z0-9]/g, ' ')}
            </StyledMenuItem>
          ))}
        </Menu>
      }
    </Box>
  );
};

export default DataTable;

const NoRecordsMessage = () => {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={12}>
        <Typography
          variant="body1"
          color={"#999"}
          sx={{ textAlign: "center", p: '100px' }}
        >
          No records
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFF",
    color: theme.palette.text.secondary,
    fontWeight: "700",
    textTransform: "uppercase",
    padding: 6,
    overflow: "hidden",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 6,
    paddingTop: 2,
    paddingBottom: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 36,
  },
}));

const GroupTitle = ({ title }) => {
  return (
    <StyledTableRow>
      <StyledTableCell colSpan={12} sx={{ backgroundColor: "#eee5" }}>
        <Typography
          variant="h6"
          fontWeight={fontWeights[600]}
          fontSize={fontSizes.sm}
          color={colors.grey[700]}
        >
          {title}
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );
};
