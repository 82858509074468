import * as React from "react";


import { fontWeights } from "styles/fonts";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

import LoadingButton from "./LoadingButton";

import UserActions from "actions/user";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import ErrorUtils from "utils/error";
import Dropdown from "./Dropdown";

export default function ManageUserDialog({ open, onClose, data }) {
  const snackbar = useSnackbar();

  const [handoverTo, setHandoverTo] = React.useState("");

  const mHandoverData = useMutation(
    {
      mutationFn: UserActions.handoverUserData,
      mutationKey: 'handover-user-data',
      onSuccess: (res) => {
        snackbar.enqueueSnackbar(res.message, { variant: "success" });
        reset();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(ErrorUtils.parseError(error), { variant: "error" });
      }

    }
  )

  const { data: dropdownUsers } = useQuery({
    queryKey: ["get-sales-users"],
    queryFn: UserActions.getSalesUsers,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const reset = () => {
    setHandoverTo("");

    onClose();
  }

  const onSave = () => {
    mHandoverData.mutate({
      username: data?.selectedUser?.username,
      newUsername: handoverTo
    })
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle style={{ fontWeight: fontWeights[600] }}>
        Manage User
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <DialogContentText variant="button">
          Handover <strong> {data?.selectedUser?.username || "User"}'s</strong> Data To
        </DialogContentText>
        <Stack direction={"column"} spacing={2} sx={{ mt: 3 }}>
          <Dropdown
            required={true}
            label="Select User"
            variant="outlined"
            size={"medium"}
            labelKey="label"
            valueKey="value"
            options={dropdownUsers}
            value={handoverTo}
            onChange={(e) => setHandoverTo(e.target.value)}
            showNone={false}
          ></Dropdown>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={reset}>Cancel</Button>
        <LoadingButton
          disabled={!handoverTo}
          loading={mHandoverData.isLoading}
          variant="text"
          onClick={onSave}
          spinnerSize={18}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
