import { Box, Fab, Grid, Stack, Typography } from "@mui/material";
import QuotationActions from "actions/quotation";
import { AccessibleFilterChips } from "components/AccessibleFilterChips";
import AddQuotationDialog from "components/AddQuotationDialog";
import CardList from "components/CardList";
import DataTable, { LIMIT_OPTIONS } from "components/DataTable";
import QuotationCard from "components/QuotationCard";
import QuotationFiltersDrawer from "components/QuotationFiltersDrawer";
import SearchInput from "components/SearchInput";
import LoadingContext from "context/LoadingContext";
import UserContext from "context/UserContext";
import { PurchaseOrdersHelper } from "helpers/purchase_orders";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import "styles/quotation.css";
import QuotationTransform from "transforms/quotation";
import { ActionTypes } from "utils/action_types";
import { ClientStatus } from "utils/client_status";
import Icons from "utils/icons";
import { PageTypes } from "utils/page_types";
import { QuotationStates } from "utils/quotation_states";
import Roles from "utils/roles";
import SortUtils from "utils/sort";

const headers = [
  {
    key: "SR",
    label: "SR. NO",
    width: 60,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "quotation_number",
    label: "QUOTE. NO",
    width: 120,
    align: "left",
  },
  {
    key: "client_name",
    label: "CLIENT NAME",
    width: 150,
    align: "left",
  },
  {
    key: "client_company",
    label: "COMPANY NAME",
    width: 150,
    align: "left",
  },
  {
    key: "client_country",
    label: "COUNTRY",
    width: 100,
    align: "left",
  },
  {
    key: "client_status",
    label: "STATUS",
    width: 100,
    align: "center",
    transform: QuotationTransform.transformClientStatus,
  },
  {
    key: "state",
    label: "STATE",
    width: 120,
    align: "center",
    transform: QuotationTransform.transformState,
  },
  {
    key: "managed_by",
    label: "MANAGED BY",
    width: 70,
    align: "center",
  },
  {
    key: "created_at",
    label: "CREATED AT",
    width: 120,
    align: "center",
    dataType: "date",
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 150,
    align: "center",
    items: {
      Accepted: Icons.Accepted,
      Rejected: Icons.Rejected,
      Approval: Icons.Approval,
      Approve: Icons.Approve,
      Discard: Icons.Discard,
      View: Icons.ChevronRight,
    },
    colors: {
      Accepted: "#4caf5099",
      Rejected: "#f4433699",
      Approval: "#ffa733",
    },
    permissions: {
      Accepted: [Roles.ADMIN],
      Rejected: [Roles.ADMIN],
      View: [Roles.ADMIN, Roles.MEMBER_PLUS, Roles.PURCHASING],
      Approval: [Roles.MEMBER, Roles.MEMBER_PLUS],
      Approve: [Roles.ADMIN],
      Discard: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS]
    },
  },
];

export const FILTERS = [
  {
    field: "state",
    value: QuotationStates.PI_READY,
    color: "state"
  },
  {
    field: "state",
    value: QuotationStates.PO_PENDING,
    color: "state"
  },
  {
    field: "state",
    value: QuotationStates.PO_APPROVED,
    color: "state"
  },
]

export default function PurchaseOrders({ mobile }) {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const snackbar = useSnackbar();

  const [createQuotationOpen, setCreateQuotationOpen] = useState(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  const { setLoading } = useContext(LoadingContext);

  const [quotations, setQuotations] = useState([]);
  const [totalQuotations, setTotalQuotations] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState([]);


  const queryParams =
    new URLSearchParams(window.location.search)
  const _page = queryParams.get("page");
  const _limit = queryParams.get("limit");

  const { isLoading, refetch: fetchQuotations } = useQuery({
    enabled: !searchText,
    queryKey: useMemo(() => [
      "get-quotations",
      user?.role,
      _limit, // limit
      _page,
      appliedFilters,
      null,
      searchText,
      PageTypes.PURCHASE_ORDERS
    ], [user?.role, _limit, _page, appliedFilters, searchText,]),
    queryFn: QuotationActions.getQuotations,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setQuotations(data.rows)
      setTotalQuotations(data.total)
    },
  });

  const mUpdateQuotation = useMutation({
    mutationKey: "update-quotation",
    mutationFn: QuotationActions.updateQuotation,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      snackbar.enqueueSnackbar("PO updated successfully", {
        variant: "success",
      });
      fetchQuotations();
    },
  });

  const resetPage = () => {
    navigate(`/purchase_orders?page=1&limit=10`);
  }

  useEffect(() => {
    if (_page !== null && !isNaN(_page) && _page > 0) {
      setPage(Number(_page - 1));
    } else {
      resetPage()
    }

    if (LIMIT_OPTIONS.includes(Number(_limit))) {
      setRowsPerPage(Number(_limit));
    } else {
      resetPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_page, _limit, navigate])

  const resetFiltersToDefault = () => {
    const _filters = PurchaseOrdersHelper.getStoredFilters();
    setAppliedFilters(_filters)
  }
  useEffect(() => {
    resetFiltersToDefault();
  }, []);

  const onAction = (actionType, row, index, column, sorting, newPage, newRowsPerPage) => {
    if (actionType === ActionTypes.VIEW) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(`/purchase_orders/${row.id}`);
      }, 500);
    }
    if (actionType === ActionTypes.ACCEPTED) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          client_status: ClientStatus.ACCEPTED,
        },
      });
    }

    if (actionType === ActionTypes.REJECTED) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          client_status: ClientStatus.REJECTED,
        },
      });
    }

    if (actionType === ActionTypes.APPROVAL) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          state: QuotationStates.PENDING_APPROVAL,
        },
      });
    }

    if (actionType === ActionTypes.APPROVE) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          state: QuotationStates.APPROVED,
        },
      });
    }

    if (actionType === ActionTypes.DISCARD) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          active: false,
        },
      });
    }

    if (
      actionType === ActionTypes.SORT_ASC ||
      actionType === ActionTypes.SORT_DESC
    ) {
      if (column?.dateType === "date") {
        setQuotations(SortUtils.sortByDate(quotations, column.key, sorting));
      } else {
        setQuotations(SortUtils.sortByString(quotations, column.key, sorting));
      }
    }

    if (actionType === ActionTypes.CHANGE_PAGE || actionType === ActionTypes.ROWS_PER_PAGE) {
      onPageChange(newPage, newRowsPerPage);
    }
  };

  const onPageChange = (page, limit) => {
    navigate(`/purchase_orders?page=${page + 1}&limit=${limit}`);
  }

  const onCreateQuotation = (data) => {
    fetchQuotations();
    setCreateQuotationOpen(false);
    onAction(ActionTypes.VIEW, data);
  };

  const onFiltersApply = (filter, active) => {
    let _filters = [...appliedFilters];

    if (!active) {
      PurchaseOrdersHelper.markFilterActive(_filters, filter)
    } else {
      PurchaseOrdersHelper.removeFilter(_filters, filter)
    }

    setAppliedFilters(_filters);
    if (searchText.length > 2) {
      onSearch();
    }
    sessionStorage.setItem('filters', JSON.stringify(_filters))
    resetPage()
  }


  // eslint-disable-next-line
  const onSearch = useCallback(debounce(() => {
    fetchQuotations();
  }, 600), []);

  useEffect(() => {
    if (searchText.length > 2) {
      onSearch();
    }
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"flex-end"}
      >
        <Box display={"inline-flex"}>
          <Typography variant="h4" color={'text.heading'} fontWeight="600">
            Purchase Orders
          </Typography>
        </Box>

        <Box
          className="fab-container"
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        >
          <Fab
            sx={{ mb: 1 }}
            color="default"
            aria-label="filters"
            size="large"
            onClick={() => setFiltersExpanded(true)}
          >
            <Icons.Filters />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            onClick={() => setCreateQuotationOpen(true)}
          >
            <Icons.Plus />
          </Fab>
        </Box>
      </Stack>

      <Grid container>
        <Grid item xs={12} lg={8}>
          <AccessibleFilterChips
            filters={FILTERS}
            appliedFilters={appliedFilters}
            onApply={onFiltersApply
            }
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ mt: '14px' }}>
          <SearchInput
            placeholder="Search Quotation No..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={onSearch}
            onClear={() => setSearchText("")}
          />
        </Grid>
      </Grid>

      {mobile ? (
        <CardList
          loading={isLoading || mUpdateQuotation.isLoading}
          headers={headers}
          rows={quotations}
          onAction={onAction}
          Component={QuotationCard}
        />
      ) : (
        <DataTable
          loading={isLoading || mUpdateQuotation.isLoading}
          headers={headers}
          rows={quotations}
          onAction={onAction}
          currentPage={page}
          currentRowsPerPage={rowsPerPage}
          routingPagination
          rowStyle={PurchaseOrdersHelper.setCustomRowStyle}
          totalRows={totalQuotations}
          pagination={searchText.length <= 2}
          showActions={false}
        />
      )}
      <AddQuotationDialog
        open={createQuotationOpen}
        setOpen={setCreateQuotationOpen}
        next={onCreateQuotation}
      />

      <QuotationFiltersDrawer
        appliedFilters={appliedFilters}
        open={filtersExpanded}
        onClose={() => setFiltersExpanded(false)}
        mobile={mobile}
        onApply={(filters) => {
          setAppliedFilters(filters);
          setFiltersExpanded(false);
          resetPage();
        }}
      />
    </>
  );
}



