import { Box, Button, Fab, Grid, IconButton, Stack, Typography } from "@mui/material";
import ClientActions from "actions/client";
import QuotationActions from "actions/quotation";
import { AccessibleFilterChips } from "components/AccessibleFilterChips";
import AddQuotationDialog from "components/AddQuotationDialog";
import CardList from "components/CardList";
import DataTable, { LIMIT_OPTIONS } from "components/DataTable";
import QuotationCard from "components/QuotationCard";
import QuotationFiltersDrawer from "components/QuotationFiltersDrawer";
import SearchInput from "components/SearchInput";
import UserChip from "components/UserChip";
import ViewSwitcher from "components/ViewSwitcher";
import LoadingContext from "context/LoadingContext";
import UserContext from "context/UserContext";
import { QuotationsHelper } from "helpers/quotations";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import "styles/quotation.css";
import QuotationTransform from "transforms/quotation";
import { ActionTypes } from "utils/action_types";
import { ClientStatus } from "utils/client_status";
import Icons from "utils/icons";
import { QuotationViewTypes } from "utils/list_view_types";
import { PageTypes } from "utils/page_types";
import { QuotationStates } from "utils/quotation_states";
import Roles from "utils/roles";
import SortUtils from "utils/sort";

const headers = [
  {
    key: "SR",
    label: "SR. NO",
    width: 60,
    align: "center",
    transform: (row, i) => {
      return `${i + 1}.`;
    },
  },
  {
    key: "quotation_number",
    label: "QUOTE. NO",
    width: 120,
    align: "left",
  },
  {
    key: "client_name",
    label: "CLIENT NAME",
    width: 150,
    align: "left",
  },
  {
    key: "client_company",
    label: "COMPANY NAME",
    width: 150,
    align: "left",
  },
  {
    key: "client_country",
    label: "COUNTRY",
    width: 100,
    align: "left",
  },
  {
    key: "client_status",
    label: "STATUS",
    width: 100,
    align: "center",
    transform: QuotationTransform.transformClientStatus,
  },
  {
    key: "state",
    label: "STATE",
    width: 120,
    align: "center",
    transform: QuotationTransform.transformState,
  },
  {
    key: "managed_by",
    label: "MANAGED BY",
    width: 70,
    align: "center",
  },
  {
    key: "created_at",
    label: "CREATED AT",
    width: 120,
    align: "center",
    dataType: "date",
  },
  {
    key: "actions",
    label: "ACTIONS",
    width: 150,
    align: "center",
    items: {
      Accepted: Icons.Accepted,
      Rejected: Icons.Rejected,
      Approval: Icons.Approval,
      Approve: Icons.Approve,
      Discard: Icons.Discard,
      View: Icons.ChevronRight,
    },
    colors: {
      Accepted: "#4caf5099",
      Rejected: "#f4433699",
      Approval: "#ffa733",
    },
    permissions: {
      Accepted: [Roles.ADMIN],
      Rejected: [Roles.ADMIN],
      View: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS],
      Approval: [Roles.MEMBER, Roles.MEMBER_PLUS],
      Approve: [Roles.ADMIN],
      Discard: [Roles.ADMIN, Roles.MEMBER, Roles.MEMBER_PLUS]
    },
  },
];

const FILTERS = [
  {
    field: "state",
    value: QuotationStates.PENDING_APPROVAL,
    color: "state"
  },
  {
    field: "state",
    value: QuotationStates.APPROVED,
    color: "state"
  },
  {
    field: "client_status",
    value: ClientStatus.ACCEPTED,
    color: "accepted"
  },
  {
    field: "client_status",
    value: ClientStatus.REJECTED,
    color: "rejected"
  },
  {
    field: "client_status",
    value: ClientStatus.PENDING,
    color: "pending"
  },
]


export default function Quotation({ mobile }) {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { username, clientId } = useParams();

  const snackbar = useSnackbar();

  const [createQuotationOpen, setCreateQuotationOpen] = useState(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  const { setLoading } = useContext(LoadingContext);

  const [quotations, setQuotations] = useState([]);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [groupBy, setGroupBy] = useState({
    value: QuotationViewTypes[0].value,
    column: QuotationViewTypes[0].column,
  });

  const queryParams =
    new URLSearchParams(window.location.search)
  const _page = queryParams.get("page");
  const _limit = queryParams.get("limit");

  const { isLoading, refetch: fetchQuotations } = useQuery({
    enabled: !searchText,
    queryKey: useMemo(() => [
      "get-quotations",
      user?.role,
      _limit,
      _page,
      appliedFilters,
      groupBy.column,
      searchText,
      PageTypes.QUOTATIONS
    ], [
      user?.role, _limit, _page, appliedFilters, groupBy.column, searchText
    ]),
    queryFn: QuotationActions.getQuotations,
    onError: () => {
      snackbar.enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setQuotations(data.rows)
      setTotalQuotations(data.total)
    },
  });

  const mUpdateQuotation = useMutation({
    mutationKey: "update-quotation",
    mutationFn: QuotationActions.updateQuotation,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Quotation updated successfully", {
        variant: "success",
      });
      fetchQuotations();
    },
  });

  const { data: clientDetails } = useQuery({
    enabled: !!clientId,
    queryKey: ["get-client-details", clientId],
    queryFn: ClientActions.getClientDetails,
  });

  const resetPage = () => {
    if (username) {
      navigate(`/master/users/${username}/quotations?page=1&limit=10`);
    } else if (clientId) {
      navigate(`/master/clients/${clientId}/quotations?page=1&limit=10`);
    } else {
      navigate(`/quotations?page=1&limit=10`);
    }
  }

  useEffect(() => {
    if (_page !== null && !isNaN(_page) && _page > 0) {
      setPage(Number(_page - 1));
    } else {
      resetPage()
    }

    if (LIMIT_OPTIONS.includes(Number(_limit))) {
      setRowsPerPage(Number(_limit));
    } else {
      resetPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_page, _limit, navigate])

  useEffect(() => {
    const _filters = QuotationsHelper.getStoredFilters();
    if (username) {
      QuotationsHelper.addUserFiler(username, _filters)
    } else if (clientId) {
      QuotationsHelper.addClientFiler(clientId, _filters)
    }
    setAppliedFilters(_filters)
  }, [username, clientId]);

  const onAction = (actionType, row, index, column, sorting, newPage, newRowsPerPage) => {
    if (actionType === ActionTypes.VIEW) {
      setLoading(true);
      // sessionStorage.setItem(`quotation-${row.id}`, JSON.stringify(row));
      setTimeout(() => {
        setLoading(false);
        navigate(`/quotations/${row.id}`);
      }, 500);
    }
    if (actionType === ActionTypes.ACCEPTED) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          client_status: ClientStatus.ACCEPTED,
        },
      });
    }

    if (actionType === ActionTypes.REJECTED) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          client_status: ClientStatus.REJECTED,
        },
      });
    }

    if (actionType === ActionTypes.APPROVAL) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          state: QuotationStates.PENDING_APPROVAL,
        },
      });
    }

    if (actionType === ActionTypes.APPROVE) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          state: QuotationStates.APPROVED,
        },
      });
    }

    if (actionType === ActionTypes.DISCARD) {
      mUpdateQuotation.mutate({
        quotationId: row.id,
        data: {
          active: false,
        },
      });
    }

    if (
      actionType === ActionTypes.SORT_ASC ||
      actionType === ActionTypes.SORT_DESC
    ) {
      if (column?.dateType === "date") {
        setQuotations(SortUtils.sortByDate(quotations, column.key, sorting));
      } else {
        setQuotations(SortUtils.sortByString(quotations, column.key, sorting));
      }
    }

    if (actionType === ActionTypes.CHANGE_PAGE || actionType === ActionTypes.ROWS_PER_PAGE) {
      onPageChange(newPage, newRowsPerPage);
    }
  };

  const onPageChange = (page, limit) => {
    if (username) {
      navigate(`/master/users/${username}/quotations?page=${page + 1}&limit=${limit}`);
    } else if (clientId) {
      navigate(`/master/clients/${clientId}/quotations?page=${page + 1}&limit=${limit}`);
    } else {
      navigate(`/quotations?page=${page + 1}&limit=${limit}`);
    }
  }

  const onCreateQuotation = (data) => {
    fetchQuotations();
    setCreateQuotationOpen(false);
    onAction(ActionTypes.VIEW, data);
  };

  const onSwitchView = (view) => {
    setGroupBy(view)
    sessionStorage.setItem('viewBy', JSON.stringify(view));
  }

  const onFiltersApply = (filter, active) => {
    let _filters = [...appliedFilters];

    if (!active) {
      QuotationsHelper.markFilterActive(_filters, filter)
    } else {
      QuotationsHelper.removeFilter(_filters, filter)
    }

    if (username && !QuotationsHelper.userFilterExists(_filters)) {
      QuotationsHelper.addUserFiler(username, _filters)
    } else if (clientId && !QuotationsHelper.clientFilterExists(_filters)) {
      QuotationsHelper.addClientFiler(clientId, _filters)
    }
    setAppliedFilters(_filters);
    if (searchText.length > 2) {
      onSearch()
    }
    sessionStorage.setItem('filters', JSON.stringify(_filters))
    resetPage()
  }

  const onClearAllFilters = () => {
    const clearKeys = ['state', 'client_status']
    const _filters = [...appliedFilters]
    const newFilters = _filters.filter((f) => !clearKeys.includes(f.field))
    setAppliedFilters(newFilters)
    if (searchText.length > 2) {
      onSearch()
    }
    resetPage()
  }

  // eslint-disable-next-line
  const onSearch = useCallback(debounce(() => {
    fetchQuotations();
  }, 600), []);

  useEffect(() => {
    if (searchText.length > 2) {
      onSearch();
    }
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"flex-end"}
      >
        <Box display={"inline-flex"}>
          <Typography variant="h4" color={'text.heading'} fontWeight="600">
            Quotations
          </Typography>
          {!mobile && (
            <ViewSwitcher
              items={QuotationViewTypes}
              selected={groupBy}
              onChange={onSwitchView}
            />
          )}
          {username && (
            <UserChip username={username} onDelete={() => navigate(-1)} />
          )}
          {clientId && clientDetails?.name && (
            <UserChip username={clientDetails.name} onDelete={() => navigate(-1)} />
          )}
        </Box>
        <Stack spacing={2} className="mobile-hide" direction={"row"}>
          <Button
            variant="outlined"
            startIcon={<Icons.Add />}
            component="label"
            onClick={() => setCreateQuotationOpen(true)}
          >
            CREATE QUOTATION
          </Button>
          <IconButton color="primary" onClick={() => setFiltersExpanded(true)}>
            <Icons.Filters />
          </IconButton>
        </Stack>

        <Box
          className="fab-container"
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        >
          {/* <Fab
            sx={{ mb: 1 }}
            color="default"
            aria-label="filters"
            size="large"
            onClick={() => setFiltersExpanded(true)}
          >
            <Icons.Filters />
          </Fab> */}
          <Fab
            color="primary"
            aria-label="add"
            size="large"
            onClick={() => setCreateQuotationOpen(true)}
          >
            <Icons.Plus />
          </Fab>
        </Box>
      </Stack>

      <Grid container>
        <Grid item xs={12} lg={8} sx={{
          overflowX: 'auto',
          scrollbarWidth: "none"
        }}>
          <AccessibleFilterChips
            filters={FILTERS}
            appliedFilters={appliedFilters}
            onApply={onFiltersApply
            }
            onClearAll={onClearAllFilters}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} lg={4} sx={{ mt: '14px' }}>
          <SearchInput
            placeholder="Search Quotation No. / Client / Company ..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={onSearch}
            onClear={() => setSearchText("")}
          />
        </Grid>
      </Grid>

      {mobile ? (
        <CardList
          loading={isLoading || mUpdateQuotation.isLoading}
          headers={headers}
          rows={quotations}
          onAction={onAction}
          Component={QuotationCard}
          currentPage={page}
          currentRowsPerPage={rowsPerPage}
          routingPagination
          pagination={searchText.length <= 2}
          totalRows={totalQuotations}
        />
      ) : (
        <DataTable
          loading={isLoading || mUpdateQuotation.isLoading}
          headers={headers}
          rows={quotations}
          onAction={onAction}
          groupBy={groupBy.column}
          currentPage={page}
          currentRowsPerPage={rowsPerPage}
          routingPagination
          rowStyle={QuotationsHelper.setCustomRowStyle}
          pagination={searchText.length <= 2}
          totalRows={totalQuotations}
          showActions={false}
        />
      )}
      <AddQuotationDialog
        open={createQuotationOpen}
        setOpen={setCreateQuotationOpen}
        next={onCreateQuotation}
      />

      <QuotationFiltersDrawer
        appliedFilters={appliedFilters}
        open={filtersExpanded}
        onClose={() => setFiltersExpanded(false)}
        mobile={mobile}
        onApply={(filters) => {
          setAppliedFilters(filters);
          setFiltersExpanded(false);
          resetPage();
        }}
      />
    </>
  );
}

