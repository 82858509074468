const { default: api } = require("network/api")
const { default: Endpoints } = require("./endpoints")

const ProformaInvoiceActions = {
    async saveProfInvoiceDetails({ quotationId, data }) {
        if (!quotationId) {
            throw new Error("Invalid quotation");
        }
        const res = await api.put(`${Endpoints.proformaInvoices}/${quotationId}`, data);
        return res.data;
    },
    async getProfInvoiceDetails({
        queryKey
    }) {
        const quotationId = queryKey[1];
        if (!quotationId) {
            throw new Error("Invalid quotation");
        }
        const res = await api.get(`${Endpoints.proformaInvoices}/${quotationId}`);
        return res.data;
    },
    async generateProfInvoiceDoc({ quotationId }) {
        const res = await api.post(`${Endpoints.proformaInvoices}/${quotationId}/generate`);
        return res.data;
    },
    async getProfInvoiceDocUrl({ queryKey }) {
        const quotationId = queryKey[1];
        if (!quotationId) {
            throw new Error("Invalid params");
        }
        const url = await api.get(
            `${Endpoints.proformaInvoices}/${quotationId}/document-url`
        );
        return url;
    }
}

export default ProformaInvoiceActions
