import {
    Autocomplete,
    TextField
} from '@mui/material';
import LoadingContext from 'context/LoadingContext';
import { debounce } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

export default function NWSuggestionInput({
    label = '',
    placeholder = '',
    onAction,
    value = '',
    required = false,
    queryFn = () => { },
    error,
    helperText = '',
    onFocus = () => { }
}) {
    const { setLoading } = useContext(LoadingContext);

    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [selectedOption, setSelectedOption] = useState(null);

    const handleInputChange = (event, newInputValue) => {
        setSearchText(newInputValue);
        if (onAction) onAction(newInputValue)
    };

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
        if (newValue) {
            setSearchText(newValue.name);
        }
    };

    const { isLoading, refetch: fetchRows } = useQuery({
        enabled: false,
        queryKey: ["get-manufacturers", searchText],
        queryFn: queryFn,
        onSuccess: (data) => {
            setRows(data);
        },
    });

    useEffect(() => {
        if (value) {
            setSearchText(value);
        }
    }, [value]);

    useEffect(() => {
        setLoading(!!isLoading);
    }, [isLoading, setLoading]);

    const onSearch = useMemo(() => debounce((searchText) => {
        if (searchText?.length > 2) {
            fetchRows();
        }
    }, 400), [fetchRows]);

    useEffect(() => {
        onSearch(searchText);
        // eslint-disable-next-line
    }, [searchText]);


    return (
        <Autocomplete
            options={rows}
            getOptionLabel={option => option.name}
            value={selectedOption}
            onChange={handleChange}
            inputValue={searchText}
            onInputChange={handleInputChange}
            renderInput={params => (
                <TextField {...params} required={required} label={label} placeholder={placeholder} error={error} helperText={helperText} onFocus={onFocus} fullWidth />
            )}
            freeSolo
        />
    )
}
