import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { Box, Stack, Typography } from "@mui/material";

import DataTable, { LIMIT_OPTIONS } from "components/DataTable";
import LoadingContext from "context/LoadingContext";

import ClientActions from "actions/client";
import Icons from "utils/icons";
import { ActionTypes } from "utils/action_types";
import AlertDialog from "components/AlertDialog";
import { ClientViewTypes } from "utils/list_view_types";
import ViewSwitcher from "components/ViewSwitcher";
import UserActions from "actions/user";
import { useNavigate } from "react-router-dom";
import DynamicFormDialog from "components/DynamicFormDialog";
import { DialogActionTypes } from "utils/dialog_action_types";
import { ClientHelper } from "helpers/client";

const headers = (params) => {
  const users = params?.users || [];
  let _headers = [
    {
      key: "SR",
      label: "SR. NO",
      width: 60,
      align: "center",
      transform: (row, i) => {
        return `${i + 1}.`;
      },
    },
    {
      key: "name",
      label: "CLIENT NAME",
      width: 200,
      align: "left",
      editable: true,
    },
    {
      key: "company",
      label: "COMPANY NAME",
      width: 200,
      align: "center",
      editable: true,
    },
    {
      key: "email",
      label: "EMAIL ID",
      width: 200,
      align: "center",
      editable: true,
    },
    {
      key: "phone",
      label: "PHONE",
      width: 200,
      align: "center",
      editable: true,
    },
    {
      key: "country",
      label: "COUNTRY",
      width: 150,
      align: "center",
      editable: true,
    },
    {
      key: "associated_user",
      label: "MANAGED BY",
      width: 150,
      align: "center",
      dropdown: true,
      options: users,
    },
    {
      key: "actions",
      label: "ACTIONS",
      width: 150,
      align: "center",
      items: {
        Edit: Icons.Edit,
        Delete: Icons.Delete,
        View: Icons.ChevronRight,
      },
    },
  ];
  return _headers;
};

export default function Clients() {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const queryParams =
    new URLSearchParams(window.location.search)
  const _page = queryParams.get("page");
  const _limit = queryParams.get("limit");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [deleteClientConfirmDialogOpen, setDeleteClientConfirmDialogOpen] =
    useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientIndex, setSelectedClientIndex] = useState(-1);
  const [groupBy, setGroupBy] = useState({
    value: ClientViewTypes[0].value,
    column: ClientViewTypes[0].column,
  });
  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState([]);

  const { setLoading } = useContext(LoadingContext);

  const {
    isLoading,
    refetch: fetchClients,
  } = useQuery({
    queryKey: ["get-clients", groupBy.column, _limit,
      _page],
    queryFn: ClientActions.getClients,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: (data) => {
      setClients(data.rows)
      setTotalClients(data.total)
    },
  });

  const mDeleteClient = useMutation({
    mutationFn: ClientActions.deleteClient,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
      setDeleteClientConfirmDialogOpen(false);
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Client deleted successfully", {
        variant: "success",
      });
      fetchClients();
      setDeleteClientConfirmDialogOpen(false);
    },
  });

  const mUpdateClient = useMutation({
    mutationFn: ClientActions.updateClient,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSuccess: () => {
      snackbar.enqueueSnackbar("Client updated successfully", {
        variant: "success",
      });
      fetchClients();
    },
  });

  const { data: dropdownUsers, isLoading: usersLoading } = useQuery({
    queryKey: ["get-sales-users"],
    queryFn: UserActions.getSalesUsers,
    onError: (error) => {
      snackbar.enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const resetPage = () => {
    navigate(`/master/clients?page=1&limit=10`);
  }

  useEffect(() => {
    setLoading(isLoading || usersLoading);
    // eslint-disable-next-line
  }, [isLoading, usersLoading]);

  useEffect(() => {
    if (_page !== null && !isNaN(_page) && _page > 0) {
      setPage(Number(_page - 1));
    } else {
      resetPage()
    }

    if (LIMIT_OPTIONS.includes(Number(_limit))) {
      setRowsPerPage(Number(_limit));
    } else {
      resetPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_page, _limit, navigate])

  const onAction = async (actionType, row, index, column, sorting, newPage, newRowsPerPage) => {
    if (actionType === ActionTypes.ADD_NEW) {
      setSelectedClient(row)
      setSelectedClientIndex(index);
      setFormDialogOpen(true)
    }

    if (actionType === ActionTypes.EDIT) {
      setSelectedClient(row)
      setSelectedClientIndex(index)
      setFormDialogOpen(true)
    }

    if (actionType === ActionTypes.DELETE) {
      setSelectedClient(row);
      setDeleteClientConfirmDialogOpen(true);
    }

    if (actionType === ActionTypes.SAVE) {
      mUpdateClient.mutate(row);
    }

    if (actionType === ActionTypes.CHANGE_PAGE || actionType === ActionTypes.ROWS_PER_PAGE) {
      navigate(`/master/clients?page=${newPage + 1}&limit=${newRowsPerPage}`);
    }

    if (actionType === ActionTypes.VIEW) {
      navigate(`/master/clients/${row.id}/quotations?page=1&limit=10`)
    }
  };

  const resetForm = () => {
    setSelectedClient(null)
    setSelectedClientIndex(-1)
    setFormDialogOpen(false)
  }

  const onFormSubmit = async (action, data) => {
    if (selectedClient?.id) {
      await onAction(ActionTypes.SAVE, { ...selectedClient, ...data }, selectedClientIndex);
    } else {
      await onAction(ActionTypes.NEW_ENTRY, data)
    }
    resetForm();
  }

  return (
    <>
      <Stack direction={"row"} justifyContent="space-between">
        <Box display={"inline-flex"}>
          <Typography variant="h4" color={"text.heading"} fontWeight="600">
            Clients
          </Typography>

          <ViewSwitcher
            items={ClientViewTypes}
            selected={groupBy}
            onChange={setGroupBy}
          />
        </Box>
      </Stack>
      <DataTable
        headers={headers({
          users: dropdownUsers,
        })}
        rows={clients}
        rowStyle={ClientHelper.setCustomRowStyle}
        onAction={onAction}
        groupBy={groupBy.column}
        currentPage={page}
        currentRowsPerPage={rowsPerPage}
        routingPagination
        totalRows={totalClients}
      />

      <AlertDialog
        open={deleteClientConfirmDialogOpen}
        title={"Delete Client"}
        message={
          "Deleting client will result in deleting all the quotations associated with it. Are you sure you want to delete this client?"
        }
        onClose={() => {
          setSelectedClient(null);
          setDeleteClientConfirmDialogOpen(false);
        }}
        onContinue={() => {
          mDeleteClient.mutate(selectedClient?.id);
          setSelectedClient(null);
        }}
      ></AlertDialog>

      <DynamicFormDialog
        open={formDialogOpen}
        values={selectedClient}
        title="Client details"
        actionType={DialogActionTypes.SAVE}
        config={ClientHelper.getClientFormConfig({ users: dropdownUsers })}
        onAction={onFormSubmit}
        onClose={resetForm}
      ></DynamicFormDialog>
    </>
  );
}
